import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Googlemap from "../component/Googlemap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { alphaNumericPattern, emailrgx } from "../assets/constant";
import axios from "axios";
import { BASE_URL } from "../assets/constant";

const CustomerAdd = (props) => {
  const [menu, setMenu] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [date, setDate] = useState(new Date());
  const [currencyOptions, setcurrencyOptions] = useState([
    { id: 1, text: "Select Currency" },
    { id: 2, text: "EUR Euro" },
    { id: 3, text: "INR Indoan Rupee" },
    { id: 4, text: "USD- US Dollar" },
  ]);
  const [countryOptions, setcountryOptions] = useState([
    { id: 1, text: "Select Country" },
    { id: 2, text: "Afghanistan" },
    { id: 3, text: "Albania" },
    { id: 4, text: "American Samoa" },
    { id: 5, text: "Algeria" },
    { id: 6, text: "Andorra" },
    { id: 7, text: "Angola" },
    { id: 8, text: "Anguilla" },
    { id: 9, text: "United States" },
  ]);

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/users`, {
        name: data.name,
        email: data.email,
        password: data.password,
        role: "customer",
      })
      .then(
        (response) => {
          clearErrors("password");
          props.history.push("index");
        },
        (error) => {
          setError("email", {
            message: error.response.data.message,
          });
        }
      );
  };
  const onEyeClick = () => {
    seteye(!eye);
  };

  const [eye, seteye] = useState(true);

  const schema = yup
    .object({
      name: yup
        .string()
        .matches(alphaNumericPattern, "please enther valid name")
        .required("Please enter your name"),
      email: yup
        .string()
        .matches(emailrgx, "Email is required")
        .required("Email is required")
        .trim(),
      password: yup
        .string()
        .min(6)
        .max(6)
        .required("Password is required")
        .trim(),

      confirmPassword: yup
        .string()
        .min(6)
        .max(6)
        .required("ConfirmPassword is required")
        .trim(),
    })
    .required();
  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Add Customer</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {/* <h4 className="card-title">Basic Info</h4> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label></label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name"
                            />
                          </div>

                          <div className="form-group">
                            <label></label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Phone Number"
                            />
                          </div>
                        </div>

                        <div className="col-md-6"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <Button variant="secondary" className="save-button fs-5">
              Save
            </Button>{" "}
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerAdd;
