import React, { useState ,useContext} from "react";
import { Button, Modal } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import axios from 'axios';
import tamaxa from "../assets/img/tamaxa-removebg-preview.svg";
import backgroundImage from "../assets/img/bg-design.png";
import { AuthContext } from "../authentication/AuthContext";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";


// Function for styling
const getBackgroundStyle = () => {
  return {
    position: "relative",
    height: "100vh",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
}

const AddStylePopUp = (props) => {
  const navigate = useNavigate();

  const handleModalClose = () => {
    props.setModalPopShow(false);
  };

  const handleModalShow = () => {
    props.setModalPopShow(true);
  };

  const handleLinkShow = () => {
    navigate('/clothing');
  };
  return (
    <>
      {/* <Button
        className="btn btn-info text-white rounded-4 mt-4"
        onClick={handleModalShow}
      >
        START HERE <FeatherIcon icon="arrow-right" style={{ marginLeft: "20px" }} />
      </Button> */}

      <Modal
        show={props?.showPop}
        backdrop="static"
        onHide={handleModalClose}
        centered
        size="lg"
      >
        {/* <Modal.Header closeButton>
            <Modal.Title>Modal Title</Modal.Title>
          </Modal.Header> */}
        <Modal.Body>
          <div className="main-wrapper login-body">
            <div className="login-wrapper" style={getBackgroundStyle()}>
              <div className="container" style={{ zIndex: 1 }}>
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap text-center">
                      <img
                        src={tamaxa}
                        alt="Logo"
                        className="img-fluid logo-dark"
                      />
                      <h6 className="mt-0 text-secondary" style={{marginTop:"-20px"}}>
                      One more thing, before you start stitching please tell us the types of clothes you make!
                      </h6>
                      <Button
                        className="btn btn-info text-white rounded-4 mt-4"
                        onClick={handleLinkShow}
                      >
                        START HERE{" "}
                        <FeatherIcon
                          icon="arrow-right"
                          style={{ marginLeft: "20px" }}
                        />
                      </Button>
                      <div>
                        <div className="login-or">
                          <span className="or-line" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModalClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default AddStylePopUp;
