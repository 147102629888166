import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import {
  img12,
  img5,
  img2,
  img3,
  img4,
  img11,
  img7,
  img6,
  img8,
  img9,
} from "../_components/imagepath";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { toast } from "react-toastify";
import { BASE_URL } from "../assets/constant";

const SlideToggle = window.ReactSlideToggle;

const ClothSize = () => {
  const { currentUser } = useContext(AuthContext);
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const [myData, setMyData] = useState([]);
  const [isError, setIsError] = useState("");

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const fetchClothTypes = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/cloth_sizes`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          // setMyData(response.data.body);
          setMyData(response.data.body.length> 0 ? response.data.body : 0 );
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsError(error.message);
        }
      );
  };

  useEffect(() => {
    fetchClothTypes();
  }, []);

  const deleteClothType = () => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/cloth_sizes/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          handleClose();
          fetchClothTypes();
          toast.success(response.data.message);
        },
        (error) => {
          setIsError(error.message);
          toast.error(error.response.data.message);
        }
      );
  };

  const columns = [
    {
      name: "No.",
      selector: (row) => row.indexNo,
      sortable: true,
      width: "150px",
    },
    {
      name: "Customer Name",
      sortable: true,
      selector: (row) => row.customer.name,
      width: "200px",
      cell: (row) => (
        <div>
          <a
            href={`/profile/${row.customer._id}`}
          >
            {row.customer.name}
          </a>
        </div>
      )
    },
    {
      name: "Clothing",
      sortable: true,
      selector: (row) => row.clothType.title,
      width: "200px",
    },
    {
      name: "Neck Size",
      sortable: true,
      selector: (row) => row.neckSize,
      width: "200px",
    },
    {
      name: "Shoulder Width",
      selector: (row) => row.shoulderWidth,
      sortable: true,
      width: "200px",
    },

    {
      name: "Chest Size",
      selector: (row) => row.chestSize,
      sortable: true,
      width: "200px",
    },

    {
      name: "Waist Size",
      selector: (row) => row.waistSize,
      sortable: true,
      width: "200px",
    },

    {
      name: "Sleeve Length",
      selector: (row) => row.sleeveLength,
      sortable: true,
      width: "200px",
    },

    {
      name: "Shirt Length",
      selector: (row) => row.shirtLength,
      sortable: true,
      width: "200px",
    },

    {
      name: "Collar Spread",
      selector: (row) => row.collarSpread,
      sortable: true,
      width: "200px",
    },

    {
      name: "Cuff Size",
      selector: (row) => row.cuffSize,
      sortable: true,
      width: "200px",
    },

    {
      name: "Bicep Size",
      selector: (row) => row.bicepSize,
      sortable: true,
      width: "200px",
    },

    {
      name: "Armhole Size",
      selector: (row) => row.armholeSize,
      sortable: true,
      width: "200px",
    },

    {
      name: "Back Pleats",
      selector: (row) => row.backPleats,
      sortable: true,
      width: "200px",
    },

    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div>
          <a
            href={`/measurements-edit/${row._id}`}
            className="btn btn-sm btn-white text-success me-2"
          >
            <i className="far fa-edit me-1"></i> Edit
          </a>
          <button
            className="btn btn-sm btn-white text-danger me-2"
            onClick={() => handleShow(row._id)}
          >
            <i className="far fa-trash-alt me-1"></i> Delete
          </button>{" "}
        </div>
      ),
      width: "200px",
    },
  ];

  const tableData = {
    columns,
    data: myData !==0 ? myData : [],
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you really want to delete this?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteClothType}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Measurements</h3>
                </div>
                <div className="col-auto">
                  <Link to="/measurements-new" className="btn  me-1">
                    {/* <i className="fas fa-plus"></i> */}
                    <Button variant="light" className="text-dark">
                      Add New
                    </Button>{" "}
                  </Link>
                  {/* <a className="btn btn-primary filter-btn" href="/customers#" id="filter_search">
									<i className="fas fa-filter"></i>
								</a> */}
                </div>
              </div>
            </div>
            <div id="filter_inputs" className="card filter-card">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <label>Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <label>Email</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <label>Phone</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table">
                  <div className="card-body">
                    <DataTableExtensions {...tableData}>
                      <DataTable
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                      />
                    </DataTableExtensions>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ClothSize;
