import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../_components/imagepath";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { alphaNumericPattern, emailrgx } from "../assets/constant";
import tamaxa from "../assets/img/tamaxa-removebg-preview.svg";
import axios from "axios";
import {BASE_URL,  OAUTH2CLIENTID } from "../assets/constant";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from './AuthContext';
import {gapi} from 'gapi-script';
import { GoogleLogin } from 'react-google-login';


const schema = yup
  .object({
    name: yup
      .string()
      .matches(alphaNumericPattern, "please enther valid name")
      .required("Please enter your name"),
    email: yup
      .string()
      .matches(emailrgx, "Email is required")
      .required("Email is required")
      .trim(),
    password: yup
      .string()
      .min(3)
      .required("Password is required")
      .trim(),

    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required")
      .trim(),
  })
  .required();

const Register = (props) => {
  const [eye, seteye] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirmEye, setConfirmEye] = useState(true);
  const navigate = useNavigate();
  const [emailerror, setEmailError] = useState("");
  const [nameerror, setNameError] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [formgroup, setFormGroup] = useState("");
  const [inputValues, setInputValues] = useState({
    email: "admin@dreamguys.co.in",
    password: "123456",
  });




  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(()=>{
    function start(){
      gapi.client.init({
        clientId:OAUTH2CLIENTID,
        scope:"profile"
      })
    };
    gapi.load('client:auth2',start)
  })

  const { setCurrentUser } = useContext(AuthContext);

  const onSubmit = (data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/auth/signup`, {
        name: data.name,
        email: data.email,
        password: data.password,
        role: "tailor",
        currency:data.currency
      })
      .then(
        (response) => {
          toast.success(response.data.message);
          setCurrentUser(response.data.user);
          navigate('/');
        },
        (error) => {
          setError("email", {
            message: error.response.data.message,
          });
        }
      );
  };
  const onEyeClick = () => {
    seteye(!eye);
  };

  const onConfirmEyeClick = () => {
    setConfirmEye(!confirmEye);
  };


  const responseGoogle = (response) => {
    setLoading(true);
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/signin_google`, {
			authId: response.tokenId
		})
      .then(data => {
        console.log(data,'response');
        setLoading(false);
        toast.success(data.data.message);
        setCurrentUser(data.data.user);
        navigate('/');
      })
      .catch(error => {
        setLoading(false);
        toast.error(error.data.data.message);
      });
  }

  return (
    <div className="main-wrapper login-body">
      <div className="login-wrapper">
        <div className="container">
          {/* <img className="img-fluid logo-dark mb-2" src={Logo} alt="Logo" /> */}
          <img src={tamaxa} alt="Logo" className="img-fluid logo-dark" />
          <div className="row" style={{justifyContent:"center"}}>
          <div className="col-lg-4 col-sm-6 col-md-10">
          <div className="card d-flex justify-content-center">
        
            <div className="login-right p-3">
              <div className="login-right-wrap">
                <h1 className="text-center" >Register</h1>
                {/* <p className="account-subtitle">Access to our dashboard</p> */}

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group input_text">
                    <label className="form-control-label">Name</label>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control ${
                            errors?.name ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                      defaultValue=""
                    />

                    <small>{errors?.name?.message}</small>
                  </div>
                  <div className="form-group input_text">
                    <label className="form-control-label">Email Address</label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors?.email ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                      defaultValue=""
                    />

                    <small>{errors?.email?.message}</small>
                  </div>
                  <div className="form-group input_text">
                    <label className="form-control-label">Password</label>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <div className="pass-group">
                          <input
                            type={eye ? "password" : "text"}
                            className={`form-control  ${
                              errors?.password ? "error-input" : ""
                            }`}
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                          />
                          <span
                            onClick={onEyeClick}
                            className={`fa toggle-password" ${
                              eye ? "fa-eye-slash" : "fa-eye"
                            }`}
                          />
                        </div>
                      )}
                      defaultValue=""
                    />

                    <small>{errors?.password?.message}</small>
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">
                      Confirm Password
                    </label>
                    <Controller
                      name="confirmPassword"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <div className="pass-group">
                          <input
                            type={confirmEye ? "password" : "text"}
                            className={`form-control  ${
                              errors?.confirmPassword ? "error-input" : ""
                            }`}
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                          />
                          <span
                            onClick={onConfirmEyeClick}
                            className={`fa toggle-password ${
                              confirmEye ? "fa-eye-slash" : "fa-eye"
                            }`}
                          />
                        </div>
                      )}
                      defaultValue=""
                    />

                    <small>{errors?.confirmPassword?.message}</small>
                  </div>
                  <div className="form-group">

                            <label className="form-control-label">Currency</label>
                            <Controller
                              name="currency"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <select
                                  className={`form-control ${
                                    errors?.name ? "error-input" : ""
                                  }`}
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                  required
                                >
                                  <option value="">Select an option</option>
                                  <option value="₦">₦</option>
                                  <option value="£">£</option>
                                  <option value="$">$</option>
                                </select>
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.name?.message}</small>
                            </div>
                  <button
                    className="btn btn-lg btn-block w-100 btn-primary w-100"
                    type="submit"
                  >
                    Register
                  </button>
                </form>
                {/* /Form */}

                <div className="login-or">
                  <span className="or-line"></span>
                  {/* <span className="span-or">or</span> */}
                </div>
                {/* Social Login */}
                {/* <div className="social-login">
									<span>Register with</span>
									<a href="#" className="facebook"><i className="fab fa-facebook-f"></i></a><a href="#" className="google"><i className="fab fa-google"></i></a>
								</div> */}
                {/* /Social Login */}
                <div id="signinButton" className="text-center">
                      <GoogleLogin
                        clientId={OAUTH2CLIENTID}
                        buttonText="Continue with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={false}
                        disabled={loading}
                      />
                      </div>
                <div className="text-center mt-3 dont-have">
                  Already have an account? <Link to="/login">Login</Link>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
          </div>
        </div>
      </div>
  );
};
export default Register;
