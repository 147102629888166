import React, { useState, useEffect } from 'react';

const DynamicInputFields = ({ measurementFields, onMeasurementFieldsChange }) => {
  const [inputFields, setInputFields] = useState([]);

  useEffect(() => {
    if (measurementFields && measurementFields.length > 0) {
      const fields = measurementFields.map((field) => ({ value: field?.title }));
      setInputFields(fields);
    } else {
      setInputFields([{ value: '' }]);
    }
  }, [measurementFields]);

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index].value = event.target.value;
    setInputFields(values);
    if (measurementFields.length > 0 && measurementFields[index]) {
      measurementFields[index].title = event.target.value;
      onMeasurementFieldsChange(measurementFields);
    } else {
      measurementFields.push({title: event.target.value});
      onMeasurementFieldsChange(measurementFields);
    }
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { value: '' }]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
    const measurements = [...measurementFields];
    measurements.splice(index, 1);
    onMeasurementFieldsChange(measurements);
  };

  return (
    <div className="">
      <form>
        {inputFields.map((inputField, index) => (
          <div key={index} className="input-group mb-4" style={{ width: "100%" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Enter measurement area e.g chest"
              value={inputField.value}
              onChange={(event) => handleInputChange(index, event)}
            />
            <div className="input-group-append">
              {index > 0 && (
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => handleRemoveFields(index)}
                >
                  <i className="glyphicon glyphicon-remove"></i> x
                </button>
              )}
            </div>
          </div>
        ))}
        <button
          className="btn"
          type="button"
          onClick={handleAddFields}
          style={{ backgroundColor: "#198754", color: "white" }}
        >
          <i className="glyphicon glyphicon-plus"></i> Add
        </button>
      </form>
    </div>
  );
};

export default DynamicInputFields;
