import React, { useEffect, useState, useContext } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../assets/constant";
import { useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Footer from "../layouts/Footer";
import { Controller } from "react-hook-form";

const AddMeasurements = (props) => {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm({});
  const [menu, setMenu] = useState(false);
  const [clothTypesList, setClothTypesList] = useState([]);
  const [userClothTypesList, setUserClothTypesList] = useState([]);
  const { id } = useParams();

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const fetchClothTypes = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_types`, {
        params: {
          active: "true",
        },
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setClothTypesList(response.data.body);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  const fetchClothSize = () => {
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_sizes`, {
      params: {
        customer: id,
      },
      headers: {
        Authorization: `Bearer ${currentUser.accessToken}`,
      },
    })
    .then(
      (response) => {
        const clothTypesRecords = response.data.body.map(
          (item) => item.clothType
        );
        const uniqueClothTypes = [
          ...new Map(
            clothTypesRecords.map((item) => [item["_id"], item])
          ).values(),
        ];
        setUserClothTypesList(uniqueClothTypes);
      },
      (error) => {
        toast.error(error.response.data.message, { toastId: 'error1', });
      }
    );
  };
  useEffect(() => {
    if (currentUser.role.name === 'admin') {
      toast.error('Admin can not create an order', { toastId: 'error1', });
      navigate('/');
    }
    fetchClothTypes();
    fetchClothSize();
  }, []);

  const [selectedClothing, setSelectedClothing] = useState("");
  const [measurements, setMeasurements] = useState([]);

  const handleInputChange = (index, value) => {
    setMeasurements(prevMeasurements => {
      const updatedMeasurements = [...prevMeasurements];
      updatedMeasurements[index].value = value;
      return updatedMeasurements;
    });
  };
  
  const handleClothingChange = (event) => {
    const clothTypeID = event.target.value;
    setSelectedClothing(clothTypeID);

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/measurements?clothType=${clothTypeID}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setMeasurements(response.data.body)
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  const { currentUser } = useContext(AuthContext);


  // const onSubmit =(data) =>{
  //    axios
  //     .post(
  //       `${process.env.REACT_APP_BASE_URL}/api/cloth_sizes`,
  //       {
  //         measurements: measurements.map(({ _id, value }) => ({
  //           measurementId: _id,
  //           size: value || "",
  //         })),
  //         clothtype: selectedClothing,
  //         customer: id,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${currentUser.accessToken}`,
  //         },
  //       }
  //     )
  //     .then(
  //       (response) => {
  //         toast.success(response.data.message);
  //         navigate(`/profile/${id}`)
  //       },
  //       (error) => {
  //         toast.error(error.response.data.message);
  //       }
  //     );
  // }
  
  const onSubmit = (data) => {
    if(data.title){
    axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/api/dynamicMeasurements`,
      {title:data.title},
      {
        headers: { Authorization: `Bearer ${currentUser.accessToken}` },
      }
    )
    .then((response) => {
      toast.success(response.data.message);
      navigate(`/profile/${id}`)
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
  } else{
    toast.error("Please Input the Title");
  }
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className=" container-fluid">
            <div className="">
              <div className="row">
                <div className="col-sm-12">
                  <Row className="mt-5">
                    <Col>
                      <span style={{ fontSize: "23px", fontWeight: "500" }}>
                        Add Measurements
                      </span>
                    </Col>
                    <Col>
                      <Button
                        variant="secondary"
                        className="btn btn-primary float-end mb-2"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {/* <Row className="mb-4">
              <Col md={5}>
                <label className="form-label">Select Clothing</label>
                <Form.Select
                  value={selectedClothing}
                  onChange={handleClothingChange}
                  className="form-control rounded-3"
                  name="clothType"
                >
                  <option value="" disabled>
                    Select a clothing
                  </option>
                  {clothTypesList?.filter((list) => {
     return !userClothTypesList?.some((item) => item?._id === list?._id);
  }).map((type) => (
                    <option key={type._id} value={type._id}>
                      {type.title}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row> */}

            {/* <div className="row">
              <div className="col-md-12"></div>
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {measurements.length > 0 ? (
                      measurements.map((value, index) => (
                        <Row key={index}>
                          <Col md={5}>
                            <div className="form-group">
                              <label>{value.title}</label>
                              <input
                                min="1"
                                type="number"
                                id={`quantity${index}`}
                                className="form-control"
                                onChange={e => handleInputChange(index, e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <div className="text-center">
                        <p>
                          <strong>List not available.</strong>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-md-12"></div>
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="">Title</label>
                          <Controller
                            name="title"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                className="form-control"
                                type="text"
                                name="title"
                                placeholder="Enter an measurement e.g. Neck"
                                id="title"
                                onChange={onChange}
                                required
                              />
                            )}
                            defaultValue=""
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};
export default AddMeasurements;
