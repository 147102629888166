import React, { useEffect, useState, useContext, useRef } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { alphaNumericPattern } from "../assets/constant";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { toast } from "react-toastify";
import DatePicke from "./DatePicke";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../assets/constant";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import { Autocomplete } from "@react-google-maps/api";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus,faSave,faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CloseButton from "react-bootstrap/CloseButton";
import {OverlayTrigger, Tooltip } from 'react-bootstrap';
import Footer from "../layouts/Footer";

const AddOrderr = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [loader, setLoader] = useState(false);
  const [clothTypesList, setClothTypesList] = useState([]);
  const [customersList, setcustomersList] = useState([]);
  const [clothTypes, setClothTypes] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [showAddServive, setShowAddServive] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [selectService, setSelectService] = useState([]);
  const [description, setDescription] = useState([]);
  const [servicePrice, setServicePrice] = useState(0);
  const [serviceId, setServiceId] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const autocompleteRef = useRef(null);
  const [channelSale, setChannelSale] = useState("");
  const [orderType, setOrderType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [delivery, setDelivery] = useState("");
  const [gender, setGender] = useState("");
  const [otherAddress, setOtherAddress] = useState("");
  const [clothTypeData, setClothTypeData] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clothTypeName, setClothTypeName] = useState("");

  const [material, setMaterial] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [collectionMethod, setCollectionMethod] = useState("Inprogress");
  const [staffId, setStaffId] = useState("");

  const [staffData, setStaffData] = useState([]);

  const [discount, setDiscount] = useState('');
  const [discountType, setDiscountType] = useState('');
  const [originalPrice, setOriginalPrice] = useState(0);

  const [showAddProduct, setShowAddProduct] = useState(false);
  const [selectedClothing, setSelectedClothing] = useState(null);
  const [productMeasurement, setProductMeasurement] = useState([]);

  const handleShowAddProduct = () => {
    setShowAddProduct(true);
  };

  const handleCloseAddProduct = () => {setShowAddProduct(false);setSelectedClothing(null)};

  const handleShowAddServive = (row) => {
    setShowAddServive(true);
  };
  const handleCloseAddServive = () => setShowAddServive(false);
  const handleClose = () => setShow(false);

  const handleDateSelected = (date) => {
    setSelectedDate(date);
  };

  const handlePlaceSelect = (place) => {
    setSelectedLocation(place);
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    const imageArray = Array.from(files);
    setSelectedImages(imageArray);
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  // Service Tooltip
  const tooltip = (
    <Tooltip id="tooltip">
      Add Service
    </Tooltip>
  );

// Product/ClothTypes Tooltip
  const addProductTooltip = (
    <Tooltip id="tooltip">
      Add Product
    </Tooltip>
  );

  // Product/ClothTypes List api
  const fetchClothTypes = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_types`, {
        params: {
          active: "true",
        },
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setClothTypesList(response.data.body);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  // useEffect for Product/ClothTypes
  useEffect(() => {
    fetchClothTypes();
  }, []);

  // onChange of Select Product/ClothTypes
  const handleClothingChange = (data) => {
    setSelectedClothing(data);
    setProductMeasurement([]);
  };

  function findRecordById(records, id) {
    for (const record of records) {
      if (record._id === id) {
        return record;
      }
    }
    return null;
  }

  // Fetch Customer Information api 
  const fetchCustomers = () => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/users`, {
        params: { user_role: "customer" },
        headers: { Authorization: `Bearer ${currentUser.accessToken}` },
      })
      .then((response) => {
        setLoader(false);
        setcustomersList(response.data.body);
        if (id) {
          const customer = findRecordById(response.data.body, id);
          if (!customer) {
            toast.error("Customer not found!", { toastId: "error1" });
          } else {
            handleCustomerChange(customer);
          }
        }
        setOptions(response.data.body);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    if (currentUser.role.name === "admin") {
      toast.error("Admin can not create an order", { toastId: "error1" });
      navigate("/");
    }
    fetchCustomers();
  }, []);

  const [selectedClothType, setSelectedType] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const handleClothTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const [options, setOptions] = useState(customersList);
  const [optionService, setOptionService] = useState(serviceData);
  const [disableButton, setDisableButton] = useState(true);

  // handle onChange Customer
  const handleCustomerChange = (event) => {
    setDisableButton(false);
    setSelectedLocation(event?.address);
    setOtherAddress(event?.otherAddress);
    setGender(event?.gender);
    setSelectedCustomer(event);
    setClothTypes([]);
    // setLoader(true);
    // axios
    //   .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_sizes`, {
    //     params: {
    //       customer: event._id,
    //     },
    //     headers: {
    //       Authorization: `Bearer ${currentUser.accessToken}`,
    //     },
    //   })
    //   .then(
    //     (response) => {
    //       const clothTypesRecords = response.data.body.map(
    //         (item) => item.clothType
    //       );
    //       const uniqueClothTypes = [
    //         ...new Map(
    //           clothTypesRecords.map((item) => [item["_id"], item])
    //         ).values(),
    //       ];
    //       setClothTypes(
    //         uniqueClothTypes.map((clothType) => {
    //           return {
    //             clothType: clothType,
    //             quantity: 1,
    //             price: 0,
    //           };
    //         })
    //       );
    //       setLoader(false);
    //     },
    //     (error) => {
    //       toast.error(error.response.data.message);
    //     }
    //   );
  };

  useEffect(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/users`, {
          params: {
            user_role: "tailor_staff",
          },
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        })
        .then(
          (response) => {
            setStaffData(
              response.data.body.length > 0 ? response.data.body : []
            );
          },
          (error) => {
            toast.error(error.response.data.message);
          }
        );
    } catch (error) {
      console.log(error);
    }
  }, []);

  const { currentUser } = useContext(AuthContext);

  // onSubmit of order 
  const onSubmit = (data) => {
    let orderItems = clothTypes.filter((item) => item.quantity > 0);

    const orderItemsList = orderItems.map((item) => ({
      clothType: item.clothType._id,
      quantity: parseInt(item.quantity),
      price: parseFloat(item?.clothType?.price),
    }));

    const additionalServicesList = selectService?.map((item) => ({
      service: item?._id,
      title: item?.title,
      quantity: parseInt(item?.quantity),
      price: parseFloat(item?.price),
    }));
    const formData = new FormData();
    formData.append("orderItemsList", JSON.stringify(orderItemsList));
    formData.append("totalPayment", billAmount);
    formData.append("paidAmount", amountPaid);
    formData.append("deliveryDate", selectedDate);
    formData.append("customer", JSON.stringify(selectedCustomer));
    formData.append(
      "additionalServicesList",
      JSON.stringify(additionalServicesList)
    );
    formData.append("comments", description);

    formData.append("channelSale", channelSale);
    formData.append("orderType", orderType);
    formData.append("phoneNumber", phoneNumber);
    if(selectedLocation){
    formData.append("address", JSON.stringify(selectedLocation));
  }
    formData.append("delivery", delivery);
    formData.append("gender", gender);
    formData.append("otherAddress", otherAddress);
    formData.append("material", material);
    formData.append("paymentMethod", paymentMethod);
    formData.append("collectionMethod", collectionMethod);

    if(staffId){
    formData.append("staffId", staffId);
    }
    formData.append("discount", discount);
    formData.append("discountType", discountType);
    formData.append("originalPrice", originalPrice);

    for (let i = 0; i < selectedImages.length; i++) {
      formData.append("orderImages", selectedImages[i]);
    }

    if (selectedDate !== "") {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/orders`, formData, {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        })
        .then(
          (response) => {
            toast.success(response.data.message);
            navigate("/orders");
          },
          (error) => {
            toast.error(error.response.data.message);
          }
        );
    } else {
      toast.error("Fill in the delivery date");
    }
  };

 // handleService Change 
  const handleService = (event) => {
    const modifiedServices = event?.map((service) => {
      return {
        ...service,
        quantity: 1,
      };
    });

    setSelectService(modifiedServices);
    // setServicePrice(event?.price)
    // setServiceId(event?._id);
    // calculateAmounts();
    // handleCloseAddServive();
  };

  // get Service api 
  const fetchService = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/service?status=${true}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setServiceData(
            response.data.body.length > 0 ? response.data.body : 0
          );
          setOptionService(response.data.body);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };
  const { handleSubmit } = useForm();

  const [billAmount, setBillAmount] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [totalRemaining, setTotalRemaining] = useState(0);


// handle ClothType Quantity Change
  const handleQuantityChange = (event, index) => {
    const updatedClothTypes = [...clothTypes];
    updatedClothTypes[index].quantity = event.target.value;
    setClothTypes(updatedClothTypes);
    calculateAmounts();
  };

  // handle ClothType Price Change
  const handlePriceChange = (event, index) => {
    const updatedClothTypes = [...clothTypes];
    updatedClothTypes[index].clothType.price = Number(
      event.target.value.replaceAll(",", "")
    );
    // updatedClothTypes[index].clothType.price = Number(event.target.value.replaceAll(",", ""));
    setClothTypes(updatedClothTypes);
    calculateAmounts();
  };

    // handle Service Price Change
  const handleServicePriceChange = (event, index) => {
    const updatedSelectService = [...selectService];
    updatedSelectService[index].price = Number(
      event.target.value.replaceAll(",", "")
    );
    setSelectService(updatedSelectService);
    calculateAmounts();
  };

  // handle Quantity Price Change
  const handleServiceQuantityChange = (event, index) => {
    const updatedSelectService = [...selectService];
    updatedSelectService[index].quantity = event.target.value;
    setSelectService(updatedSelectService);
    calculateAmounts();
  };

  // handle Amount Paid
  const handleAmountPaidChange = (event) => {
    setAmountPaid(event.target.value);
  };

  useEffect(() => {
    setTotalRemaining(billAmount - amountPaid);
  }, [amountPaid, billAmount]);

  useEffect(() => {
    fetchService();
  }, []);

// handle Calculate Amounts
  const calculateAmounts = () => {
    let totalAmount = 0;

    clothTypes.forEach((value) => {
      const price = parseFloat(value.clothType.price);
      const quantity = parseFloat(value.quantity);
      if (price && quantity) {
        const clothAmount = price * quantity;
        totalAmount += clothAmount;
      }
    });
    selectService?.forEach((value) => {
      const price = parseFloat(value.price);
      const quantity = parseFloat(value.quantity);
      if (price && quantity) {
        const serviceAmount = price * quantity;
        totalAmount += serviceAmount;
      }
    });
    // totalAmount=totalAmount+servicePrice;
    setOriginalPrice(totalAmount);
     
    if (discountType === "percentage") {
     const discountedValue= ((totalAmount * discount) / 100);
      setBillAmount( (totalAmount-discountedValue) >= 0 ? (totalAmount-discountedValue) : 0  );
    }
    else{
       setBillAmount(( totalAmount-discount) >= 0 ? (totalAmount-discount) : 0);
    }
  };

  useEffect(() => {
    calculateAmounts();
  }, [selectService, clothTypes,discount]);

  const handleSearchChange = (searchQuery) => {
    const filteredOptions = customersList.filter((customer) =>
      customer.name?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
    if (
      selectedCustomer &&
      !filteredOptions.some((option) => option._id === selectedCustomer._id)
    ) {
      filteredOptions.unshift(selectedCustomer);
    }

    setOptions(filteredOptions);
    setSelectService([]);
  };

  const handleClothType = (data) => {
    setShow(true);
    setLoading(true);
    setClothTypeName(data?.clothType?.title);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/clothTypes/${data.clothType._id}?customerId=${selectedCustomer?._id}`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          setClothTypeData(response?.data?.body?.filter(item => item.size !== null));
          setLoading(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setLoading(false);
        }
      );
  };

// Check the Product have the Measurement if yes add else show message assign it first

  // const handleProductMeasurementCheck = (clothType) => {
  //   setLoading(true);
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/api/clothTypes/${clothType}?customerId=${selectedCustomer?._id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${currentUser.accessToken}`,
  //         },
  //       }
  //     )
  //     .then(
  //       (response) => {
  //         setProductMeasurement(response?.data?.body?.filter(item => item.size !== null));
  //         setLoading(false);
  //         if(response?.data?.body?.filter(item => item.size !== null)?.length !=0){
  //           clothTypes.push({clothType:{price:selectedClothing?.price,title:selectedClothing?.title,_id:selectedClothing?._id},quantity:1
  //           });
          
  //         } else{
  //           toast.error("Assign Measurements to the Product");
  //         }
  //       },
  //       (error) => {
  //         toast.error(error.response.data.message);
  //         setLoading(false);
  //       }
  //     );
  // };

  const removeCloth = (index) => {
    setClothTypes(clothTypes.filter((_, i) => {return i !== index}));
  };

  // Submit button on Add Product Button
  const handleSubmitCloth = () => {
    if(selectedClothing){
    const found = clothTypes.find((element) => element.clothType._id === selectedClothing?._id);
    if (found) {
      toast.error("Already Added in List");
    }
    else {
      clothTypes.push({clothType:{price:selectedClothing?.price,title:selectedClothing?.title,_id:selectedClothing?._id},quantity:1});
      setShowAddProduct(false);
      calculateAmounts();
    }
  }else{
    toast.error('Please Select a Product');
  }
  };

  const SaveTooltip = (
    <Tooltip id="tooltip">
      Save
    </Tooltip>
  );

  return (
    <>
       {/* Add Service Modal */}
      <Modal  centered show={showAddServive} onHide={handleCloseAddServive}>
        <Modal.Header closeButton>
          <Modal.Title>Add Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <Select
                  value={selectService}
                  options={optionService}
                  onChange={handleService}
                  getOptionLabel={(serviceData) =>
                    `${serviceData?.title}(${serviceData?.price})`
                  }
                  getOptionValue={(serviceData) => serviceData?._id}
                  isSearchable
                  placeholder="Select a Title"
                  isMulti
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
 
     {/* Add Measurements Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{clothTypeName} Measurements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading == true ? (
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ColorRing
                visible={true}
                height="auto"
                width="20%"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={[
                  "#7638ff",
                  "#7638ff",
                  "#7638ff",
                  "#7638ff",
                  "#7638ff",
                  "#7638ff",
                ]}
              />
            </div>
          ) : (
            <>
            {clothTypeData.length > 0 ?
            <>
              {clothTypeData?.map((value, index) => (
                <Col lg={4} key={value._id}>
                  {value.measurement && (
                    <div className="form-group">
                      <label>{value.measurement.title}</label>
                      <input
                        min="1"
                        max="100"
                        type="text"
                        id={`measurement-${value._id}`}
                        className="form-control"
                        value={value.size || 0}
                        disabled
                      />
                    </div>
                  )}
                </Col> 
              ))}
              </>
              : <div className="text-center">Assign Measurements to the Product</div>}
            </>
          )}
        </Modal.Body>
      </Modal>

     {/* Add Product Modal */}
      <Modal  centered show={showAddProduct} onHide={handleCloseAddProduct}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <Select
                  options={clothTypesList}
                  onChange={handleClothingChange}
                  getOptionLabel={(clothTypesList) => clothTypesList?.title}
                  getOptionValue={(clothTypesList) => clothTypesList?._id}
                  isSearchable
                  placeholder="Select a Product"
                  defaultValue={selectedClothing?._id}
                />
            
              </div>
            </div>
          <Modal.Footer>
          <Button  disabled={loading} variant="success" onClick={handleSubmitCloth}>
            Save
          </Button>
        </Modal.Footer>
          </form>
        </Modal.Body>  
      </Modal>
      

      {loader === true ? (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ColorRing
            visible={true}
            height="auto"
            width="20%"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={[
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
            ]}
          />
        </div>
      ) : (
        <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <Sidebar />
          <div className="page-wrapper">
            <div className="content container-fluid">
              <div className="">
                <div className="row">
                  <div className="col-sm-12">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className='mb-2 mt-2'
                    size="2x"
                    onClick={() => navigate(`/orders`)}
                  />
                    <Row className="mb-5">
                      <Col>
                        {" "}
                        <span style={{ fontSize: "30px", fontWeight: "500" }}>
                          Add Order
                        </span>{" "}
                      </Col>

                      <Col>
                        {/* <div class="d-grid gap-2 d-md-flex justify-content-md-end" > */}
                        <div  style={{position:"fixed", bottom: "25px", right: "30px",zIndex:99,width:"100px"}}>
                        <OverlayTrigger placement="top" overlay={SaveTooltip}>
                          <Button
                            className="btn btn-primary float-end"
                            onClick={handleSubmit(onSubmit)}
                          >
                             <FontAwesomeIcon icon={faSave} />
                          </Button>
                          </OverlayTrigger>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <Row className="mb-5">
                <Col md={4}>
                  <Select
                    value={selectedCustomer}
                    onChange={handleCustomerChange}
                    options={options}
                    getOptionLabel={(customer) => customer.name}
                    getOptionValue={(customer) => customer._id}
                    isSearchable
                    onInputChange={handleSearchChange}
                    placeholder="Select a customer"
                  />
                </Col>
              </Row>

              <div className="row">
              <div class="justify-content-md-end p-3">  
                  <OverlayTrigger placement="top" overlay={addProductTooltip}>
                  <Button
                    className="btn btn-primary float-end"
                    onClick={() => setShowAddProduct(true)}
                    disabled={disableButton}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  </OverlayTrigger>
                </div>
                <label>Products</label>
                <div className="col-md-12"></div>
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {clothTypes.length > 0 ? (
                        <Row>
                          <Col md={4} lg={4} sm={4} xs={4}>
                            <label>Clothing</label>
                          </Col>
                          <Col md={4} lg={4} sm={3} xs={3}>
                            <label>Qty</label>
                          </Col>
                          <Col md={4} lg={4} sm={5} xs={5}>
                            <label>Price</label>
                          </Col>
                        </Row>
                      ) : (
                        <div className="text-center">
                          <p>
                            <strong>List not available.</strong>
                          </p>
                        </div>
                      )}
                      {clothTypes.length > 0
                        ? clothTypes.map((value, index) => {
                            return (
                              <Row key={index}>
                                <Col md={4} lg={4} sm={4} xs={4}>
                                  <div>
                                    <input
                                      type="text"
                                      id={"typeNumber" + index}
                                      className="form-control"
                                      style={{
                                        padding: "5px",
                                        color:"#621aff",
                                        backgroundColor:
                                          "#e2d5ff",
                                          cursor: "pointer",
                                      }}
                                      value={value.clothType.title}
                                      onClick={() => handleClothType(value)}
                                      readOnly
                                    />
                                  </div>
                                </Col>
                                <Col md={4} lg={4} sm={3} xs={3}>
                                  <div className="form-group">
                                    <input
                                      min="1"
                                      max="100"
                                      type="number"
                                      id={index}
                                      className="form-control"
                                      value={value.quantity}
                                      onChange={(event) =>
                                        handleQuantityChange(event, index)
                                      }
                                      placeholder="Quantity"
                                    />
                                  </div>
                                </Col>
                                <Col md={4} lg={4} sm={5} xs={5}>
                                  <Row key={index}>
                                    <Col xs={10}>
                                      <div className="form-group">
                                        <InputGroup
                                          placeholder="Price"
                                        >
                                          <InputGroup.Text>
                                            {currentUser?.currency
                                              ? currentUser?.currency
                                              : "£"}
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            value={Number(
                                              value?.clothType?.price
                                            ).toLocaleString("en-UK")}
                                            onChange={(event) =>
                                              handlePriceChange(event, index)
                                            }
                                            style={{ padding: "5px" }}
                                          />
                                        </InputGroup>
                                      </div>
                                    </Col>
                                    <Col
                                      md={2}
                                      lg={2}
                                      sm={2}
                                      xs={2}
                                      className="p-2"
                                    >
                                      <CloseButton
                                        className="d_none p-2 float-right"
                                        onClick={(e) => removeCloth(index)}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div class="justify-content-md-end p-3">
                  <OverlayTrigger placement="top" overlay={tooltip}>
                  <Button
                    className="btn btn-primary float-end"
                    onClick={() => handleShowAddServive()}
                    disabled={disableButton}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  </OverlayTrigger>
                 
                </div>
                <label>Additional Services</label>
                <div className="col-md-12"></div>
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {selectService?.length > 0 ? (
                        <Row>
                          <Col md={4} lg={4} sm={4} xs={4}>
                            <label>Service</label>
                          </Col>
                          <Col md={4} lg={4} sm={3} xs={3}>
                            <label>Qty</label>
                          </Col>
                          <Col md={4} lg={4} sm={5} xs={5}>
                            <label>Price</label>
                          </Col>
                        </Row>
                      ) : (
                        <div className="text-center">
                          <p>
                            <strong>List not available.</strong>
                          </p>
                        </div>
                      )}
                      {selectService.length > 0
                        ? selectService.map((value, index) => {
                            return (
                              <Row key={index}>
                                <Col md={4} lg={4} sm={4} xs={4}>
                                  <input
                                    min="1"
                                    max="100"
                                    type="text"
                                    id="typeNumber"
                                    style={{ padding: "5px" }}
                                    className="form-control"
                                    value={value?.title}
                                    readOnly
                                  />
                                </Col>
                                <Col md={4} lg={4} sm={3} xs={3}>
                                  <div className="form-group">
                                    <input
                                      min="1"
                                      max="100"
                                      type="number"
                                      id={"quantity" + index}
                                      className="form-control"
                                      defaultValue={value?.quantity}
                                      onChange={(event) =>
                                        handleServiceQuantityChange(
                                          event,
                                          index
                                        )
                                      }
                                      placeholder="Quantity"
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col md={4} lg={4} sm={5} xs={5}>
                                  <div className="form-group">
                                    <InputGroup
                                      min="1"
                                      id={"price" + index}
                                      placeholder="Price"
                                    >
                                      <InputGroup.Text>
                                        {currentUser?.currency
                                          ? currentUser?.currency
                                          : "£"}
                                      </InputGroup.Text>
                                      <Form.Control
                                        type="text"
                                        value={Number(
                                          value?.price
                                        ).toLocaleString("en-UK")}
                                        onChange={(event) =>
                                          handleServicePriceChange(event, index)
                                        }
                                        style={{ padding: "5px" }}
                                      />
                                    </InputGroup>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <label className="form-control-label">Customer Information</label>
                <div className="col-md-12"></div>
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <Row>
                        <Col md="3">
                            <label>
                              Channel Of Sale
                            </label>
                            <Form.Select
                              className={`form-control`}
                              // value={value}
                              onChange={(e) => {
                                setChannelSale(e.target.value);
                              }}
                              autoComplete="false"
                              required
                            >
                              <option value="">Select an option</option>
                              <option value="walkin">Walk In</option>
                              <option value="referral">Referral</option>
                              <option value="instagram">Instagram</option>
                              <option value="facebook">Facebook</option>
                              <option value="tiktok">TikTok</option>
                              <option value="whatsapp">Whatsapp</option>
                              <option value="existingcustomer">
                                Existing Customer
                              </option>
                            </Form.Select>
                        </Col>
                        <Col md="3">
                            <label>Order Type</label>
                            <Form.Select
                              className={`form-control`}
                              // value={value}
                              onChange={(e) => {
                                setOrderType(e.target.value);
                              }}
                              autoComplete="false"
                              required
                            >
                              <option value="">Select an option</option>
                              <option value="offtherack">Off the Rack</option>
                              <option value="custommade">Custom Made</option>
                            </Form.Select>
                        </Col>
                        <Col md="3">
                      <label>Material</label>
                      <Form.Select
                        className={`form-control`}
                        // value={value}
                        onChange={(e) => {
                          setMaterial(e.target.value);
                        }}
                        autoComplete="false"
                        required
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        <option value="storematerial">Store material</option>
                        <option value="customermaterial">
                          Customer material
                        </option>
                      </Form.Select>
                    </Col>
                        <Col md="3">
                            <label >Drop off method</label>
                            <Form.Select
                              className={`form-control`}
                              onChange={(e) => {
                                setDelivery(e.target.value);
                              }}
                              autoComplete="false"
                              required
                            >
                              <option value="">Select an option</option>
                              <option value="in-person">In-person</option>
                              <option value="courier">Courier</option>
                            </Form.Select>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="3">
                            <label >Other Address</label>
                            <input
                              type="text"
                              id="disabledTextInput"
                              placeholder="Other Address"
                              className="form-control"
                              defaultValue={otherAddress}
                              onChange={(e) => {
                                setOtherAddress(e.target.value);
                              }}
                            />
                        </Col>
                        <Col md="3">
                          <label>Address</label>
                          <Autocomplete
                            style={{ border: "0 !important" }}
                            onLoad={(autocomplete) =>
                              (autocompleteRef.current = autocomplete)
                            }
                            onPlaceChanged={() =>
                              handlePlaceSelect(
                                autocompleteRef.current.getPlace()
                              )
                            }
                          >
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search location..."
                              defaultValue={
                                selectedCustomer?.address?.formatted_address
                              }
                            />
                          </Autocomplete>
                        </Col>
                        <Col md="3">
                          <div className="form-group">
                            <label >Gender</label>
                            <Form.Select
                              className={`form-control`}
                              defaultValue={gender}
                              onChange={(e) => {
                                setGender(e.target.value);
                              }}
                              autoComplete="false"
                              required
                            >
                              <option value="" selected disabled>
                                Select an option
                              </option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </Form.Select>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col md="3">
                      <DatePicke
                        name="deliveryDate"
                        onDateSelected={handleDateSelected}
                      />
                    </Col>
                    <Col md="3">
                      <label >Bill Amount</label>
                      <InputGroup id="disabledTextInput" value={billAmount}>
                        <InputGroup.Text>
                          {currentUser?.currency ? currentUser?.currency : "£"}
                        </InputGroup.Text>
                        <Form.Control
                          disabled
                          value={billAmount.toLocaleString("en-UK", {
                            minimumFractionDigits: 0,
                          })}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="3">
                      <label>Amount Paid</label>
                      <InputGroup id="amountPaid" placeholder="Price">
                        <InputGroup.Text>
                          {currentUser?.currency ? currentUser?.currency : "£"}
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          value={amountPaid.toLocaleString("en-UK", {
                            minimumFractionDigits: 0,
                          })}
                          onChange={handleAmountPaidChange}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="3">
                      <label>Total Remaining</label>
                      <InputGroup id="disabledTextInput">
                        <InputGroup.Text>
                          {currentUser?.currency ? currentUser?.currency : "£"}
                        </InputGroup.Text>
                        <Form.Control
                          disabled
                          value={totalRemaining.toLocaleString("en-UK", {
                            minimumFractionDigits: 0,
                          })}
                        />
                      </InputGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md="3">
                      <label >Payment Method</label>
                      <Form.Select
                        className={`form-control`}
                        // value={value}
                        onChange={(e) => {
                          setPaymentMethod(e.target.value);
                        }}
                        autoComplete="false"
                        required
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        <option value="cash">Cash</option>
                        <option value="pointofsale">Point Of Sale</option>
                        <option value="banktransfer">Bank Transfer</option>
                      </Form.Select>
                    </Col>
                    <Col md="3">
                      <label >Staff</label>
                      <Form.Select
                        className={`form-control`}
                        // value={value}
                        onChange={(e) => {
                          setStaffId(e.target.value);
                        }}
                        autoComplete="false"
                        required
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        {staffData?.map((staff, index) => (
                          <option key={index} value={staff?._id}>
                            {staff?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md="3">
                      <label>Discount Type</label>
                      <Form.Select
                        className={`form-control`}
                        onChange={(e) => {
                          setDiscountType(e.target.value);
                          setDiscount("");
                        }}
                        autoComplete="false"
                        required
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        <option value="cash">Cash</option>
                        <option value="percentage">Percentage</option>
                      </Form.Select>
                    </Col>
{  discountType ?
                    <Col md="3">
                    <label>Discount</label>
                      <InputGroup id="amountPaid" placeholder="Discount">
                        <InputGroup.Text>
                        {discountType == "cash" ?
                          <>{currentUser?.currency ? currentUser?.currency : "£"}</> : "%" }
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          min={0}
                          max={ discountType == "percentage" && 100} 
                          value={discount.toLocaleString("en-UK", {
                            minimumFractionDigits: 0,
                          })}
                          onChange={(e) => {
                            setDiscount(e.target.value);
                          }}
                        />
                      </InputGroup>

                                  </Col>  : ""}
                  </Row>
                </div>
              </div>

              <Row>
                <Col md="5">
                  <div className="form-group">
                    <label>Upload Images</label>
                    <input
                      type="file"
                      id="images"
                      className="form-control"
                      multiple
                      accept="image/*"
                      onChange={handleImageChange}
                      min="1"
                      max="10"
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row >
                <Col md="12 pb-5">
                  <div className="form-group mb-3">
                    <label>Notes</label>
                    <Form.Group
                      className="mb-3"
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
       <Footer></Footer>
    </>
  );
};
export default AddOrderr;
