import React, { Fragment } from 'react';
import AppContainer from './appcontainer.jsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './authentication/AuthContext.jsx';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AppRouter = (props) => {
    return (
        <Router>
            <ToastContainer />
            <AuthProvider>
                <AppContainer />
            </AuthProvider>
        </Router >
    );
}


export default AppRouter;
