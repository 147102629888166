import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import GoogleMap from "../component/Googlemap";
import Googlemap from "../component/Googlemap";

const EditTailor = () => {
  const [menu, setMenu] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [date, setDate] = useState(new Date());
  const [currencyOptions, setcurrencyOptions] = useState([
    { id: 1, text: "Select Currency" },
    { id: 2, text: "EUR Euro" },
    { id: 3, text: "INR Indoan Rupee" },
    { id: 4, text: "USD- US Dollar" },
  ]);
  const [countryOptions, setcountryOptions] = useState([
    { id: 1, text: "Select Country" },
    { id: 2, text: "Afghanistan" },
    { id: 3, text: "Albania" },
    { id: 4, text: "American Samoa" },
    { id: 5, text: "Algeria" },
    { id: 6, text: "Andorra" },
    { id: 7, text: "Angola" },
    { id: 8, text: "Anguilla" },
    { id: 9, text: "United States" },
  ]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Edit Tailor</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {/* <h4 className="card-title">Basic Info</h4> */}
                    <form action="#">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label></label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="The Elegant Stitch"
                            />
                          </div>

                          {/* <GoogleMap/> */}

                          <div className="form-group">
                            <label></label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="elegantstitch@gmail.com"
                            />
                          </div>

                          <div className="form-group">
                            <label></label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="01234567890"
                            />
                          </div>
                          <div className="form-group mt-5">
                            <InputGroup className="">
                              <Form.Control
                                className="form-control"
                                placeholder="Upload Logo"
                                aria-label="Upload Logo"
                                aria-describedby="basic-addon2"
                              />
                              <Button
                                variant="outline-secondary"
                                id="button-addon2"
                                className=""
                              >
                                Chose File
                              </Button>
                            </InputGroup>
                          </div>
                        </div>
                        <Googlemap/>
                        <div className="col-md-6"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <Button variant="secondary" className="save-button fs-5">
              Save
            </Button>{" "}
          </div>
        </div>
      </div>
    </>
  );
};
export default EditTailor;
