import React, { useEffect, useState, useContext, useRef } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DatePicke from "./DatePicke";
import Select from 'react-select';
import CloseButton from 'react-bootstrap/CloseButton';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../assets/constant";
import { useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { Autocomplete } from "@react-google-maps/api";
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faSave} from '@fortawesome/free-solid-svg-icons';
import { ColorRing } from "react-loader-spinner";
import {OverlayTrigger, Tooltip } from 'react-bootstrap';
import Footer from "../layouts/Footer";

const AddOrderr = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [clothTypesList, setClothTypesList] = useState([]);
  const [customersList, setcustomersList] = useState([]);
  const [clothTypes, setClothTypes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [orderStatus, setOrderStatus] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [showAddServive, setShowAddServive] = useState(false);
  const [orderImages, setOrderImages] = useState([]);
  const [imagesDb, setImagesDb] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [selectService, setSelectService] = useState([]);
  const [servicePrice, setServicePrice] = useState(0);
  const [deleteImage, setdeleteImage] = useState('');
  const [serviceId, setServiceId] = useState("");
  const [billAmount, setBillAmount] = useState(0);
  const [description, setDescription] = useState('');

  const [showAddCloth, setShowAddCloth] = useState(false);
  const [clothList, setClothList] = useState([]);
  const [clothData, setClothData] = useState({});
  const [filterList, setFilterList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const autocompleteRef = useRef(null);
  const [channelSale, setChannelSale] = useState("");
  const [orderType, setOrderType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [delivery, setDelivery] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [otherAddress, setOtherAddress] = useState("");
  const [clothTypeData, setClothTypeData] = useState([]);
  const [showMeasure, setShowMeasure] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clothTypeName, setClothTypeName] = useState('');

  
  const [material, setMaterial] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [staffId, setStaffId] = useState("");
  const [staffData, setStaffData] = useState([]);
  const [collectionMethod, setCollectionMethod] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState('');
  const [originalPrice, setOriginalPrice] = useState(0);




  const handleImageChange = (event) => {
    const files = event.target.files;
    const imageArray = Array.from(files);
    setSelectedImages(imageArray);
  };

  const handleDateSelected = (date) => {
    setSelectedDate(date);
  };

  const handleCloseMeasure = () => setShowMeasure(false);

  const handleShowAddServive = (row) => { setShowAddServive(true) };
  const handleCloseAddServive = () => setShowAddServive(false);

  const handleShowAddCloth = () => { setShowAddCloth(true) };
  const handleCloseAddCloth = () => setShowAddCloth(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handlePlaceSelect = (place) => {
    setSelectedLocation(place);
  };

  useEffect(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/users`, {
          params: {
            user_role: "tailor_staff",
          },
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        })
        .then(
          (response) => {
            setStaffData(
              response.data.body.length > 0 ? response.data.body : []
            );
          },
          (error) => {
            toast.error(error.response.data.message);
          }
        );
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchOrder = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/orders/${id}`, {
        params: {
          active: "true",
        },
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setSelectedCustomer(response.data.body.customer);
          setClothTypesList(response.data.body.orderItemsList);
          const dateStr = response.data.body.deliveryDate;
          const year = dateStr.substring(0, 4);
          const month = dateStr.substring(5, 7);
          const day = dateStr.substring(8, 10);
          const formattedDate = `${year}-${month}-${day}`;
          setSelectedDate(formattedDate);
          setBillAmount(response?.data?.body?.totalPayment);
          setOrderStatus(response.data.body.orderStatus);
          setAmountPaid(response?.data?.body?.paidAmount ? response?.data?.body?.paidAmount : 0);
          setOrderImages(response.data.body.orderImagesWithSignedUrls);
          setImagesDb(response.data.body.orderImages);
          setSelectService(response?.data?.body?.additionalServicesList);
          setServiceId(response.data.body.service?._id)
          setDescription(response?.data?.body?.comments);
          setChannelSale(response?.data?.body?.customerInformation?.channelSale);
          setOrderType(response?.data?.body?.customerInformation?.orderType);
          setPhoneNumber(response?.data?.body?.customerInformation?.phoneNumber);
          setSelectedLocation(response.data.body.customer?.address);
          setDelivery(response?.data?.body?.customerInformation?.delivery);
          setAddress(response?.data?.body?.customer?.address);
          setGender(response?.data?.body?.customer?.gender);
          setOtherAddress(response?.data?.body?.customer?.otherAddress);
          setCollectionMethod(response?.data?.body?.collectionMethod);
          setPaymentMethod(response?.data?.body?.paymentMethod);
          setMaterial(response?.data?.body?.material);
          setStaffId(response?.data?.body?.staff);
          setDiscount(response?.data?.body?.discount ? response?.data?.body?.discount :0);
          setDiscountType(response?.data?.body?.discountType);
          setOriginalPrice(response?.data?.body?.originalPrice)
          // if(response.data.body.service?.price){
          // setServicePrice(response.data.body.service.price)
          // }else{setServicePrice(0)}

          handleClothSize(response.data.body.customer?._id);
        },

        (error) => {
          toast.error(error.response.data.message, { toastId: "error1" });
        }
      );
  };


  const handleOrderStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setOrderStatus(selectedStatus);
  };

  useEffect(() => {
    if (currentUser.role.name === 'admin') {
      toast.error('Admin can not edit an order', { toastId: 'error1', });
      navigate('/');
    }
    fetchOrder();
  }, []);

  const [selectedClothType, setSelectedType] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [optionService, setOptionService] = useState(serviceData);
  const [optionCloth, setOptionCloth] = useState(clothData);


  const handleClothTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const [options, setOptions] = useState(customersList);

  // useEffect(() => {
  //   handleClothSize(clothId);
  // console.log("cominggggg");
  // }, [clothId]);

  const handleClothSize = (customerId) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_sizes`, {
        params: {
          customer: customerId,
        },
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          const clothTypesRecords = response.data.body.map(item => item.clothType);
          const uniqueClothTypes = [...new Map(clothTypesRecords.map(item => [item['_id'], item])).values()];
          setClothList(
            uniqueClothTypes.map((clothType) => {
              return {
                clothType: clothType,
                quantity: 0,
                price: 0,
              };
            })
          );
          setOptionCloth(uniqueClothTypes.map((clothType) => {
            return {
              clothType: clothType,
              quantity: 0,
              price: 0,
            };
          }));
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  const fetchService = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/service?status=${true}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          // setServiceData(response.data.body.length> 0 ? response.data.body : 0 );
          const modifiedServices = response.data.body?.map(service => {
            return {
              ...service,
              service: { _id: service?._id, title: service?.title }
            };
          });
          setServiceData(modifiedServices);
          setOptionService(modifiedServices);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };
  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setOrderStatus(selectedStatus);
  };


  const { currentUser } = useContext(AuthContext);

  const onSubmit = (data) => {

    const orderItemsList = clothTypesList.map((item) => ({
      clothType: item.clothType?._id,
      quantity: parseInt(item.quantity),
      price: parseFloat(item.price),
    }));


    const formData = new FormData();
    formData.append("paidAmount", amountPaid);
    formData.append("deliveryDate", selectedDate);
    formData.append("orderStatus", orderStatus);

    // if(serviceId != "" && serviceId != undefined){
    // formData.append("service",serviceId);
    // }

    const additionalServicesList = selectService?.map((item) => ({
      service: item?.service?._id,
      title: item?.service?.title,
      quantity: parseInt(item?.quantity),
      price: parseFloat(item?.price),
    }));

    formData.append("additionalServicesList", JSON.stringify(additionalServicesList));
    formData.append("totalPayment", billAmount);
    formData.append("orderItemsList", JSON.stringify(orderItemsList));
    formData.append("comments", description);

    formData.append("channelSale", channelSale);
    formData.append("orderType", orderType);
    formData.append("phoneNumber", phoneNumber);
    formData.append("address", JSON.stringify(selectedLocation));
    formData.append("delivery", delivery);
    formData.append("gender", gender);
    formData.append("customerId", selectedCustomer?._id);
    formData.append("otherAddress", otherAddress);
    formData.append("material", material);
    formData.append("paymentMethod", paymentMethod);
    if(staffId != "" && staffId != undefined){
    formData.append("staffId", staffId);
  }
    formData.append("collectionMethod", collectionMethod);
    formData.append("discount", discount == null ? 0 :discount);
    formData.append("discountType", discountType);
    formData.append("originalPrice", originalPrice);

    for (let i = 0; i < selectedImages.length; i++) {
      formData.append("orderImages", selectedImages[i]);
    }
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/orders/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
      )
      .then(
        (response) => {
          toast.success(response.data.message);
          // navigate('/orders')
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  const {
    handleSubmit,
  } = useForm();

  const [amountPaid, setAmountPaid] = useState(0);
  const [image, setImage] = useState("");

  const [totalRemaining, setTotalRemaining] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => { setShow(true); };

  const handleQuantityChange = (event, index) => {
    const updatedClothTypes = [...clothTypes];
    updatedClothTypes[index].quantity = event.target.value;
    setClothTypes(updatedClothTypes);
    calculateAmounts();
  };


  console.log("amountPaid........",amountPaid);

  const handlePriceChange = (event, index) => {
    const updatedClothTypes = [...clothTypes];
    updatedClothTypes[index].price = Number(
      event.target.value.replaceAll(",", "")
    );
    setClothTypes(updatedClothTypes);
    calculateAmounts();
  };
  const handleServicePriceChange = (event, index) => {
    const updatedSelectService = [...selectService];
    updatedSelectService[index].price = Number(
      event.target.value.replaceAll(",", "")
    );
    setSelectService(updatedSelectService);
    calculateAmounts();
  };

  const handleServiceQuantityChange = (event, index) => {
    const updatedSelectService = [...selectService];
    updatedSelectService[index].quantity = event.target.value;
    setSelectService(updatedSelectService);
    calculateAmounts();
  };
  const handleService = (event) => {
    const modifiedServices = event?.map(service => {
      return {
        ...service,
        quantity: 1,
      };
    });

    setSelectService(modifiedServices);
    // setSelectService(event);
    // setServicePrice(event?.price);
    // setServiceId(event?._id);
    // handleCloseAddServive();
    calculateAmounts();

  };

  const handleAmountPaidChange = (event) => {
    const enteredAmount =event.target.value;
    if (enteredAmount <= billAmount) {
      setAmountPaid(enteredAmount);
    }
  }

  useEffect(() => {
        setTotalRemaining(billAmount - amountPaid);
  }, [amountPaid, billAmount]);

  const calculateAmounts = () => {
    let totalAmount = 0;
    // setClothTypes(clothTypesList);
    clothTypesList.forEach((value) => {
      const price = parseFloat(value.price);
      const quantity = parseFloat(value.quantity);

      if (price && quantity) {
        const clothAmount = price * quantity;
        totalAmount += clothAmount;
      }
    });
    selectService?.forEach((value) => {
      const price = parseFloat(value.price);
      const quantity = parseFloat(value.quantity);
      if (price && quantity) {
        const serviceAmount = price * quantity;
        totalAmount += serviceAmount;
      }
    });
    setOriginalPrice(totalAmount);
    if (discountType === "percentage") {
     const discountedValue= ((totalAmount * discount) / 100);
      setBillAmount((totalAmount-discountedValue) >= 0 ? (totalAmount-discountedValue) : 0);
    }
    else{
       setBillAmount((totalAmount-discount) >= 0 ? (totalAmount-discount) : 0);
    }
  };
  useEffect(() => {
    fetchService();
  }, []);
  useEffect(() => {
    calculateAmounts();
  }, [discount]);


  const showImage = (data, index) => {
    var valueAtIndex = imagesDb.at(index);
    setdeleteImage(valueAtIndex)
    setImage(data);
    handleShow();
  }
  const deleteOrderImage = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/api/ordersImage`,

        {
          data: {
            orderId: id,
            imageId: deleteImage
          },
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          }
        },
      )
      .then(
        (response) => {
          toast.success(response.data.message);
          handleClose();
          window.location.reload();
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };
  const handleSearchChange = (searchQuery) => {
    const filteredOptions = customersList.filter((customer) =>
      customer.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    if (selectedCustomer && !filteredOptions.some((option) => option._id === selectedCustomer._id)) {
      filteredOptions.unshift(selectedCustomer);
    }

    setOptions(filteredOptions);
  };

  // const removeService = () => {
  //   setSelectService("");
  //   setServicePrice(0);
  //   calculateAmounts();
  //   setServiceId("");
  // };

  const removeCloth = (index) => {
    setClothTypesList(clothTypesList.filter((item, i) => i !== index));
  };

  const removeService = (index) => {
    setSelectService(selectService?.filter((item, i) => i !== index));
  };

  useEffect(() => {
    setClothTypesList(clothTypesList);
    setClothTypes(clothTypesList);
    calculateAmounts();
  }, [clothTypesList]);


  useEffect(() => {
    calculateAmounts();
    setClothTypes(clothTypesList);
  }, [selectService]);


  const listCloth = () => {

    const found = clothTypesList.find((element) => element.clothType._id === clothData?.clothType?._id);

    if (found) {
      toast.error("Already Added in List");
    }
    else {
      clothTypesList.push(clothData);
      setShowAddCloth(false);
    }

  };
  const handleDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/api/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
      )
      .then(
        (response) => {
          toast.success(response.data.message);
          navigate('/orders')
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  const handleCloth = (data) => {
    setClothData(data);
  };

  const handleSubmitCloth = () => {
    // clothTypesList.push(clothData);
    listCloth();
    // setShowAddCloth(false);
  };

  const renderImages = () => {
    return (
      <Row>
        <Col>
          <h3>Order Images</h3>
          <div className="d-flex flex-wrap">
            {orderImages.map((image, index) => (
              <div key={index} className="mt-3 mx-2" style={{ maxWidth: "25%" }}>
                <img src={image.signedUrl} alt={`Order Image ${index + 1}`} style={{ maxWidth: "100%", maxHeight: "200px" }} onClick={() => showImage(image.signedUrl, index)} />
              </div>
            ))}
          </div>
        </Col>
      </Row>
    );
  };


  const handleClothType = (data) => {
    setShowMeasure(true);
    setLoading(true);
    setClothTypeName(data?.clothType?.title)
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/clothTypes/${data.clothType._id}?customerId=${selectedCustomer?._id}`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          setClothTypeData(response?.data?.body?.filter(item => item.size !== null));
          setLoading(false);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  const tooltip = (
    <Tooltip id="tooltip">
      Add Service
    </Tooltip>
  );

  const SaveTooltip = (
    <Tooltip id="tooltip">
      Save
    </Tooltip>
  );

  return (
    <>
    <Modal show={showMeasure} onHide={handleCloseMeasure}>
        <Modal.Header closeButton>
          <Modal.Title>{clothTypeName} Measurements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
 
 {loading == true ? 

<div
className=""
style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}}
>
<ColorRing
  visible={true}
  height="auto"
  width="20%"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  colors={[
    "#7638ff",
    "#7638ff",
    "#7638ff",
    "#7638ff",
    "#7638ff",
    "#7638ff",
  ]}
/>
</div> :
<>
          {clothTypeData?.map((value, index) => (
            <Col lg={4} key={value._id}>
              {value.measurement && (
                <div className="form-group">
                  <label>{value.measurement.title}</label>
                  <input
                    min="1"
                    max="100"
                    type="text"
                    id={`measurement-${value._id}`}
                    className="form-control"
                    value={value.size || ""}
                    disabled
                  />
                </div>
              )}
            </Col>
          ))}
</>

              }        
        </Modal.Body>
      </Modal>

      <Modal show={showAddCloth} onHide={handleCloseAddCloth}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <Select
                  options={optionCloth}
                  onChange={handleCloth}
                  getOptionLabel={(optionCloth) => optionCloth?.clothType?.title}
                  getOptionValue={(optionCloth) => optionCloth?.clothType?._id}
                  isSearchable
                  placeholder="Select a Product"
                />
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseAddCloth}>
                Cancel
              </Button>
              <Button variant="success" onClick={handleSubmitCloth}>
                Add
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <Modal  centered show={showAddServive} onHide={handleCloseAddServive}>
        <Modal.Header closeButton>
          <Modal.Title>Add Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <Select
                  value={selectService}
                  options={optionService}
                  onChange={handleService}
                  getOptionLabel={(serviceData) => `${serviceData?.title}(${serviceData?.price})`}
                  getOptionValue={(serviceData) => serviceData?.service?._id}
                  isSearchable
                  placeholder="Select a Title"
                  isMulti
                />
              </div>
            </div>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddServive}>
            Cancel
          </Button>
          <Button variant="success">
            Save
          </Button>
        </Modal.Footer> */}
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div className="d-flex flex-wrap">
                <div className="mt-3 mx-2" style={{ maxWidth: "50%" }}>
                  <img src={image} style={{ maxWidth: "200%", maxHeight: "300px" }} />
                </div>
              </div>
            </Col>
          </Row>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="danger" onClick={deleteOrderImage}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="">
              <div className="row">
                <div className="col-sm-12">
                  <Row >
                    <Col> <span style={{ fontSize: "30px", fontWeight: "500" }}>Edit Order</span></Col>
                    <Col>
                    <div class="justify-content-md-end">
                      
                      {/* <div class="d-grid gap-2 d-md-flex justify-content-md-end"> */}
                        {/* <Button
                  className="btn btn-danger float-end"
                  onClick={handleDelete}
                >
                  Delete
                </Button> */}
                             

                        <Button
                          className="btn btn-primary float-end"
                          onClick={() => handleShowAddCloth()}
                        >
                          {/* Add Cloth */}
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        <div  style={{position:"fixed", bottom: "0px", right: "-30px",zIndex:99,width:"100px"}}>
                        <OverlayTrigger placement="top" overlay={SaveTooltip}>
                        <Button
                          className="btn btn-primary mb-4 "
                          onClick={handleSubmit(onSubmit)}
                         
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                        </OverlayTrigger>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <Row className="mb-3">
              <Col md="3">
                <label>Customer Name</label>
                <input
                  type="text"
                  id="disabledTextInput"
                  className="form-control"
                  value={selectedCustomer.name}
                  disabled
                />
              </Col>
              <Col md="3">
                <label>Customer Email</label>
                <input
                  type="text"
                  id="disabledTextInput"
                  className="form-control"
                  value={selectedCustomer.email}
                  disabled
                />
              </Col>

              <Col md="4">
                <label>Customer Phone Number</label>
                <input
                  type="text"
                  id="disabledTextInput"
                  className="form-control"
                  value={selectedCustomer.phoneNumber}
                  disabled
                />
              </Col>
            </Row>

            <div className="row">
              <div className="col-md-12"></div>
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {clothTypesList.length > 0 ? (
                      <Row>
                        <Col md={4} lg={4} sm={4} xs={4}>
                          <label>Clothing</label>
                        </Col>
                        <Col md={4} lg={4} sm={3} xs={3} >
                          <label>Qty</label>
                        </Col>
                        <Col md={4} lg={4} sm={5} xs={5} >
                          <label>Price</label>
                        </Col>
                      </Row>
                    ) : (
                      <div className="text-center">
                        <p>
                          <strong>List not available.</strong>
                        </p>
                      </div>
                    )}
                    {clothTypesList.length > 0 ? (
                      clothTypesList.map((value, index) => {
                        return (
                          <Row key={index}>
                            <Col md={4} lg={4} sm={4} xs={4} style={{ padding: "0 !important" }} >
                              <input
                                type="text"
                                id="typeNumber"
                                className="form-control"
                                value={value.clothType?.title}
                                // style={{ padding: "1px",backgroundColor:"#e9ecef"}}
                                style={{padding: "5px", color:"#621aff",
                                backgroundColor:
                                  "#e2d5ff",
                                  cursor: "pointer",
                                }}

                                onClick={() => handleClothType(value)}
                                readOnly
                              />
                            </Col>
                            <Col md={4} lg={4} sm={3} xs={3}>
                              <div className="form-group">
                                <input
                                  min="1"
                                  max="100"
                                  type="number"
                                  id={"quantity" + index}
                                  className="form-control"
                                  value={value.quantity}
                                  onChange={(event) => handleQuantityChange(event, index)}
                                  placeholder="Quantity"
                                // disabled
                                />
                              </div>
                            </Col>
                            <Col md={4} lg={4} sm={5} xs={5}>
                              <Row>
                                <Col xs={10}>
                                  <div className="form-group">
                                    <InputGroup
                                      // max="100"
                                      id="price"
                                      placeholder="Price"
                                    // disabled
                                    >
                                      <InputGroup.Text>{(currentUser?.currency ? currentUser?.currency : "£")}</InputGroup.Text>
                                      <Form.Control className="p-1" value={Number(
                                          value?.price
                                        ).toLocaleString("en-UK")} min="0" type="text" onChange={(event) => handlePriceChange(event, index)} />
                                    </InputGroup>
                                  </div>
                                </Col>
                                <Col md={2} lg={2} sm={2} xs={2} className="p-2" >
                                  <CloseButton className="d_none float-right" onClick={(e) => removeCloth(index, e)} />
                                </Col>
                              </Row>
                            </Col>

                          </Row>
                        );
                      })
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              
              <div class="justify-content-md-end p-3">
              <label>Additional Services</label>
              <OverlayTrigger placement="top" overlay={tooltip}>
              <Button
                          className="btn btn-primary float-end"
                          onClick={() => handleShowAddServive()}
                        >
                         <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        </OverlayTrigger>
                        </div>
              <div className="col-md-12"></div>
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {selectService?.length > 0 ? (
                      <Row>
                        <Col md={4} lg={4} sm={4} xs={4}>
                          <label>Service</label>
                        </Col>
                        <Col md={4} lg={4} sm={3} xs={3}>
                          <label>Qty</label>
                        </Col>
                        <Col md={4} lg={4} sm={5} xs={5}>
                          <label>Price</label>
                        </Col>
                      </Row>
                    ) : (
                      <div className="text-center">
                        <p>
                          <strong>List not available.</strong>
                        </p>
                      </div>
                    )}
                    {selectService?.length > 0 ? (
                      selectService?.map((value, index) => {
                        return (
                          <Row key={index}>
                            <Col md={4} lg={4} sm={4} xs={4}>
                              <input
                                min="1"
                                max="100"
                                type="text"
                                id="typeNumber"
                                className="form-control"
                                value={value?.title}
                                disabled
                              />
                            </Col>
                            <Col md={4} lg={4} sm={3} xs={3}>
                              <div className="form-group">
                                <input
                                  min="1"
                                  max="100"
                                  type="number"
                                  id={"quantity" + index}
                                  className="form-control"
                                  defaultValue={value?.quantity}
                                  onChange={(event) => handleServiceQuantityChange(event, index)}
                                  placeholder="Quantity"
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col md={4} lg={4} sm={5} xs={5}>
                              <Row>
                                <Col xs={10}>
                                  <div className="form-group">
                                    <InputGroup
                                      min="1"
                                      // max="100"
                                      id={"price" + index}
                                      placeholder="Price"
                                    >
                                      <InputGroup.Text>{(currentUser?.currency ? currentUser?.currency : "£")}</InputGroup.Text>
                                      <Form.Control                                         className="p-1"
 type="text" min="0"  value={Number(
  value?.price
).toLocaleString("en-UK")} onChange={(event) => handleServicePriceChange(event, index)} />
                                    </InputGroup>
                                  </div>

                                </Col>
                                <Col  md={2} lg={2} sm={2} xs={2} className="p-1 ">
                                  <CloseButton className="d_none p-2 float-right" onClick={(e) => removeService(index, e)} />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        );
                      })
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <label>Customer Information</label>
            
              
                <div className="card">
                  <div className="card-body">
                  <Row>
                      <Col md="4">
                          <label>Channel Of Sale</label>
                          <Form.Select
                            className="form-control"
                            value={channelSale}
                            onChange={(e) => { setChannelSale(e.target.value) }}
                            autoComplete="false"
                            required
                          >
                            <option value="">Select an option</option>
                            <option value="walkin">Walk In</option>
                            <option value="referral">Referral</option>
                            <option value="instagram">Instagram</option>
                            <option value="facebook">Facebook</option>
                            <option value="tiktok">TikTok</option>
                            <option value="whatsapp">Whatsapp</option>
                            <option value="existingcustomer">Existing Customer</option>
                          </Form.Select>
                      </Col>
                      <Col md="4">
                          <label>Order Type</label>
                          <Form.Select
                            className={`form-control`}
                            value={orderType}
                            onChange={(e) => { setOrderType(e.target.value) }}
                            autoComplete="false"
                            required
                          >
                            <option value="">Select an option</option>
                            <option value="offtherack">Off the Rack</option>
                            <option value="custommade">Custom Made</option>
                          </Form.Select>
                      </Col>
                      <Col md="4">
                      <label>Material</label>
                      <Form.Select
                        className={`form-control`}
                        value={material}
                        onChange={(e) => {
                          setMaterial(e.target.value);
                        }}
                        autoComplete="false"
                        required
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        <option value="storematerial">Store material</option>
                        <option value="customermaterial">
                          Customer material
                        </option>
                      </Form.Select>
                    </Col>
</Row>
<Row>
                      <Col md="4">
                          <label>Drop off method</label>
                          <Form.Select
                            value={delivery}
                            className="form-control"
                            onChange={(e) => { setDelivery(e.target.value) }}
                            autoComplete="false"
                            required
                          >
                            <option value="" selected disabled>Select an option</option>
                            <option value="in-person">In-person</option>
                            <option value="courier">Courier</option>
                          </Form.Select>
                      </Col>
                      <Col md="4">
                          <label>Other Address</label>
                          <input
                            type="text"
                            id="disabledTextInput"
                            placeholder="Other Address"
                            className="form-control"
                            defaultValue={otherAddress}
                            onChange={(e) => { setOtherAddress(e.target.value) }}
                          />
                      </Col>
                      <Col md="4">
                        <label>Address</label>
                        <Autocomplete
                          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                          onPlaceChanged={() =>
                            handlePlaceSelect(autocompleteRef.current.getPlace())
                          }
                        >
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search location..."
                            defaultValue={selectedLocation?.formatted_address}
                          />
                        </Autocomplete>
                      </Col>
                      </Row>
<Row>     
                      <Col md="4">
                          <label>Gender</label>
                          <Form.Select
                            className={`form-control`}
                            value={gender}
                            onChange={(e) => { setGender(e.target.value) }}
                            autoComplete="false"
                            required
                          >
                            <option value="" selected disabled>Select an option</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </Form.Select>
                      </Col>
                    </Row>
                  </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">

            <Row>
              <Col md="3">
                <label>Bill Amount</label>
                <InputGroup
                  id="disabledTextInput"
                  value={billAmount}
                  disabled
                >
                  <InputGroup.Text>{(currentUser?.currency ? currentUser?.currency : "£")}</InputGroup.Text>
                  <Form.Control disabled type="text" value={billAmount?.toLocaleString('en-UK', { minimumFractionDigits: 0 })} />
                </InputGroup>
              </Col>
              <Col md="3">
                <label>Amount Paid</label>
                <InputGroup
                  id="amountPaid"
                  placeholder="Price"
                >
                  <InputGroup.Text>{(currentUser?.currency ? currentUser?.currency : "£")}</InputGroup.Text>
                  <Form.Control
                  type="number" value={amountPaid}
                  onChange={handleAmountPaidChange} />
                </InputGroup>
              </Col>
              <Col md="3">
                <label>Total Remaining</label>
                <InputGroup
                  id="disabledTextInput"
                >
                  <InputGroup.Text>{(currentUser?.currency ? currentUser?.currency : "£")}</InputGroup.Text>
                  <Form.Control type="text" disabled value={totalRemaining?.toLocaleString('en-UK', { minimumFractionDigits: 0 })} />
                </InputGroup>
              </Col>
              {/* <Col md="3">
                <label className="form-label">Service</label>
                {selectService != null &&  <CloseButton onClick={removeService} />}
                <input
                  type="text"
                  id="disabledTextInput"
                  className="form-control"
                  value={[selectService?.title, ' ', selectService?.price].join('')}
                  disabled
                />
              </Col> */}
                  <Col md="3">
                      <label>Payment Method</label>
                      <Form.Select
                        className={`form-control`}
                        value={paymentMethod}
                        onChange={(e) => {
                          setPaymentMethod(e.target.value);
                        }}
                        autoComplete="false"
                        required
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        <option value="cash">Cash</option>
                        <option value="pointofsale">Point Of Sale</option>
                        <option value="banktransfer">Bank Transfer</option>
                      </Form.Select>
                    </Col>
            </Row>
           
            <Row>
              <Col md="3">
                <DatePicke selectedDateprop={selectedDate} onDateSelected={handleDateSelected} />
              </Col>
              <Col md="3">
                <label>Order Status</label>
                <Form.Select
                  value={orderStatus}
                  onChange={handleOrderStatusChange}
                  className="form-control"
                  name="orderStatus"
                  required
                >
                  <option value="" disabled>Select Order Status</option>
                  <option value="complete">Complete</option>
                  <option value="incomplete">Incomplete</option>
                </Form.Select>
              </Col>
            
              <Col md="3">
                      <label>Collection method</label>
                      <Form.Select
                        className={`form-control`}
                        value={collectionMethod}
                        onChange={(e) => {
                          setCollectionMethod(e.target.value);
                        }}
                        autoComplete="false"
                        required
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        <option value="In-person">In-person</option>
                        <option value="courierDelivery">Courier Delivery</option>
                      </Form.Select>
                    </Col>
               
                    <Col md="3">
                      <label>Staff</label>
                      <Form.Select
                        className={`form-control`}
                        value={staffId}
                        onChange={(e) => {
                          setStaffId(e.target.value);
                        }}
                        autoComplete="false"
                        required
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        {staffData?.map((staff, index) => (
                          <option key={index} value={staff?._id}>
                            {staff?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md="3">
                      <label>Discount Type</label>
                      <Form.Select
                        className={`form-control`}
                        onChange={(e) => {
                          setDiscountType(e.target.value);
                          setDiscount("");
                        }}
                        autoComplete="false"
                        required
                        value={discountType}
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        <option value="cash">Cash</option>
                        <option value="percentage">Percentage</option>
                      </Form.Select>
                    </Col>
{  discountType ?
                    <Col md="3">
                    <label>Discount</label>
                      <InputGroup id="amountPaid" placeholder="Discount">
                        <InputGroup.Text>
                        {discountType == "cash" ?
                          <>{currentUser?.currency ? currentUser?.currency : "£"}</> : "%" }
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          min={0}
                          max={ discountType == "percentage" && 100}
                          value={discount.toLocaleString("en-UK", {
                            minimumFractionDigits: 0,
                          })}
                          onChange={(e) => {
                            setDiscount(Number(e.target.value.replaceAll(",", "")));
                          }}
                        />
                      </InputGroup>

                                  </Col>  : ""}
            </Row>
            </div>
            </div>
            <Row>
              <Col md="5">
                <div className="form-group">
                  <label>Upload Images</label>
                  <input
                    type="file"
                    id="images"
                    className="form-control"
                    multiple
                    accept="image/*"
                    onChange={handleImageChange}
                    min="1"
                    max="10"
                    required
                  />
                </div>
              </Col>
            </Row>

            {orderImages?.length > 0 && renderImages()}
            <Row>
              <Col md="12 pb-5">
                <div className="form-group  mb-3">
                  <label>Notes</label>
                  <Form.Group className="mb-3" onChange={(e) => { setDescription(e.target.value) }} controlId="exampleForm.ControlTextarea1">
                    <Form.Control defaultValue={description} o as="textarea" rows={3} />
                  </Form.Group>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </>
  );
};
export default AddOrderr;
