import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../_components/imagepath";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { alphaNumericPattern, emailrgx } from "../assets/constant";
import tamaxa from "../assets/img/tamaxa-removebg-preview.svg";
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, OAUTH2CLIENTID } from "../assets/constant";
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';

const schema = yup.object({
  email: yup
    .string()
    .matches(emailrgx, "Email is required")
    .required("Email is required")
    .trim(),
  password: yup.string().min(6).max(10).required("Password is required").trim(),
});

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [eye, seteye] = useState(true);
  const [emailerror, setEmailError] = useState("");
  const [nameerror, setNameError] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [formgroup, setFormGroup] = useState("");
  const [inputValues, setInputValues] = useState({
    email: "admin@dreamguys.co.in",
    password: "123456",
  });


  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: OAUTH2CLIENTID,
        scope: "profile"
      })
    };
    gapi.load('client:auth2', start)
  })


  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { setCurrentUser, CurrentUser } = useContext(AuthContext);


  const onSubmit = async (data) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/signin`, {
      email: data?.email,
      password: data?.password,
    })
      .then(response => {
        setCurrentUser(response?.data?.user);
        navigate('/');
      })
      .catch(error => {
        toast.error(error.response?.data.message);
      });
  };

  const onEyeClick = () => {
    seteye(!eye);
  };

  const responseGoogle = (response) => {
    setLoading(true);
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/signin_google`, {
      authId: response.tokenId
    })
      .then(resData => {
        console.log(resData, 'response');
        setLoading(false);
        // toast.success(resData.data.message);
        setCurrentUser(resData.data.user);
        navigate('/');
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
      });
  }

  return (
    <>

      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            {/* <img
          className="img-fluid logo-dark mb-2"
          src={Logo}
          alt="Logo"
        /> */}
            <img src={tamaxa} alt="Logo" className="img-fluid logo-dark" />
            <div className="row" style={{justifyContent:"center"}}>
              <div className="col-lg-4 col-sm-6 col-md-10">
                <div className="card d-flex justify-content-center">
                  <div className="login-right p-3">
                    <div className="login-right-wrap ">
                      <h1 className="text-center">Login</h1>
                      {/* <p className="account-subtitle">Access to our dashboard</p> */}
                      <div className="">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="form-group">
                            <label className="form-control-label">
                              Email Address
                            </label>
                            <Controller
                              name="email"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control  ${errors?.email ? "error-input" : ""
                                    }`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                />
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.email?.message}</small>
                          </div>
                          <div className="form-group input_text">
                            <label className="form-control-label">Password</label>
                            <Controller
                              name="password"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <div className="pass-group">
                                  <input
                                    type={eye ? "password" : "text"}
                                    className={`form-control  ${errors?.password ? "error-input" : ""
                                      }`}
                                    value={value}
                                    onChange={onChange}
                                    autoComplete="false"
                                  />
                                  <span
                                    onClick={onEyeClick}
                                    className={`fa toggle-password" ${eye ? "fa-eye-slash" : "fa-eye"
                                      }`}
                                  />
                                </div>
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.password?.message}</small>
                          </div>
                          {/* <div className="form-group">
                        <div className="row">
                          <div className="col-6">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="cb1"
                              />
                              <label
                                className="custom-control-label ms-1"
                                htmlFor="cb1"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                          <div className="col-6 text-end">
                            <Link className="forgot-link" to="/forgot-password">
                              {" "}
                              Forgot Password ?{" "}
                            </Link>
                          </div>
                        </div>
                      </div> */}
                          <button
                            className="btn btn-lg btn-block w-100 btn-primary w-100"
                            type="submit"
                          >
                            Login
                          </button>
                        </form>
                        <div className="login-or">
                          <span className="or-line" />
                          {/* <span className="span-or">or</span> */}
                        </div>
                        {/* Social Login */}
                        {/* <div className="social-login mb-3">
                  <span>Login with</span>
                  <a href="#" className="facebook">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="#" className="google">
                    <i className="fab fa-google" />
                  </a>
                </div> */}
                        <div className="text-center dont-have">
                          <div id="signinButton">
                            <GoogleLogin
                              clientId={OAUTH2CLIENTID}
                              buttonText="Continue with Google"
                              onSuccess={responseGoogle}
                              onFailure={responseGoogle}
                              cookiePolicy={'single_host_origin'}
                              isSignedIn={false}
                              disabled={loading}
                            />
                          </div>
                          <div className="text-center mt-3 dont-have">
                            Don't have an account yet?{" "}
                            <Link to="/register">Register</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
