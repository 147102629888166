import React, { useEffect, useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Googlemap from "../component/Googlemap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BASE_URL, alphaNumericPattern, emailrgx } from "../assets/constant";
import tamaxa from "../assets/img/tamaxa-removebg-preview.svg";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import { Autocomplete } from "@react-google-maps/api";

const AddTailor = (props) => {
  const navigate = useNavigate();
  const autocompleteRef = useRef(null);
  const [menu, setMenu] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const { currentUser } = useContext(AuthContext);

  const [date, setDate] = useState(new Date());
  const [currencyOptions, setcurrencyOptions] = useState([
    { id: 1, text: "Select Currency" },
    { id: 2, text: "EUR Euro" },
    { id: 3, text: "INR Indoan Rupee" },
    { id: 4, text: "USD- US Dollar" },
  ]);
  const [countryOptions, setcountryOptions] = useState([
    { id: 1, text: "Select Country" },
    { id: 2, text: "Afghanistan" },
    { id: 3, text: "Albania" },
    { id: 4, text: "American Samoa" },
    { id: 5, text: "Algeria" },
    { id: 6, text: "Andorra" },
    { id: 7, text: "Angola" },
    { id: 8, text: "Anguilla" },
    { id: 9, text: "United States" },
  ]);

  const handlePlaceSelect = (place) => {
    setSelectedLocation(place);
  };

  const onSubmit = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/users`,
        {
          name: data.name,
          email: data.email,
          phone_number: data.phoneNumber,
          role: "tailor",
          tailorLocation: selectedLocation
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          toast.success(response.data.message);
          clearErrors("password");
          navigate('/tailors');
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };
  const onEyeClick = () => {
    seteye(!eye);
  };
  const [eye, seteye] = useState(true);

  const schema = yup
    .object({
      name: yup
        .string()
        .matches(alphaNumericPattern, "please enther valid name")
        .required("Please enter your name"),
      email: yup
        .string()
        .matches(emailrgx, "Email is required")
        .required("Email is required")
        .trim(),
    })
    .required();

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Add Tailor</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {/* <h4 className="card-title">Basic Info</h4> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={5}>
                          <div className="form-group input_text">
                            <label className="form-control-label">Name</label>
                            <Controller
                              name="name"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control ${
                                    errors?.name ? "error-input" : ""
                                  }`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                  placeholder="e.g. John Wick"
                                  required
                                />
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.name?.message}</small>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5}>
                          <div className="form-group input_text">
                            <label className="form-control-label">
                              Email Address
                            </label>
                            <Controller
                              name="email"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control  ${
                                    errors?.email ? "error-input" : ""
                                  }`}
                                  type="email"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                  placeholder="something@email.com"
                                  required
                                />
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.email?.message}</small>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5}>
                          <div className="form-group input_text">
                            <label className="form-control-label">
                              Phone Number
                            </label>
                            <Controller
                              name="phoneNumber"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control  ${
                                    errors?.phoneNumber ? "error-input" : ""
                                  }`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                  placeholder=""
                                />
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.phoneNumber?.message}</small>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5}>
                          <div className="form-group input_text">
                            <label className="form-control-label">Location</label>
                            <Autocomplete
                              onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                              onPlaceChanged={() =>
                                handlePlaceSelect(autocompleteRef.current.getPlace())
                              }
                            >
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Search location..."
                              />
                            </Autocomplete>
                          </div>
                        </Col>
                      </Row>
                      <button
                        className="btn btn-md btn-primary float-end "
                        type="submit"
                      >
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddTailor;
