import React, { useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { img2 } from "../_components/imagepath";
import FeatherIcon from "feather-icons-react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { AuthContext } from "../authentication/AuthContext";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { Autocomplete } from "@react-google-maps/api";
import Form from "react-bootstrap/Form";
import Footer from "../layouts/Footer";

const ProfileSettings = () => {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [menu, setMenu] = useState(false);
  const autocompleteRef = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedImages, setSelectedImages] = useState('');

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handlePlaceSelect = (place) => {
    setSelectedLocation(place);
  };

  const handleImageChange = (event) => {
    const files = event.target.files[0];
    setSelectedImages(files);
  };
  const { handleSubmit, register } = useForm();

  const onSubmit = (data) => {
    const { name, phoneNumber,currency } = data;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phoneNumber", phoneNumber);
    formData.append("location",JSON.stringify(selectedLocation ? selectedLocation : currentUser?.address));
    formData.append("currency", currency);
    formData.append("profileImage",selectedImages);
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/api/users/${currentUser.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          const { name, phoneNumber, currency} = response.data.body;
          const profileImageSignedUrl=response.data.body.user.profileImageSignedUrl;
          const address=response.data.body.user.address;
          setCurrentUser((prevUser) => ({
            ...prevUser,
            name,
            phoneNumber,
            currency,
            address,
            profileImageSignedUrl
          }));
         toast.success(response.data.message);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={toggleMobileMenu} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="page-title">Settings</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Profile Settings</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Basic information</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row form-group">
                      <label className="col-sm-3 col-form-label input-label">
                        Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          defaultValue={currentUser.name}
                          {...register("name", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label className="col-sm-3 col-form-label input-label">
                        Email
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          value={currentUser.email}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label className="col-sm-3 col-form-label input-label">
                        Phone
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          defaultValue={currentUser.phoneNumber}
                          {...register("phoneNumber")}
                        />
                      </div>
                    </div>
                    {currentUser.role.name === "tailor" && (
                      <>
                        <div className="row form-group">
                          <label className="col-sm-3 col-form-label input-label">
                            Location
                          </label>
                          <div className="col-sm-9">
                            <Autocomplete
                              onLoad={(autocomplete) =>
                                (autocompleteRef.current = autocomplete)
                              }
                              onPlaceChanged={() =>
                                handlePlaceSelect(
                                  autocompleteRef.current.getPlace()
                                )
                              }
                            >
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Search location..."
                                defaultValue={
                                  currentUser?.address?.formatted_address
                                }
                              />
                            </Autocomplete>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label input-label">
                              Currency
                            </label>
                            <div className="col-sm-9">
                              <Form.Select
                                className="form-control"
                                defaultValue={currentUser?.currency}
                                id="currency"
                                autoComplete="false"
                                required
                                {...register("currency", { required: true })}
                              >
                                <option value="">Select an option</option>
                                <option value="₦">₦</option>
                                <option value="£">£</option>
                                <option value="$">$</option>
                              </Form.Select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label input-label">
                             logo
                            </label>
                            <div className="col-sm-9">
                            <input
                    type="file"
                    id="images"
                    className="form-control"
                    accept="image/*"
                    onChange={handleImageChange}
                    min="1"
                    max="1"
                  />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {/* <div className="row form-group">
                      <label className="col-sm-3 col-form-label input-label">New Password</label>
                      <div className="col-sm-9">
                        <input
                          type="password"
                          className="form-control"
                          id="newPassword"
                          placeholder="New Password"
                          {...register("newPassword")}
                        />
                      </div>
                    </div> */}
                    <div className="text-end">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default ProfileSettings;
