import React, { useEffect, useState, useContext } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../assets/constant";
import { ColorRing } from "react-loader-spinner";

const EditClothSize = (props) => {
  const navigate = useNavigate();
  const { id,customerId } = useParams();
  const [menu, setMenu] = useState(false);
  const [measurementFields, setMeasurementFields] = useState([]);
  const [clothTypeTitle, setClothTypeTitle] = useState("");
  const [customer, setCustomer] = useState("");
  const [isloading, setIsloading] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const { currentUser } = useContext(AuthContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm({});

  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/measurements?clothType=${id}&customerId=${customerId}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          // const filteredMeasurements = response.data.body.filter(item => item.clothSize && item.clothSize._id !== null);
          setMeasurementFields(response.data.body);
          setClothTypeTitle(response.data.body[0].clothType.title);
          setCustomer(response.data.body[0].clothSize.customer);
          setIsloading(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsloading(false);
        }
      );
  }, []);

  const onSubmit = (data) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/api/measurements`, {
          measurementFields,
          customer:customerId
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          toast.success(response.data.message);
          navigate(`/profile/${customerId}`);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  return (
    <>
          {isloading == true ? (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ColorRing
            visible={true}
            height="auto"
            width="20%"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={[
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
            ]}
          />
        </div>
      ) : (
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <div className="row">
                <div className="col-sm-12">
                <Row className="mb-5">
                  <Col>  <span style={{fontSize:"30px",fontWeight:"500"}}>Edit Measurement</span> </Col>
                  <Col>
                  <button
                        className="btn btn-primary float-end"
                        type="submit"
                      >
                        Save
                      </button>
                      </Col>
                      </Row>
                 </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                   
                      <Row>
                        <Col md={4}>
                          <div className="form-group input_text">
                            <label className="form-control-label">Clothing</label>
                            <input
                              type="text"
                              id="disabledTextInput"
                              className="form-control"
                              value={clothTypeTitle}
                              disabled
                            />

                            <small>{errors?.email?.message}</small>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        {measurementFields.length === 0 ? (
                          <Col>
                            <p>No Measurement fields added yet.</p>
                          </Col>
                        ) : (
                          measurementFields.map((field, index) => (
                            <Col lg="4" key={index}>
                              <div className="form-group input_text">
                                <label className="form-control-label">{field.title}</label>
                                <Controller
                                  name={`measurementFields[${index}]`}
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <input
                                      className={`form-control ${
                                        errors?.measurementFields?.[index] ? "error-input" : ""
                                      }`}
                                      type="number"
                                      value={measurementFields[index]?.clothSize.size || ""}
                                      onChange={(e) => {
                                        onChange(e);
                                        const updatedMeasurementFields = [...measurementFields];
                                        updatedMeasurementFields[index] = {
                                          ...updatedMeasurementFields[index],
                                          clothSize: {
                                            ...updatedMeasurementFields[index]?.clothSize,
                                            size: e.target.value,
                                          },
                                        };
                                        setMeasurementFields(updatedMeasurementFields);
                                      }}
                                      
                                      autoComplete="off"
                                    />
                                  )}
                                  defaultValue=""
                                />
                                <small>{errors?.measurementFields?.[index]?.message}</small>
                              </div>
                            </Col>
                          ))
                        )}
                      </Row>

                    
                  </div>
                </div>
              </div>
            </div>
            </form>
          </div>   
        </div>
      </div>
     )}
    </>
  );
};
export default EditClothSize;
