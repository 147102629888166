import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Dashboard from "./dashboard/Index";
import AddCustomer from "./customerss/AddCustomer";
import EditCustomer from "./tailor/EditTailor";
import Expenses from "./termsandcondition/Index";
import AddExpenses from "./termsandcondition/AddExpenses";
import EditExpenses from "./termsandcondition/EditExpenses";
import Estimates from "./customerss/Index";
import AddEstimate from "./customerss/CustomerDetails";
import EditEstimate from "./customerss/EditEstimate";
import ViewEstimate from "./customerss/ViewEstimate";
import Invoices from "./orders/Index";
import ViewInvoice from "./orders/ViewInvoice";
import AddInvoice from "./orders/AddInvoice";
import EditInvoice from "./orders/EditInvoice";
import Payments from "./transactions/Index";
import AddPayments from "./transactions/TransactionDetails";
import ProfileSettings from "./settings/Profile";
import Preferences from "./settings/Preferences";
import TaxTypes from "./settings/TaxTypes";
import ExpenseCategory from "./settings/ExpenseCategory";
import Notifications from "./settings/Notifications";
import ChangePassword from "./settings/ChangePassword";
import DeleteAccount from "./settings/DeleteAccount";
import Chat from "./privacy/Chat";
import Calendar from "./privacy/Calendar";
import Inbox from "./privacy/Inbox";
import Profile from "./profile/Index";
import Login from "./authentication/Login";
import Logout from "./authentication/Logout";
import Register from "./authentication/Register";
import Activate from "./authentication/Activate";
import ForgotPassword from "./authentication/ForgotPassword";
import LockScreen from "./authentication/LockScreen";
import Page404 from "./errorpages/404";
import Page500 from "./errorpages/500";
import BlankPage from "./blankpage/Index";
import Users from "./users/Index";
import Components from "./components/Index";
import BasicInputs from "./forms/BasicInputs";
import FormInputGroups from "./forms/FormInputGroups";
import HorizontalForm from "./forms/HorizontalForm";
import VerticalForm from "./forms/VerticalForm";
import FormMask from "./forms/FormMask";
import FormValidation from "./forms/FormValidation";
import VectorMaps from "./vectormaps/Index";
import BasicTables from "./tables/BasicTables";
import DataTables from "./tables/DataTables";
import SalesReport from "./reports/salesreport";
import ExpenseReport from "./reports/expense";
import ProfitlossReport from "./reports/profitloss";
import Taxreport from "./reports/taxreport";
import AddTailor from "./tailor/AddTailor";
import EditTailor from "./tailor/EditTailor";
import CustomerDetails from "./customerss/CustomerDetails";
import Tailor from "./tailor/Index";
import Customerss from "./customerss/Index";
import Orders from "./orders/Index";
import Transactions from "./transactions/Index";
import Termsandcondition from "./termsandcondition/Index";
import Privacy from "./privacy/Chat";
import TransactionDetails from "./transactions/TransactionDetails";
import CustomerAdd from "./component/CustomerAdd";
import CustomerEdit from "./component/CustomerEdit";
import OrderSize from "./component/ClothSize";
import ClothSize from "./component/ClothSize";
import AddClothSize from "./component/AddClothSize";
import AddMeasurements from "./component/AddMeasurements";
import ClothSizeDetails from "./component/ClothSizeDetails";
import EditClothSize from "./component/EditClothSize";
import AddOrderr from "./component/AddOrderr";
import EditOrder from "./component/EditOrder";
import OrderDetail from "./component/OrderDetail";
import ClothTypeNew from "./component/ClothTypeNew";
import ClothTypesIndex from "./component/ClothTypesIndex";
import Marketplace from "./component/Marketplace";
import Staff from "./staff/index";
import AddStaff from "./staff/AddStaff";
import ClothTypeEdit from "./component/ClothTypeEdit";
import Privacy_Policy from "./component/Privacy";
import TAC from "./component/TAC";
import { NonProtectedRoutes } from "./authentication/NonProtectedRoutes";
import { UserProtectedRoutes } from "./authentication/UserProtectedRoutes";
import AssignTempMeasurement from "./component/AssignTempMeasurement";
import AssignMeasurement from "./measurements/Assign";

const AppContainer = (props) => {
  return (
    <>
      <NonProtectedRoutes>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/activate/:id" element={<Activate />} />
          <Route path="/privacy_policy" element={<Privacy_Policy />} />
          <Route path="/tac" element={<TAC />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </NonProtectedRoutes>
      <UserProtectedRoutes>
        <Routes>
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/tailors-new" element={<AddTailor />} />
          <Route path="/tailors" element={<Tailor />} />
          <Route path="/customers-new" element={<AddCustomer />} />
          <Route path="/edit-customer" element={<EditCustomer />} />
          <Route path="/customers" element={<Customerss />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/edit-tailor" element={<EditTailor />} />
          <Route path="/customer-details" element={<CustomerDetails />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/terms-condition" element={<Termsandcondition />} />
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/add-expenses" element={<AddExpenses />} />
          <Route path="/edit-expenses" element={<EditExpenses />} />
          <Route path="/estimates" element={<Estimates />} />
          <Route path="/add-estimate" element={<AddEstimate />} />
          <Route path="/edit-estimate" element={<EditEstimate />} />
          <Route path="/view-estimate" element={<ViewEstimate />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/view-invoice" element={<ViewInvoice />} />
          <Route path="/add-invoice" element={<AddInvoice />} />
          <Route path="/edit-invoice" element={<EditInvoice />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/add-payments" element={<AddPayments />} />
          <Route path="/transaction-details" element={<TransactionDetails />} />
          <Route path="/settings" element={<ProfileSettings />} />
          <Route path="/sales-report" element={<SalesReport />} />
          <Route path="/expenses-report" element={<ExpenseReport />} />
          <Route path="/profit-loss-report" element={<ProfitlossReport />} />
          <Route path="/taxs-report" element={<Taxreport />} />
          <Route path="/preferences" element={<Preferences />} />
          <Route path="/tax-types" element={<TaxTypes />} />
          <Route path="/expense-category" element={<ExpenseCategory />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/profile" exact element={<Profile />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/error-404" element={<Page404 />} />
          <Route path="/error-500" element={<Page500 />} />
          <Route path="/blank-page" element={<BlankPage />} />
          <Route path="/users" element={<Users />} />
          <Route path="/components" element={<Components />} />
          <Route path="/form-basic-inputs" element={<BasicInputs />} />
          <Route path="/form-input-groups" element={<FormInputGroups />} />
          <Route path="/form-horizontal" element={<HorizontalForm />} />
          <Route path="/form-vertical" element={<VerticalForm />} />
          <Route path="/form-mask" element={<FormMask />} />
          <Route path="/form-validation" element={<FormValidation />} />
          <Route path="/maps-vector" element={<VectorMaps />} />
          <Route path="/tables-basic" element={<BasicTables />} />
          <Route path="/data-tables" element={<DataTables />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/lock-screen" element={<LockScreen />} />
          <Route path="/customer/add" element={<CustomerAdd />} />
          <Route path="/customer/details" element={<CustomerDetails />} />
          <Route path="/customer/edit" element={<CustomerEdit />} />
          <Route path="/measurements" element={<ClothSize />} />
          <Route path="/clothing-measurements-new/:id" element={<AddClothSize />} />
          <Route path="/customer-measurements-new/:id" element={<AddMeasurements />} />
          <Route path="/cloth/size/details" element={<ClothSizeDetails />} />
          <Route path="/measurements-edit/:id/:customerId" element={<EditClothSize />} />
          <Route path="/orders-new" exact element={<AddOrderr />} />
          <Route path="/orders-new/:id" element={<AddOrderr />} />
          <Route path="/orders-edit/:id" element={<EditOrder />} />
          <Route path="/order-detail/:id" element={<OrderDetail />} />
          <Route path="/clothing-new" element={<ClothTypeNew />} />
          <Route path="/clothing" element={<ClothTypesIndex />} />
          <Route path="/clothing-edit/:id" element={<ClothTypeEdit />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/staff-new" element={<AddStaff />} />
          <Route path="/measurement-assign/:id" element={<AssignTempMeasurement />} />
          <Route path="/measurement-assign" element={<AssignMeasurement />} />          
        </Routes>
      </UserProtectedRoutes>
    </>
  );
};

export default AppContainer;
