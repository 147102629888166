import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import DatePicker from "react-datepicker";
import DataTable ,{ createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { img4, img12, img6, img8, img10 } from "../_components/imagepath";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import axios from 'axios';
import { AuthContext } from "../authentication/AuthContext";
import { toast } from 'react-toastify';
import { ColorRing } from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faCartPlus } from '@fortawesome/free-solid-svg-icons';
import {OverlayTrigger, Tooltip } from 'react-bootstrap';
import Footer from "../layouts/Footer";
import moment from 'moment';

createTheme('solarized', {
  action: {
    hover: '#7638ff',
  },
});

const Orders = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [menu, setMenu] = useState(false);
  const [delele, setdelete] = useState(false);
  const [myData, setMyData] = useState([]);
  const [isError, setIsError] = useState("");

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleRowClick = (row) => {
    if (currentUser.role.name === 'tailor') {
      navigate(`/order-detail/${row._id}`);
    }
  };

  const titlize = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const [date, setDate] = useState(new Date());
  const [options, setOptions] = useState([
    { id: 1, text: "Select Status" },
    { id: 2, text: "Draft" },
    { id: 3, text: "Sent" },
    { id: 4, text: "Viewed" },
    { id: 5, text: "Expired" },
    { id: 6, text: "Accepted" },
    { id: 7, text: "Rejected" },
  ]);

  const numberWithCommas = (number) => {
    return number?.toLocaleString('en-GB');
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/Orders`, {
        headers: {
          Authorization:
            `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          // setMyData(response.data.body);
          setMyData(response.data.body.length > 0 ? response.data.body : 0);
          setdelete(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsError(error.message);
        }
      );
  }, [delele]);

  const handleChange = (date) => {
    setDate(date);
  };

  const handleDelete = (data) => {
    const newStatus = data?.isdelete == true ? false : true;
  axios
  .delete(
    `${process.env.REACT_APP_BASE_URL}/api/orders/${data._id}?newStatus=${newStatus}`, {
      headers: {
        Authorization: `Bearer ${currentUser.accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    }
  )
  .then(
    (response) => {
      toast.success(response.data.message);
      setdelete(true);
      navigate('/orders')
    },
    (error) => {
      toast.error(error.response.data.message);
    }
  );
  };
  const columns = [
    {
      name: "No",
      selector: (row) => row.indexNo,
      sortable: true,
      width: "70px",
      export:false,
    },
    {
      name: "Customer",
      sortable: true,
      // selector: (row) => row?.customer?.name,
      width: "120px",
      fixed: true,
      cell: (row) => (
        <div style={{whiteSpace:"break-spaces"}}>
          <a
            href={`/profile/${row?.customer?._id}`}
          >
            {row?.customer?.name.substring(0, 20)}
          </a>
        </div>
      )
    },
    {
      name: "Created Date",
      selector: (row) => row?.createdAt,
      cell: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
      sortable: true,
      width: "150px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      cell: (row) => row.deliveryDate,
      sortable: true,
      width: "150px",
    },
    {
      name: "Payment",
      // selector: (row) => row.totalPayment,
      width: "150px",
      cell: (row) => (
        <div style={{whiteSpace:"break-spaces"}}>
        <span
          className={` badge badge-pill badge ${row.transactionStatus === "paid"
            ? "bg-success-light"
            : row.transactionStatus === "partial paid"
              ? "bg-warning-light"
              : row.transactionStatus == "Sent"
                ? "bg-info-light"
                : "bg-danger-light"
            }`}
        >
          {titlize(row.transactionStatus)}
        </span>
        </div>
      ),
    },
    {
      name: "Order Status",
      // selector: (row) => row.orderStatus,
      width: "200px",
      cell: (row) => (
        <span
          className={`badge p-2 badge-pill badge ${row.orderStatus === "complete"
            ? "bg-success-light"
            : row.orderStatus === "incomplete"
              ? "bg-warning-light"
              : "bg-danger-light"
            }`}
        >
          {titlize(row.orderStatus)}
        </span>
      ),
    },
    {
      name: "Collection Method",
      width: "200px",
      cell: (row) => (
        <span
          className={`badge p-2 badge-pill badge ${row?.collectionMethod === "Inprogress"
            ? "bg-warning-light"
            : row?.collectionMethod === "In-person"
              ? "bg-success-light"
              :  row?.collectionMethod === "courierDelivery" ? "bg-primary-light" :"bg-warning-light"
            }`}
        >
          {row?.collectionMethod === "courierDelivery" ? "Courier Delivery" : titlize(row?.collectionMethod ? row?.collectionMethod : "Inprogress")}
        </span>
      ),
    },
    {
      name: "Total",
      width: "150px",
      cell: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>{currentUser?.currency ? currentUser?.currency : "£"}{numberWithCommas(row?.totalPayment)} </div>
      ),
    },
    {
      name: "Paid",
      width: "150px",
      cell: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>{currentUser?.currency ? currentUser?.currency : "£"}{numberWithCommas(row?.paidAmount ? row?.paidAmount :0)} </div>
      ),
    },
    {
      name: "Remaining",
      width: "150px",
      cell: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>{currentUser?.currency ? currentUser?.currency : "£"}{(numberWithCommas(row?.totalPayment - row?.paidAmount))} </div>
      ),
    },
    {
      name: "Void",
      width: "150px",
      export:false,
      cell: (row) => (
       <> {
          row?.isdelete == false ? 
          <OverlayTrigger placement="top" overlay={VoidTooltip}>
          <button
            className="btn btn-danger btn-sm"
            style={{ color:"white"}}
            onClick={() => handleDelete(row)}
          >
              {row?.isdelete === true ? "Voided" : <FontAwesomeIcon icon={faTrash} />}
          </button> 
          </OverlayTrigger>
          : 
        ""
        }
      </>
      ),
    },
  ];

  const ExpandedComponent = ({ data }) => <><div className="ml-3"><div className="form-group p-3">
    <div className="row">
      <div className="col">
        <label htmlFor="">Total</label>
        <span className="form-control">{currentUser?.currency ? currentUser?.currency : "£"}{numberWithCommas(data?.totalPayment)} </span>
      </div>
      <div className="col">
        <label htmlFor="">Paid</label>
        <span className="form-control">{currentUser?.currency ? currentUser?.currency : "£"}{numberWithCommas(data?.paidAmount)}</span>
        
      </div>
      <div className="col">
        <label htmlFor="">Remaining</label>
        <span className="form-control">{currentUser?.currency ? currentUser?.currency : "£"}{(numberWithCommas(data.totalPayment - data.paidAmount))}</span>
      </div>
      <div className="col">
    <label htmlFor=""></label>
    <div className="col">
      {data?.isdelete == false ? 
      <button
        className="btn btn-danger btn-sm"
        style={{ color:"white"}}
        onClick={() => handleDelete(data)}
      >
          {data?.isdelete === true ? "Voided" : <FontAwesomeIcon icon={faTrash} />}
      </button> 
      : 
    //   <button
    //   className="btn btn-success btn-sm"
    //   style={{ color:"white"}}
    //   onClick={() => handleDelete(data)}
    // >
    //     {data?.isdelete === true ? "Voided" : <FontAwesomeIcon icon={faTrash} />}
    // </button>  
    ""
    }
    </div>
  </div>
    </div>
  </div>

    {/* <div className="col-sm-12"> */}

    {/* <div className="card card-table"> */}
    {/* <div className="card-body"> */}
    {/* <table className="table-responsive bordered w-100"> */}
    {/* <thead> */}
    {/* <tr> */}
    {/* <th width="25%"></th> */}
    {/* <th width="25%">Total</th> */}
    {/* <th width="25%">Paid</th> */}
    {/* <th width="25%">Remaining</th> */}
    {/* <th width="20%">Delivery</th> */}
    {/* <th width="20%">Status</th>
                <th width="20%">Payment</th> */}
    {/* <th width="20%">Service</th> */}
    {/* </tr> */}
    {/* </thead> */}
    {/* <tbody> */}
    {/* <tr> */}
    {/* <td width="25%"></td> */}
    {/* <td width="25%"> <span className="border p-2 w-75"> {data?.totalPayment} </span></td> */}
    {/* <td width="25%"> <span className="border p-2 w-75"> {data?.paidAmount} </span></td> */}
    {/* <td width="25%"> <span className="border p-2"> {(data.totalPayment - data.paidAmount)} </span></td> */}
    {/* <td width="20%"> <span className="border p-2 w-75"> {data?.deliveryDate} </span></td> */}
    {/* <td width="20%" >  <span className="btn btn-sm btn-primary">{data?.orderStatus}</span></td>
        <td width="20%" > <span className="btn btn-sm btn-primary"> {data?.transactionStatus} </span></td> */}
    {/* <td width="20%">{data?.service?.title ? data?.service?.title : 'No Service'}</td> */}
    {/* </tr> */}
    {/* </tbody> */}
    {/* </table> */}
    {/* </div> */}
    {/* </div> */}
    {/* </div> */}
  </div>
  </>
  // {JSON.stringify({
  //   Remaining: (data.totalPayment - data.paidAmount), Delivery: data?.deliveryDate, Status: data?.orderStatus, Payment: data?.transactionStatus
  //   , Service: data?.service?.title ? data?.service?.title : 'No Service'
  // }, null, 2)}


  const tableData = {
    columns,
    data: myData != 0 ? myData : [],
    "export": true,
    exportHeaders:true,
    print: false,
    fileName: "orders", 
  };

  const conditionalRowStyles = [
    {
      when: row => row?.isdelete === true,
      style: {
        backgroundColor: "rgba(255, 0, 0, 0.1)",
        userSelect: "none"
      }
    }
  ];

  const tooltip = (
    <Tooltip id="tooltip">
      Add Order
    </Tooltip>
  );

  const VoidTooltip = (
    <Tooltip id="tooltip">
     Void
    </Tooltip>
  );

  return (
    <>
      {
        myData.length === 0 ? <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <ColorRing
            visible={true}
            height="auto"
            width="20%"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#7638ff', '#7638ff', '#7638ff', '#7638ff', '#7638ff', '#7638ff']}
          />
        </div> :
          <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
            <Header onMenuClick={(value) => toggleMobileMenu()} />
            <Sidebar />
            <div className="page-wrapper">
              <div className="container-fluid">
                <div className="mb-1">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mt-5">Orders</h3>
                    </div>
                    <div className="col-auto">
                      {currentUser.role.name === 'tailor' && (
                        <Link to="/orders-new" className="btn me-1">
                           <OverlayTrigger placement="top" overlay={tooltip}>
                          <Button className="btn btn-primary mt-5">
                          <FontAwesomeIcon icon={faCartPlus} />
                          </Button>
                          </OverlayTrigger>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div id="filter_inputs" className="card filter-card">
                  <div className="card-body pb-0">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Customer:</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Status:</label>
                          <Select2
                            className="w-100"
                            data={options}
                            options={{
                              placeholder: "Select Status",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>From</label>
                          <div className="cal-icon">
                            <DatePicker
                              selected={date}
                              onChange={handleChange}
                              className="form-control datetimepicker"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>To</label>
                          <div className="cal-icon">
                            <DatePicker
                              selected={date}
                              onChange={handleChange}
                              className="form-control datetimepicker"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Invoice Number</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* <div className="col-sm-12"> */}
                    <div className="card card-table">
                      <div className="card-body">
                        {/* <div className="table-responsive"> */}
                          <div style={{ overflowX: 'auto' }}>
                            <DataTableExtensions {...tableData}>
                              <DataTable
                                defaultSortField="id"
                                className="data-table-extensions-action p-3"
                                defaultSortAsc={false}
                                pagination
                                // responsive={true}
                                // expandableRows
                                // expandableRowsComponent={ExpandedComponent}
                                onRowClicked={handleRowClick}
                                highlightOnHover
                                theme="solarized"
                                conditionalRowStyles={conditionalRowStyles}
                              />
                            </DataTableExtensions>
                          </div>
                        </div>
                      {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
                <Footer></Footer>
              </div>
            </div>
                      </div>
      }
    </>
  );
};
export default Orders;
