import React, { useEffect, useRef, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Autocomplete } from "@react-google-maps/api";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { toast } from 'react-toastify';
import { BASE_URL } from "../assets/constant";
import { ColorRing } from "react-loader-spinner";



const SlideToggle = window.ReactSlideToggle;

const Tailor = () => {
  const { currentUser } = useContext(AuthContext);
  const [menu, setMenu] = useState(false);
  const [singleRow, setSingleRew] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (row) => { setShow(true); setSingleRew(row) };

  const [myData, setMyData] = useState([]);
  const [isError, setIsError] = useState("");
  const autocompleteRef = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handlePlaceSelect = (place) => {
    setSelectedLocation(place);
  };

  useEffect(() => {
    axios.get(`${BASE_URL}/api/users`, {
      params: { user_role: "Tailor", },
      headers: { Authorization: `Bearer ${currentUser.accessToken}`, },
    })
      .then(response => {
        // setMyData(response.data.body);
        setMyData(response.data.body.length > 0 ? response.data.body : [{ "data": "nothing found" }]);

      })
      .catch(error => {
        toast.error(error.response.data.message);
        setIsError(error.response.data.message);
      });
  }, []);

  const columns = [
    {
      name: "No.",
      selector: (row) => row.indexNo,
      sortable: true,
      width: "7%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "15%",
      cell: (row) => (
        <div>
          <a
            href={`/profile/${row._id}`}
          >
            {row.name}
          </a>
        </div>
      )
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "20%",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
      sortable: true,
      width: "15%",
    },
    {
      name: "Activation Status",
      selector: (row) => row?.activationStatus?.toLowerCase() === 'inactive' ? 'Signup Pending' : row?.activationStatus?.charAt(0)?.toUpperCase() + row?.activationStatus?.slice(1),
      sortable: true,
      width: "20%",
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <i onClick={() => handleShow(row)} className="fa fa-edit"></i>
        </div>
      ),
      sortable: false,
      width: "10%",
    },
  ];

  const tableData = {
    columns,
    data: myData !== 0 ? myData : [],
    "export": false,
    print: false
  };
  const handleSubmit = (event) => {
    event.preventDefault()
    console.log(event.key);
    if(selectedLocation!==null){
      singleRow.location =selectedLocation; 
      singleRow.address =selectedLocation; 
    }
    axios.patch(`${BASE_URL}/api/users/${singleRow._id}`,singleRow,{
      headers: { Authorization: `Bearer ${currentUser.accessToken}` }
    })
      .then(response => {
        // setMyData(response.data.body);
        toast.success(response.data.message);
        handleClose();
        setSelectedLocation(null);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        setIsError(error.response.data.message);
      });
    // console.log(event.target.elements.username.value)
    // console.log(event.target.username.value)
    // console.log(this.inputNode.value)
  }

  return (
    <>
      {/* delete old modal akbar's work */}
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you really want to delete this?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Tailor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
                <input className="form-control" defaultValue={singleRow?._id} type="hidden" name="id" id="id" />
              <div className="col-12">
                <label htmlFor="">Name</label>
                <input className="form-control" defaultValue={singleRow?.name} onChange={(e)=>{singleRow.name =e.target.value }} type="text" name="name" id="name" />
              </div>
              <div className="col-12">
                <label htmlFor="">Email</label>
                <input className="form-control" defaultValue={singleRow?.email}  disabled type="text" name="email" id="email" />
              </div>
              <div className="col-12">
                <label htmlFor="">Phone Number</label>
                <input className="form-control" onChange={(e)=>{singleRow.phoneNumber =e.target.value }} defaultValue={singleRow?.phoneNumber !== null && singleRow?.phoneNumber !== "" ? singleRow?.phoneNumber : ""} type="text" name="phoneNumber" id="phoneNumber" />
              </div>
              <div className="col-12">
                <label htmlFor="">Location</label>
                <div className="">
                <Autocomplete
                  onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                  onPlaceChanged={() =>
                    handlePlaceSelect(autocompleteRef.current.getPlace())
                  }
                >
                  <input
                    className="form-control"
                    type="text"
                    name="location"
                    placeholder="Search location..."
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    defaultValue={singleRow?.address?.formatted_address}
                  />
                </Autocomplete>

                </div>
                {/* <input className="form-control" type="text" name="" id="" /> */}
              </div>
            </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="success">
            Save
          </Button>
        </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      {myData.length === 0 ?
        <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <ColorRing
            visible={true}
            height="auto"
            width="20%"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#7638ff', '#7638ff', '#7638ff', '#7638ff', '#7638ff', '#7638ff']}
          />
        </div>
        :
        <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <Sidebar />
          <div className="page-wrapper">
            <div className="content container-fluid">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="page-title">Tailors</h3>
                  </div>
                  <div className="col-auto">
                    {/* <Link to="/tailors-new" className="btn  me-1">
                    <Button className="btn btn-primary">
                      Add Tailor
                    </Button>{" "}
                  </Link> */}
                    {/* <a className="btn btn-primary filter-btn" href="/customers#" id="filter_search">
									<i className="fas fa-filter"></i>
								</a> */}
                  </div>
                </div>
              </div>
              <div id="filter_inputs" className="card filter-card">
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>Name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>Phone</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table">
                    <div className="card-body">
                      <DataTableExtensions {...tableData}>
                        <DataTable
                          noHeader
                          defaultSortField="id"
                          defaultSortAsc={false}
                          pagination
                          highlightOnHover
                        />
                      </DataTableExtensions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  );
};
export default Tailor;
