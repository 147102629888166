import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select2 from 'react-select2-wrapper';
import Header from '../layouts/Header'
import Sidebar from '../layouts/Sidebar'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const TransactionDetails = () => {
  const [menu, setMenu] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [date, setDate] = useState(new Date());
  const [currencyOptions, setcurrencyOptions] = useState([
    { id: 1, text: "Select Currency" },
    { id: 2, text: "EUR Euro" },
    { id: 3, text: "INR Indoan Rupee" },
    { id: 4, text: "USD- US Dollar" },
  ]);
  const [countryOptions, setcountryOptions] = useState([
    { id: 1, text: "Select Country" },
    { id: 2, text: "Afghanistan" },
    { id: 3, text: "Albania" },
    { id: 4, text: "American Samoa" },
    { id: 5, text: "Algeria" },
    { id: 6, text: "Andorra" },
    { id: 7, text: "Angola" },
    { id: 8, text: "Anguilla" },
    { id: 9, text: "United States" },
  ]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title ">Transaction Details</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12"></div>
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {/* <h4 className="card-title">Basic Info</h4> */}
                    <form action="#">
                      <div className="row">
                        <div className="col-md-6">
                          <h5 className="fw-bold">Order Summary</h5>
                          <br />

                          <Row className="mx-3 ">
                            <Col lg="6">
                              <h6>Customer: David Smith</h6>
                            </Col>
                            <Col lg="6">
                              <h6>Tailor: The Elegant Stitch</h6>
                            </Col>
                          </Row>

                          <Row className="mx-3 mt-3">
                            <Col lg="6">
                              <h6>Order Id: 0456 </h6>
                            </Col>
                            <Col lg="6">
                              <h6>Delivery Date: 12 May 2023</h6>
                            </Col>
                          </Row>

                          <Row className="mx-3 mt-3">
                            <Col lg="6">
                              <h6>Clothing: Slim Fit Dress Shirt</h6>
                            </Col>
                            <Col lg="6">
                              <h6>Transaction Status: Completed</h6>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12"></div>
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {/* <h4 className="card-title">Basic Info</h4> */}
                    <form action="#">
                      <div className="row">
                        <div className="col-md-6">
                          <h5 className="fw-bold">Size Details</h5>
                          <br />

                          <Row className="mx-3 ">
                            <Col lg="6">
                              <p className="fs-6">Neck Size: 15 inches</p>
                            </Col>
                            <Col lg="6">
                              <p className="fs-6">Collar Spread: 3.5 inches</p>
                            </Col>
                          </Row>

                          <Row className="mx-3 ">
                            <Col lg="6">
                              <p className="fs-6">Shoulder Width: 18 inches</p>
                            </Col>
                            <Col lg="6">
                              <p className="fs-6">Cuff Size: 9 inches</p>
                            </Col>
                          </Row>

                          <Row className="mx-3 ">
                            <Col lg="6">
                              <p className="fs-6">Chest Size: 40 inches</p>
                            </Col>
                            <Col lg="6">
                              <p className="fs-6">Bicep Size: 14 inches</p>
                            </Col>
                          </Row>

                          <Row className="mx-3 ">
                            <Col lg="6">
                              <p className="fs-6">Waist Size: 36 inches</p>
                            </Col>
                            <Col lg="6">
                              <p className="fs-6">Armhole Size: 18 inches</p>
                            </Col>
                          </Row>

                          <Row className="mx-3 ">
                            <Col lg="6">
                              <p className="fs-6">Sleeve Length: 34 inches</p>
                            </Col>
                            <Col lg="6">
                              <p className="fs-6">Back Pleats: No Pleats</p>
                            </Col>
                          </Row>

                          <Row className="mx-3 ">
                            <Col lg="6">
                              <p className="fs-6">Shirt Length: 28 inches</p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <Row>
              <Col>
                {" "}
                <h3>Total Amount</h3>
              </Col>
              <Col>
                {" "}
                <h3>$250</h3>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default TransactionDetails;