import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { toast } from "react-toastify";
import { Card, Col, Modal, Row, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../authentication/AuthContext";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Autocomplete } from "@react-google-maps/api";
import { ColorRing } from "react-loader-spinner";
import Footer from "../layouts/Footer";

const Staff = () => {
  const [menu, setMenu] = useState(false);
  const [myData, setMyData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [singleRowData, setSingleRowData] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  const autocompleteRef = useRef(null);
  const [dataAppend, setDataAppend] = useState(false);

  const handleClose = () => {setShowModal(false);};
  const handleOpen = () => setShowModal(true);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleShowEdit = (row) => {
    setShowModal(true);
    setSingleRowData(row);
    setSelectedLocation(row?.address);
  };

  const handlePlaceSelect = (place) => {
    setSelectedLocation(place);
  };  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.key === 'Enter') e.preventDefault();
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/api/users/${singleRowData?._id}`,
        { ...singleRowData, location: JSON.stringify(selectedLocation) },
        {
          headers: { Authorization: `Bearer ${currentUser.accessToken}` },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setSingleRowData(response.data.body.user);
        handleClose();
        setDataAppend(true);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/users`, {
          params: {
            user_role: "tailor_staff",
          },
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        })
        .then(
          (response) => {
            setMyData(response.data.body.length > 0 ? response.data.body : 0);
            setDataAppend(false);
          },
          (error) => {
            toast.error(error.response.data.message);
          }
        );
    } catch (error) {
      console.log(error);
    }
  }, [dataAppend]);

  const columns = [
    {
      name: <div>Name</div>,
      selector: (row) => row.name,
      sortable: true,
      width: "26%",
      cell: (row) => <div style={{ whiteSpace: "break-spaces" }}>{row.name.substring(0, 20)}</div>,
    },
    {
      name: <div>Contact</div>,
      selector: (row) => <div className="float-right" style={{ whiteSpace: "break-spaces" }}>{row.phoneNumber}</div>,
      sortable: true,
      width: "34%",
    },
    {
      name: <div>Edit</div>,
      cell: (row) => (
        <div>
          <i onClick={() => handleShowEdit(row)} className="fa fa-edit"></i>
        </div>
      ),
      sortable: false,
      width: "22%",
    },
  ];

  const ExpandedComponent = ({ data }) => (
    <>
      <div className="ml-3">
        <div className="form-group p-3">
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="">Email</label>
              <span className="form-control">{data?.email}</span>
            </div>
            <div className="col-lg-4">
              <label htmlFor="">Contact</label>
              <span className="form-control">{data?.phoneNumber}</span>
            </div>
            <div className="col-lg-4">
              <label htmlFor="">Gender</label>
              <span className="form-control">{data?.gender}</span>
            </div>
            <div className="col-lg-6 mt-2">
              <label htmlFor="">Address</label>
              <span className="form-control">
                {/* {data?.location?.formatted_address
                  ? data?.location?.formatted_address
                  : data?.address
                  ?.formatted_address} */}
                  { data?.address?.formatted_address}
              </span>
            </div>
            <div className="col-lg-6 mt-2">
              <label htmlFor="">Other Address</label>
              <span className="form-control">{data?.otherAddress}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const tableData = {
    columns,
    data: myData != 0 ? myData : [],
    export: false,
    print: false,
  };

  return (
    <>
     {
        myData.length === 0 ? <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <ColorRing
            visible={true}
            height="auto"
            width="20%"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#7638ff', '#7638ff', '#7638ff', '#7638ff', '#7638ff', '#7638ff']}
          />
        </div>:
        <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <input className="form-control" type="hidden" name="id" id="id" />
              <div className="col-12">
                <label htmlFor="">Name</label>
                <input
                  className="form-control"
                  type="text" 
                  name="name"
                  defaultValue={singleRowData?.name}
                  onChange={(e) => {
                    singleRowData.name = e.target.value;
                  }}
                  placeholder="e.g. John Snow"
                  id="name"
                />
              </div>
              <div className="col-12">
                <label htmlFor="">Email</label>
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  defaultValue={singleRowData?.email}
                  onChange={(e) => {
                    singleRowData.email = e.target.value;
                  }}
                  placeholder="something@email.com"
                  id="email"
                />
              </div>
              <div className="col-12">
                <label htmlFor="">Contact</label>
                <input
                  className="form-control"
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  defaultValue={singleRowData?.phoneNumber}
                  onChange={(e) => {
                    singleRowData.phoneNumber = e.target.value;
                  }}
                  placeholder="Contact Number"
                />
              </div>
              <div className="col-12">
                <label htmlFor="gender">Gender</label>
                <Form.Select
                  className="form-control"
                  autoComplete="false"
                  required
                  id="gender"
                  name="gender"
                  onChange={(e) => {
                    singleRowData.gender = e.target.value;
                  }}
                  defaultValue={singleRowData?.gender}
                >
                  <option value="">Select an option</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Select>
              </div>
              <div className="col-12">
                <label className="form-control-label">Address</label>
                <div className="col-sm-12">
                  <Autocomplete
                    onLoad={(autocomplete) =>
                      (autocompleteRef.current = autocomplete)
                    }
                    onPlaceChanged={() =>
                      handlePlaceSelect(autocompleteRef.current.getPlace())
                    }
                  >
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search location..."
                      // defaultValue={singleRowData?.location?.formatted_address
                      //   ? singleRowData?.location?.formatted_address
                      //   : singleRowData?.address?.formatted_address}
                      defaultValue={singleRowData?.address?.formatted_address}
                    />
                  </Autocomplete>
                </div>
              </div>
              <div className="col-12">
                <label className="form-control-label">Other Address</label>
                <div className="col-sm-12">
                  <input
                    type="text"
                    id="disabledTextInput"
                    placeholder="Other Address"
                    className="form-control"
                    onChange={(e) => {
                      singleRowData.otherAddress = e.target.value;
                    }}
                    defaultValue={singleRowData?.otherAddress}
                  />
                </div>
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" variant="success">
                Save
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="mb-1">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="mt-5">Staff</h3>
                </div>
                <div className="col-auto">
                  {currentUser.role.name === "tailor" && (
                    <Link to="/staff-new" className="btn me-1">
                      <Button className="btn btn-primary mt-5">
                        <FontAwesomeIcon icon={faUserPlus} />
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table">
                  <div className="card-body">
                    <div className="table-responsive break-whitespace">
                      <DataTableExtensions {...tableData}>
                        <DataTable
                          noHeader
                          defaultSortField="id"
                          defaultSortAsc={false}
                          pagination
                          fixedHeader={true}
                          responsive={true}
                          expandableRows
                          expandableRowsComponent={ExpandedComponent}
                          highlightOnHover
                          theme="solarized"
                        />
                      </DataTableExtensions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
      </>
}
    </>
  );
};
export default Staff;
