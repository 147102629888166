import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../_components/imagepath";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { alphaNumericPattern, emailrgx } from "../assets/constant";
import tamaxa from "../assets/img/tamaxa-removebg-preview.svg";
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../assets/constant";

const schema = yup.object({
  email: yup
    .string()
    .matches(emailrgx, "Email is required")
    .trim(),
  password: yup.string().min(6).max(10).required("Password is required").trim(),
});

const Activate = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [eye, seteye] = useState(true);
  const [userEmail, setUserEmail] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/users/${id}/activate`, {
      })
      .then(
        (response) => {
          setUserEmail(response.data.body);
        },
        (error) => {
          toast.error(error.response.data.message, { toastId: 'error1', });
        }
      );
  }, []);

  const onSubmit = async (data) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/${id}/activate_user`, {
      password: data.password,
    })
      .then(response => {
        toast.success(response.data.message);
        navigate('/login');
      })
      .catch(error => {
        toast.error(error.response.data.message);
      });
  };

  const onEyeClick = () => {
    seteye(!eye);
  };

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <img src={tamaxa} alt="Logo" className="header-img-login" />
            <div className="loginbox">
              <div className="login-right">
                <div className="login-right-wrap">
                  <h1>Activate</h1>
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group input_text">
                        <label className="form-control-label">
                          Email Address
                        </label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control  ${
                                errors?.email ? "error-input" : ""
                              }`}
                              type="text"
                              value={userEmail}
                              onChange={onChange}
                              autoComplete="false"
                              disabled={true}
                            />
                          )}
                          defaultValue=""
                        />

                        <small>{errors?.email?.message}</small>
                      </div>
                      <div className="form-group input_text">
                        <label className="form-control-label">Password</label>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <div className="pass-group">
                              <input
                                type={eye ? "password" : "text"}
                                className={`form-control  ${
                                  errors?.password ? "error-input" : ""
                                }`}
                                value={value}
                                onChange={onChange}
                                autoComplete="false"
                              />
                              <span
                                onClick={onEyeClick}
                                className={`fa toggle-password" ${
                                  eye ? "fa-eye-slash" : "fa-eye"
                                }`}
                              />
                            </div>
                          )}
                          defaultValue=""
                        />

                        <small>{errors?.password?.message}</small>
                      </div>
                      <button
                        className="btn btn-lg btn-block w-100 btn-primary w-100"
                        type="submit"
                      >
                        Activate
                      </button>
                    </form>
                    <div className="login-or">
                      <span className="or-line" />
                    </div>
                    <div className="text-center dont-have">
                      Don't have an account yet?{" "}
                      <Link to="/register">Register</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Activate;
