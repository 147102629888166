import { Link } from "react-router-dom";
// import { tamaxa, LogoSmallImg } from "../_components/imagepath";
import tamaxa from "../assets/img/tamaxa-removebg-preview.svg";
import { useContext } from "react";
import { AuthContext } from "../authentication/AuthContext";

const Header = (props) => {
  const { currentUser } = useContext(AuthContext);

  const handleSidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const onMenuClick = () => {
    props.onMenuClick();
  };

  console.log("currentUser.....",currentUser);

  return (
    <div className="header">
      <div className="header-one">
        <Link to="#" id="toggle_btn" onClick={handleSidebar}>
          <span className="toggle-bars">
            <span className="bar-icons" />
            <span className="bar-icons" />
            <span className="bar-icons" />
            <span className="bar-icons" />
          </span>
        </Link>
        <Link className="mobile_btn" id="mobile_btn">
          <i className="fas fa-bars" />
        </Link>
        <Link className="mobile_btn" id="mobile_btn" onClick={onMenuClick}>
          <i className="fas fa-bars" />
        </Link>
      </div>
      <div className="header-left">
        <Link to="/" >
        {currentUser?.profileImageSignedUrl ?
          <img src={currentUser?.profileImageSignedUrl} style={{ maxWidth: "100%", maxHeight: "65px" }} alt="Logo" />
        :  
        <img src={tamaxa} alt="Logo" style={{ maxWidth: "100%", maxHeight: "65px" }} />    
        // className="header-img"
        }
        </Link>
      </div>
    </div>
  );
};

export default Header;
