import { useParams } from "react-router-dom";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { AuthContext } from "../authentication/AuthContext";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { BASE_URL } from "../assets/constant";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Card, Form } from "react-bootstrap";
import { Autocomplete } from "@react-google-maps/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPen,
  faCartPlus,
  faRuler,
  faTrash,
  faTrashAlt,
  faArrowUpFromBracket,
  faDownload,
  faFileAlt,
  faUpload,
  faPlus,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ColorRing } from "react-loader-spinner";
import Footer from "../layouts/Footer";
import moment from "moment";

const Profile = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [profileUser, setProfileUser] = useState([]);
  const [userMeasurements, setUserMeasurements] = useState([]);
  const [delele, setdelete] = useState(false);
  const [activeTab, setActiveTab] = useState("measurements");
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [singleRow, setSingleRew] = useState();
  const handleClose = () => setShow(false);
  const handleShowClose = () => setShowMore(false);

  const [productList, setProductList] = useState([]);
  const [productId, setProductId] = useState("");
  const [tempMeasurementData, setTempMeasurementData] = useState({});
  const [title, setTitle] = useState("");
  const [value, setValue] = useState(0);
  const [dynamicMeasurement, setDynamicMeasurement] = useState([]);

  const [activeTabMeasurements, setActiveTabMeasurements] = useState("list");
  const [showMeasurements, setShowMeasurements] = useState(false);
 
  const handleCloseMeasurements = () => {
    setShowMeasurements(false);
    setTempMeasurementData({});
    setProductId("");
    setTitle("");
    setValue("");
  };


  const handleShow = () => {
    setShow(true);
  };
  const handleMeasurementShow = () => {
    setShowMeasurements(true);
  };

  const profileUserId = id;
  const [myData, setMyData] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showUploadMeasurement, setShowUploadMeasurement] = useState(false);
  const [otherAddress, setOtherAddress] = useState("");
  const [typeFile, setTypeFile] = useState("");
  const [refreshList, setRefreshList] = useState(false);
  const [uploadMeasurementFile, setUploadMeasurementFile] = useState("");
  const [isloading, setIsloading] = useState(false);
  const autocompleteRef = useRef(null);
  const [myDataMeasurement, setMyDataMeasurement] = useState([]);

  const handleCloseUploadMeasurement = () => {
    setShowUploadMeasurement(false);
    setTypeFile("");
    setUploadMeasurementFile("");
  };
  const handleShowUploadMeasurement = () => {
    setShowUploadMeasurement(true);
  };
  const handleMeasurementFileChange = (event) => {
    const files = event.target.files[0];
    setUploadMeasurementFile(files);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const handleTabChangemeasurements = (tab) => {
    setActiveTabMeasurements(tab);
  };

  const initializeMap = (lat, lng) => {
    const mapOptions = { center: { lat, lng }, zoom: 15 };
    const map = new window.google.maps.Map(
      document.getElementById("tailorMap"),
      mapOptions
    );

    new window.google.maps.Marker({
      position: { lat, lng },
      map: map,
      title: "Tailor Location",
    });
  };
 
  

  useEffect(() => {
    if (
      profileUser?.location &&
      profileUser?.location?.geometry?.location?.lat &&
      profileUser?.location?.geometry?.location?.lng
    ) {
      initializeMap(
        profileUser?.location.geometry.location.lat,
        profileUser.location.geometry.location.lng
      );
    }
  }, [profileUser?.location]);

  const handleRowClick = (row) => {
    if (currentUser.role.name === "tailor") {
      navigate(`/orders-edit/${row._id}`);
    }
  };

  const handlePlaceSelect = (place) => {
    setSelectedLocation(place);
  };

  const titlize = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    if (!profileUserId) {
      if (currentUser.role.name === "admin") {
        toast.error("Admin can't visit here.", { toastId: "error1" });
        navigate("/");
      }
      setProfileUser(currentUser);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/users/${profileUserId}/show`,
          {
            headers: {
              Authorization: `Bearer ${currentUser.accessToken}`,
            },
          }
        )
        .then(
          (response) => {
            setProfileUser(response.data.body);
            setSelectedLocation(response?.data?.body?.location);
          },
          (error) => {
            toast.error(error.response.data.message, { toastId: "error1" });
          }
        );
    }
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_sizes`, {
        params: {
          customer: profileUserId,
        },
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          // setUserMeasurements(
          //   response?.data?.body?.filter((item) => item.size !== null)
          // );
            setUserMeasurements(response?.data?.body);
        },
        (error) => {
          toast.error(error.response.data.message, { toastId: "error1" });
        }
      );
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/customer_orders/${id}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          // setMyData(response.data.body);
          setMyData(response.data.body.length > 0 ? response.data.body : 0);
          setdelete(false);
        },
        (error) => {
          toast.error(error.response.data.message, { toastId: "error1" });
        }
      );
  }, [profileUserId, currentUser.accessToken, delele, refreshList]);

  const fetchClothTypes = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_types`, {
        params: {
          active: "true",
        },
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setProductList(response.data.body);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };
  useEffect(() => {
    fetchClothTypes();
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/tempMeasurements?customerId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          
          setMyDataMeasurement(
            response.data.body.length > 0 ? response.data.body : 0
          );
        },
        (error) => {
          toast.error(error.response.data.message, { toastId: "error1" });
        }
      );
  }, [id]);

  const handleGettempMeasurement = (id) => {
    setIsloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/tempMeasurements?customerId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          setMyDataMeasurement(
            response.data.body.length > 0 ? response.data.body : 0
          );
          setIsloading(false);
        },
        (error) => {
          toast.error(error.response.data.message, { toastId: "error1" });
        }
      );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.key === "Enter") event.preventDefault();
    axios
      .patch(
        `${BASE_URL}/api/users/${profileUserId}`,
        { ...profileUser, location: JSON.stringify(selectedLocation) },
        {
          headers: { Authorization: `Bearer ${currentUser.accessToken}` },
        }
      )
      .then((response) => {
        // setMyData(response.data.body);
        toast.success(response.data.message);
        setProfileUser(response.data.body.user);
        setSelectedLocation(response?.data?.body?.user?.address);
        handleClose();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
    // console.log(event.target.elements.username.value)
    // console.log(event.target.username.value)
    // console.log(this.inputNode.value)
  };

  const handleSubmitMeasurement = (value,dynamicMeasurement,assignMeasurement) => {
    setIsloading(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/tempMeasurements`,
        {
          customer: id,
          value: value,
          dynamicMeasurement:dynamicMeasurement,
          assignMeasurement:assignMeasurement,
          // dynamicMeasurement:tempMeasurementData._id,
          // assignMeasurement:tempMeasurementData.assigenMeasurement
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          setShowMeasurements(false);
          toast.success(response.data.message);
          handleGettempMeasurement(id);
          setIsloading(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsloading(false);
        }
      );
  };



  const fetchUserMeasurement =() =>{
    setIsloading(true);
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_sizes`, {
      params: {
        customer: profileUserId,
      },
      headers: {
        Authorization: `Bearer ${currentUser.accessToken}`,
      },
    })
    .then(
      (response) => {
          setUserMeasurements(response?.data?.body);
          setIsloading(false);
      },
      (error) => {
        setIsloading(false);
        toast.error(error.response.data.message, { toastId: "error1" });
      }
    );
  }

  const getClothTypeId = (clothTypeTitle) => {
    const matchedMeasurement = userMeasurements.find(
      (value) => value.clothType.title === clothTypeTitle
    );
    if (matchedMeasurement) {
      return matchedMeasurement.clothType._id;
    }
    return "";
  };

  const handleDelete = (data) => {
    const newStatus = data?.isdelete == true ? false : true;
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/api/orders/${data._id}?newStatus=${newStatus}`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(
        (response) => {
          toast.success(response.data.message);
          setdelete(true);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };
  const numberWithCommas = (number) => {
    return number?.toLocaleString("en-GB");
  };

  const columns = [
    {
      name: "No",
      selector: (row) => row.indexNo,
      sortable: true,
      width: "70px",
    },
    {
      name: "Customer",
      sortable: true,
      selector: (row) => row.customer.name,
      width: "120px",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "160px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "160px",
    },
    {
      name: "Payment",
      selector: (row) => row.totalPayment,
      sortable: true,
      cell: (row) => (
        <Card className="user-dt">
          <span
            className={`badge badge-pill badge ${
              row.transactionStatus === "paid"
                ? "bg-success-light"
                : row.transactionStatus === "partial paid"
                ? "bg-warning-light"
                : row.transactionStatus == "Sent"
                ? "bg-info-light"
                : "bg-danger-light"
            }`}
          >
            {titlize(row.transactionStatus)}
          </span>
          <Card.Body></Card.Body>
        </Card>
      ),
      width: "150px",
    },
    {
      name: "Order Status",
      selector: (row) => row.orderStatus,
      sortable: true,
      cell: (row) => (
        <Card className="user-dt">
          <span
            className={`badge badge-pill badge ${
              row.orderStatus === "complete"
                ? "bg-success-light"
                : row.orderStatus === "incomplete"
                ? "bg-warning-light"
                : "bg-danger-light"
            }`}
          >
            {titlize(row.orderStatus)}
          </span>
          <Card.Body></Card.Body>
        </Card>
      ),
      width: "150px",
    },
    {
      name: <div>Collection Method</div>,
      selector: (row) => row?.collectionMethod,
      width: "200px",
      cell: (row) => (
        <Card className="user-dt">
          <span
            className={`badge badge-pill badge ${
              row?.collectionMethod === "Inprogress"
                ? "bg-warning-light"
                : row?.collectionMethod === "In-person"
                ? "bg-success-light"
                : row?.collectionMethod === "courierDelivery"
                ? "bg-primary-light"
                : "bg-warning-light"
            }`}
          >
            {row?.collectionMethod === "courierDelivery"
              ? "Courier Delivery"
              : titlize(
                  row?.collectionMethod ? row?.collectionMethod : "Inprogress"
                )}
          </span>
          <Card.Body></Card.Body>
        </Card>
      ),
    },
    {
      name: "Total",
      selector: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>
          {currentUser?.currency ? currentUser?.currency : "£"}
          {numberWithCommas(row.totalPayment)}{" "}
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: " Paid",
      selector: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>
          {currentUser?.currency ? currentUser?.currency : "£"}
          {numberWithCommas(row.paidAmount)}
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Remaining",
      selector: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>
          {currentUser?.currency ? currentUser?.currency : "£"}
          {numberWithCommas(row.totalPayment - row.paidAmount)}
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Void",
      sortable: true,
      cell: (row) => (
        <>
          {row?.isdelete == false ? (
            <OverlayTrigger placement="top" overlay={VoidTooltip}>
              <button
                className="btn btn-danger btn-sm"
                style={{ color: "white" }}
                onClick={() => handleDelete(row)}
              >
                {row?.isdelete === true ? (
                  <FontAwesomeIcon icon={faTrashAlt} />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </button>
            </OverlayTrigger>
          ) : (
            <button
              className="btn btn-success btn-sm"
              style={{ color: "white" }}
              onClick={() => handleDelete(row)}
            >
              {row?.isdelete === true ? (
                <FontAwesomeIcon icon={faTrashAlt} />
              ) : (
                <FontAwesomeIcon icon={faTrash} />
              )}
            </button>
          )}
        </>
      ),
      width: "150px",
    },
  ];

  const measurementColumns = [
    {
      name: "Title",
      cell: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>
          {row.title.substring(0, 20)}
        </div>
      ),
      width: "25%",
    },
    {
      name: "Measurement",
      cell: (row, index) => (
        <input
          type="number"
          key={"input-" + index}
          className="form-control"
          style={{ width: "35%" }}
          onChange={(e) => {
            const index = myDataMeasurement.findIndex((i) => i._id === row._id);
            let val = e.target.value;
            setValue(e.target.value);
            let arr = [...myDataMeasurement];
            let obj = { ...arr[index] };
            if (obj.temp_measurements) {
              obj.temp_measurements[0].value = val;
            } else {
              obj.temp_measurements = [{ value: val }];
            }
            arr[index] = obj;
            setMyDataMeasurement([...arr]);
          }}
          onBlur={async (e) => {
            e.preventDefault();

            handleSubmitMeasurement(
              row?.temp_measurements && row?.temp_measurements[0]?.value
                ? row?.temp_measurements[0]?.value
                : 0,
              row._id,
              row.assigenMeasurement
            );
          }}
          value={
            row?.temp_measurements && row?.temp_measurements[0]?.value
              ? row?.temp_measurements[0]?.value
              : ''
          }
        />
      ),
      width: "53%",
    },
    {
      name: <div>Assigned</div>,
      cell: (row) => (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {row?.assigenMeasurement?.map((item, index) => (
            <React.Fragment key={index}>
              <span className="badge badge-success mr-1 mb-1">
                {item?.clothType?.title}
              </span>
              {index !== row.assigenMeasurement.length - 1 && (
                <span style={{ marginRight: "5px" }} />
              )}
            </React.Fragment>
          ))}
        </div>
      ),
      width: "22%",
    },
    // {
    //   name: <div>Edit</div>,
    //   cell: (row) => (
    //     <>
    //       <div>
    //         <i
    //           onClick={() => handleDataTempMeasurement(row)}
    //           className="fa fa-edit"
    //         ></i>
    //       </div>
    //     </>
    //   ),
    //   width: "25%",
    // },
  ];

  const tableData = {
    columns,
    data: myData !== 0 ? myData : [],
    export: false,
    print: false,
  };

  const tableDataMeasurements = {
    columns: measurementColumns,
    data: myDataMeasurement !== 0 ? myDataMeasurement : [],
    export: false,
    print: false,
  };

  const conditionalRowStyles = [
    {
      when: (row) => row?.isdelete === true,
      style: {
        backgroundColor: "rgba(255, 0, 0, 0.1)",
        userSelect: "none",
      },
    },
  ];

  const tooltip = <Tooltip id="tooltip">Add Order</Tooltip>;
  const CustomerTooltip = <Tooltip id="tooltip">Edit Customer</Tooltip>;
  const MeasurementTooltip = <Tooltip id="tooltip">Add Measurements</Tooltip>;

  const VoidTooltip = <Tooltip id="tooltip">Void</Tooltip>;

  const UploadMeasurementTooltip = (
    <Tooltip id="tooltip">Upload Measurements</Tooltip>
  );
  const downloadMeasurementTooltip = (
    <Tooltip id="tooltip">Download Measurements</Tooltip>
  );
  const handleTypeChange = (e) => {
    setTypeFile(e.target.value);
  };

  const handleImportMeasurement = () => {
    if (typeFile && uploadMeasurementFile) {
      const formData = new FormData();
      formData.append("type", typeFile);
      formData.append("tailor", currentUser["id"]);
      formData.append("file", uploadMeasurementFile);
      formData.append("customer", id);
      setIsloading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/import/dynamicMeasurements`,
          formData,
          {
            headers: { Authorization: `Bearer ${currentUser.accessToken}` },
          }
        )
        .then((response) => {
          toast.success(response.data.message);
          handleCloseUploadMeasurement();
          setIsloading(false);
          setRefreshList(true);
        })
        .catch((error) => {
          toast.error("Please Upload Correct file template");
          setIsloading(false);
        });
    } else {
      toast.error("Fill the Complete Form");
    }
  };

  const handleExportMeasurement = () => {
    setIsloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/export/measurements?customer=${id}`,
        { responseType: "blob" },
        {
          headers: { Authorization: `Bearer ${currentUser.accessToken}` },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${profileUser?.name} Measurements.xlsx`);
        document.body.appendChild(link);
        link.click();
        setIsloading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsloading(false);
      });
  };

  const handleDownloadMeasurementTemplate = () => {
    setIsloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/dynamicMeasurementTemplate`,
        { responseType: "blob" },
        {
          headers: { Authorization: `Bearer ${currentUser.accessToken}` },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `MeasurementTemplate.xlsx`);
        document.body.appendChild(link);
        link.click();
        setIsloading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsloading(false);
      });
  };

  const downloadMeasurementtooltip = (
    <Tooltip id="tooltip">Measurements Template</Tooltip>
  );

  const handleDataTempMeasurement = (data) => {
    setTempMeasurementData(data);
    setValue(data.temp_measurements && data.temp_measurements.length > 0 ? data.temp_measurements[0].value : 0);
    setTitle(data.title);
    setShowMeasurements(true);
  };

  return (
    <>
      {isloading == true ? (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ColorRing
            visible={true}
            height="auto"
            width="20%"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={[
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
            ]}
          />
        </div>
      ) : (
        <>
          <Modal
            show={showUploadMeasurement}
            onHide={handleCloseUploadMeasurement}
          >
            <Modal.Header closeButton>
              <Modal.Title>Upload Measurements</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <label className="form-label">Type</label>
                <Row className="mb-3">
                  <Col lg={2}>
                    <Form.Check
                      inline
                      type="checkbox"
                      label="CSV"
                      value="csv"
                      checked={typeFile === "csv"}
                      onChange={handleTypeChange}
                    />
                  </Col>
                  <Col lg={6}>
                    <Form.Check
                      inline
                      type="checkbox"
                      label="Excel"
                      value="excel"
                      checked={typeFile === "excel"}
                      onChange={handleTypeChange}
                    />
                  </Col>
                </Row>
                <div className="form-group">
                  <label className="form-label">Upload File</label>
                  <input
                    type="file"
                    id="images"
                    className="form-control"
                    multiple
                    accept=".xlsx, .csv"
                    onChange={handleMeasurementFileChange}
                    min="1"
                    max="1"
                    required
                  />
                </div>
              </>
              <Row>
                <Col lg={7} md={5} sm={5} xs={5}>
                  <OverlayTrigger
                    placement="top"
                    overlay={downloadMeasurementtooltip}
                  >
                    <Button
                      className="btn btn-primary float-start"
                      onClick={() => handleDownloadMeasurementTemplate()}
                    >
                      {/* <FontAwesomeIcon icon={faFileAlt} /> */}
                      Download Template
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col lg={5} md={7} sm={7} xs={7}>
                  <Button
                    variant="secondary"
                    onClick={handleCloseUploadMeasurement}
                    className="float-right mx-1"
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    variant="success"
                    onClick={handleImportMeasurement}
                    className="float-right"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Customers </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <input
                    className="form-control"
                    defaultValue={profileUser?._id}
                    type="hidden"
                    name="id"
                    id="id"
                  />
                  <div className="col-12">
                    <label htmlFor="">Name</label>
                    <input
                      className="form-control"
                      defaultValue={profileUser?.name}
                      onChange={(e) => {
                        profileUser.name = e.target.value;
                      }}
                      type="text"
                      name="name"
                      id="name"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="">Email</label>
                    <input
                      className="form-control"
                      defaultValue={profileUser?.email}
                      onChange={(e) => {
                        profileUser.email = e.target.value;
                      }}
                      type="text"
                      name="email"
                      id="email"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="">Contact</label>
                    <input
                      className="form-control"
                      onChange={(e) => {
                        profileUser.phoneNumber = e.target.value;
                      }}
                      defaultValue={
                        profileUser?.phoneNumber != null &&
                        profileUser?.phoneNumber != ""
                          ? profileUser?.phoneNumber
                          : ""
                      }
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="gender">Gender</label>
                    <Form.Select
                      className="form-control"
                      defaultValue={
                        profileUser?.gender != null &&
                        profileUser?.gender !== ""
                          ? profileUser?.gender
                          : ""
                      }
                      onChange={(e) => {
                        profileUser.gender = e.target.value;
                      }}
                      autoComplete="false"
                      required
                      id="gender"
                      name="gender"
                    >
                      <option value="">Select an option</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Form.Select>
                  </div>
                  <div className="col-12">
                    <label className="form-control-label">Address</label>
                    <div className="col-sm-12">
                      <Autocomplete
                        onLoad={(autocomplete) =>
                          (autocompleteRef.current = autocomplete)
                        }
                        onPlaceChanged={() =>
                          handlePlaceSelect(autocompleteRef.current.getPlace())
                        }
                      >
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search location..."
                          defaultValue={profileUser?.address?.formatted_address}
                        />
                      </Autocomplete>
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="form-control-label">Other Address</label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="disabledTextInput"
                        placeholder="Other Address"
                        className="form-control"
                        defaultValue={profileUser?.otherAddress}
                        onChange={(e) => {
                          profileUser.otherAddress = e.target.value;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="tier">Tier</label>
                    <Form.Select
                      className="form-control"
                      defaultValue={
                        profileUser?.tier != null && profileUser?.tier !== ""
                          ? profileUser?.tier
                          : ""
                      }
                      onChange={(e) => {
                        profileUser.tier = e.target.value;
                      }}
                      autoComplete="false"
                      required
                      id="tier"
                      name="tier"
                    >
                      <option value="">Select an option</option>
                      <option value="silver">Silver</option>
                      <option value="gold">Gold</option>
                      <option value="platinum">Platinum</option>
                    </Form.Select>
                  </div>
                </div>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="success">
                    Save
                  </Button>
                </Modal.Footer>
              </form>
            </Modal.Body>
          </Modal>
          <Modal show={showMore} onHide={handleShowClose}>
            <Modal.Header closeButton>
              <Modal.Title>Customer Details </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <input
                  className="form-control"
                  disabled
                  defaultValue={profileUser?._id}
                  type="hidden"
                  name="id"
                  id="id"
                />
                <div className="col-12">
                  <label htmlFor="">Name</label>
                  <input
                    disabled
                    className="form-control"
                    defaultValue={profileUser?.name}
                    onChange={(e) => {
                      profileUser.name = e.target.value;
                    }}
                    type="text"
                    name="name"
                    id="name"
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="">Email</label>
                  <input
                    disabled
                    className="form-control"
                    defaultValue={profileUser?.email}
                    type="text"
                    name="email"
                    id="email"
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="">Contact</label>
                  <input
                    disabled
                    className="form-control"
                    onChange={(e) => {
                      profileUser.phoneNumber = e.target.value;
                    }}
                    defaultValue={
                      profileUser?.phoneNumber != null &&
                      profileUser?.phoneNumber != ""
                        ? profileUser?.phoneNumber
                        : ""
                    }
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="gender">Gender</label>
                  <select
                    className="form-control"
                    defaultValue={
                      profileUser?.gender != null && profileUser?.gender !== ""
                        ? profileUser?.gender
                        : ""
                    }
                    onChange={(e) => {
                      profileUser.gender = e.target.value;
                    }}
                    autoComplete="false"
                    required
                    id="gender"
                    name="gender"
                    disabled
                  >
                    <option value="">Select an option</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
                <div className="col-12">
                  <label className="form-control-label">Address</label>
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      placeholder="Search location..."
                      defaultValue={profileUser?.address?.formatted_address}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <label className="form-control-label">Other Address</label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      id="disabledTextInput"
                      placeholder="Other Address"
                      className="form-control"
                      disabled
                      defaultValue={profileUser?.otherAddress}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="tier">Tier</label>
                  <select
                    className="form-control"
                    defaultValue={
                      profileUser?.tier != null && profileUser?.tier !== ""
                        ? profileUser?.tier
                        : ""
                    }
                    onChange={(e) => {
                      profileUser.tier = e.target.value;
                    }}
                    autoComplete="false"
                    required
                    id="tier"
                    name="tier"
                    disabled
                  >
                    <option value="">Select an option</option>
                    <option value="silver">Silver</option>
                    <option value="gold">Gold</option>
                    <option value="platinum">Platinum</option>
                  </select>
                </div>
              </div>
              <Modal.Footer className="mt-2">
                <Button variant="secondary" onClick={handleShowClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>

          <Modal
            centered
            show={showMeasurements}
            onHide={handleCloseMeasurements}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Measurement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmitMeasurement}>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="">Title</label>
                    <input
                      className="form-control"
                      defaultValue={tempMeasurementData?.title}
                      disabled
                      type="text"
                      name="title"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      id="title"
                    />
                  </div>
                  <div className="col-12">
                    <label className="form-control-label mt-3">Value</label>
                    <input
                      className="form-control"
                      defaultValue={
                        tempMeasurementData?.temp_measurements &&
                        tempMeasurementData.temp_measurements.length > 0
                          ? tempMeasurementData.temp_measurements[0].value
                          : 0
                      }
                      type="text"
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      name="value"
                      id="value"
                    />
                  </div>
                </div>
                <Modal.Footer>
                  <Button type="submit" variant="success">
                    Submit
                  </Button>
                </Modal.Footer>
              </form>
            </Modal.Body>
          </Modal>

          <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
            <Header onMenuClick={(value) => toggleMobileMenu()} />
            <Sidebar />
            <div className="page-wrapper">
              <div className=" container-fluid">
                <div className="row justify-content-lg-center">
                  <div className="col-lg-10">
                    <div className="">
                      <div className="row">
                        <div className="col">
                          <h3 className="mt-5">Profile</h3>
                          <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to="/">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">Profile</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mb-5">
                      <div className="card">
                        <div className="card-body">
                          <h3>
                            {profileUser?.name &&
                              profileUser?.name?.charAt(0).toUpperCase() +
                                profileUser?.name?.slice(1)}
                          </h3>
                          <h4>{profileUser?.phoneNumber}</h4>
                          <h6 onClick={() => setShowMore(true)}>
                            {" "}
                            <a href="#">show more</a>
                          </h6>
                          {/* <h4>Email: {profileUser?.email}</h4>
                  <h4>Address: {profileUser?.location?.formatted_address}</h4>
                  <h4>Gender: {profileUser?.gender && profileUser?.gender?.charAt(0).toUpperCase() + profileUser?.gender?.slice(1)}</h4>
                  <h4>Tier: {profileUser?.tier && profileUser?.tier?.charAt(0).toUpperCase() + profileUser?.tier?.slice(1)}</h4> */}
                        </div>
                      </div>
                    </div>

                    <div className="page-header">
                      <div className="row align-items-center">
                        <div className="col">
                          <h3 className="page-title"></h3>
                        </div>
                        <Row>
                          <Col
                            className="float-end"
                            // md={{ span: 4, offset: 7 }}
                            // sm={{ span: 4, offset: 8 }}
                            // lg={{ span: 4, offset: 8 }}
                          >
                            {currentUser.role.name === "tailor" && (
                              <div className="row">
                                {/* <Col md={{ span: 1, offset: 2 }} sm={{ span: 4, offset: 8 }} xs={{ span: 4, offset: 8 }}>
                            </Col> */}
                                {/* <Col lg={2} md={2} sm={2} xs={2} className="">
                                  <Link to={`#`}>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={downloadMeasurementTooltip}
                                    >
                                      <Button
                                        className="btn btn-primary float-left"
                                        onClick={() =>
                                          handleExportMeasurement()
                                        }
                                      >
                                        <FontAwesomeIcon icon={faDownload} />
                                      </Button>
                                    </OverlayTrigger>
                                  </Link>
                                </Col> */}
                                {/* <Col md={2} sm={3} xs={2}>
                            <Link to={`#`}>
                            <OverlayTrigger placement="top" overlay={downloadMeasurementtooltip}>
                                <Button
                                  className="btn btn-primary"
                                 onClick={() => handleDownloadMeasurementTemplate()}
                                >
                                  <FontAwesomeIcon icon={faFileAlt} />
                                </Button>
                                </OverlayTrigger>
                                </Link>
                                </Col> */}
                                <Col
                                  lg={12}
                                  style={{
                                    float: "right",
                                  }}
                                >
                                   <Link
                                    to={`/customer-measurements-new/${profileUserId}`}
                                  >
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={MeasurementTooltip}
                                    >
                                      <Button
                                        style={{
                                          float: "right",
                                        }}
                                        className="btn btn-primary mx-1"
                                      >
                                        <FontAwesomeIcon icon={faRuler} />
                                      </Button>
                                    </OverlayTrigger>
                                  </Link>
                                  <Link to={`#`}>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={CustomerTooltip}
                                    >
                                      <Button
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() => handleShow()}
                                        className="btn btn-primary  mx-1 "
                                      >
                                        <FontAwesomeIcon icon={faUserPen} />
                                      </Button>
                                    </OverlayTrigger>
                                  </Link>
                                  <Link to={`/orders-new/${profileUserId}`}>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={tooltip}
                                    >
                                      <Button
                                        style={{
                                          float: "right",
                                        }}
                                        className="btn btn-primary mx-1"
                                      >
                                        <FontAwesomeIcon icon={faCartPlus} />
                                      </Button>
                                    </OverlayTrigger>
                                  </Link>
                                  <Link to={`#`}>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={UploadMeasurementTooltip}
                                    >
                                      <Button
                                        style={{
                                          float: "right",
                                        }}
                                        className="btn btn-primary mx-1"
                                        onClick={() =>
                                          handleShowUploadMeasurement()
                                        }
                                      >
                                        <FontAwesomeIcon icon={faUpload} />
                                      </Button>
                                    </OverlayTrigger>
                                  </Link>
                                  <Link to={`#`}>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={downloadMeasurementTooltip}
                                    >
                                      <Button
                                        className="btn btn-primary mx-1"
                                        style={{
                                          float: "right",
                                        }}
                                        onClick={() =>
                                          handleExportMeasurement()
                                        }
                                      >
                                        <FontAwesomeIcon icon={faDownload} />
                                      </Button>
                                    </OverlayTrigger>
                                  </Link>          
                                </Col>

                                {/* <Col
                                  lg={2}
                                  md={2}
                                  sm={2}
                                  xs={2}
                                  className="mx-1"
                                >
                                  <Link to={`#`}>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={UploadMeasurementTooltip}
                                    >
                                      <Button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          handleShowUploadMeasurement()
                                        }
                                      >
                                        <FontAwesomeIcon icon={faUpload} />
                                      </Button>
                                    </OverlayTrigger>
                                  </Link>
                                </Col> */}

                                {/* <Col
                                  lg={2}
                                  md={2}
                                  sm={2}
                                  xs={2}
                                  className="mx-1"
                                >
                                  <>
                                  <Link to={`/orders-new/${profileUserId}`}>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={tooltip}
                                    >
                                      <Button className="btn btn-primary">
                                        <FontAwesomeIcon icon={faCartPlus} />
                                      </Button>
                                    </OverlayTrigger>
                                  </Link>
                                  </>
                                </Col> */}
                                {/* <Col
                                  lg={2}
                                  md={2}
                                  sm={2}
                                  xs={2}
                                  className="mx-1"
                                >
                                  <Link to={`#`}>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={CustomerTooltip}
                                    >
                                      <Button
                                        onClick={() => handleShow()}
                                        className="btn btn-primary "
                                      >
                                        <FontAwesomeIcon icon={faUserPen} />
                                      </Button>
                                    </OverlayTrigger>
                                  </Link>
                                </Col> */}
                                {/* <Col
                                  lg={2}
                                  md={2}
                                  sm={2}
                                  xs={2}
                                  className="mx-1"
                                >
                                  <Link
                                    to={`/customer-measurements-new/${profileUserId}`}
                                  >
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={MeasurementTooltip}
                                    >
                                      <Button className="btn btn-primary">
                                        <FontAwesomeIcon icon={faRuler} />
                                      </Button>
                                    </OverlayTrigger>
                                  </Link>
                                </Col> */}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card flex-fill bg-white">
                          <div className="card-header">
                            <ul
                              role="tablist"
                              className="nav nav-tabs card-header-tabs flex-column flex-sm-row "
                            >
                              <li className="nav-item float-right">
                                <button
                                  className={`nav-link mx-2 mb-2 ${
                                    activeTab === "measurements"
                                      ? "active btn btn-primary"
                                      : "btn btn-inactive"
                                  } rounded`}
                                  onClick={() =>
                                    handleTabChange("measurements")
                                  }
                                >
                                  Measurements
                                </button>
                              </li>
                              <li className="nav-item float-right">
                                <button
                                  className={`nav-link ${
                                    activeTab === "orders"
                                      ? "active btn btn-primary"
                                      : "btn-inactive"
                                  } rounded`}
                                  onClick={() => handleTabChange("orders")}
                                >
                                  Orders
                                </button>
                              </li>
                              {profileUser.role?.name === "tailor" && (
                                <li className="nav-item">
                                  <button
                                    className={`nav-link ${
                                      activeTab === "location" ? "active" : ""
                                    }`}
                                    onClick={() => handleTabChange("location")}
                                  >
                                    Location
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className="card-body">
                            <div className="tab-content pt-0">
                              <div
                                role="tabpanel"
                                id="tab-1"
                                className={`tab-pane fade show ${
                                  activeTab === "measurements" ? "active" : ""
                                }`}
                              >
                                {/* <div className="float-right btn-group mb-4 " role="group" aria-label="Basic outlined example">
                                  <ul
                                    className="nav nav-tabs card-header-tabs"
                                    id="myTab"
                                    role="tablist"
                                   
                                  >
                                      <li className="nav-item float-right" >
                                      <button
                                        className={`nav-link ${
                                          activeTabMeasurements === "list"
                                            ? "active"
                                            : "btn text-dark"
                                        } rounded`}
                                        id="profile-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#profile"
                                        type="button"
                                        role="tab"
                                        aria-controls="profile"
                                        aria-selected="false"
                                        onClick={() =>
                                          handleTabChangemeasurements("list")
                                        }
                                        style={{
                                          borderColor: activeTabMeasurements === "list" ? "#621aff" : "#111111",
                                          color: activeTabMeasurements === "list" ?  "#621aff" : "",
                                          backgroundColor: activeTabMeasurements === "list" ? "white" : ""
                                        }}
                                      >
                                        {" "}
                                        List
                                      </button>
                                    </li>
                                    <li className="nav-item float-right" >
                                      <button
                                        className={`nav-link  ${
                                          activeTabMeasurements === "detail"
                                            ? "active"
                                            : "btn text-dark"
                                        } rounded`}
                                        id="home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#home"
                                        type="button"
                                        role="tab"
                                        aria-controls="home"
                                        aria-selected="true"
                                        onClick={() =>
                                          handleTabChangemeasurements("detail")
                                        }
                                        style={{
                                          borderColor: activeTabMeasurements === "detail" ? "#621aff" : "",
                                          color: activeTabMeasurements === "detail" ?  "#621aff" : "",
                                          backgroundColor: activeTabMeasurements === "detail" ? "white" : ""
                                        }}
                                      >
                                        Details
                                      </button>
                                    </li>
                                  
                                  </ul>
                                </div> */}
                                <div
                                  className="btn-group float-right mb-4"
                                  role="group"
                                  aria-label="Basic outlined example"
                                >
                                  <button
                                    type="button"
                                    className={`  ${
                                      activeTabMeasurements === "list"
                                        ? " btn btn-outline-primary active"
                                        : "btn text-dark"
                                    }`}
                                    onClick={() =>
                                      handleTabChangemeasurements("list")
                                    }
                                    style={{
                                      borderColor:
                                        activeTabMeasurements === "list"
                                          ? "#621aff"
                                          : "#111111",
                                      color:
                                        activeTabMeasurements === "list"
                                          ? "#621aff"
                                          : "",
                                      backgroundColor:
                                        activeTabMeasurements === "list"
                                          ? "#e2d5ff"
                                          : "",
                                    }}
                                  >
                                    List
                                  </button>
                                  <button
                                    type="button"
                                    className={`  ${
                                      activeTabMeasurements === "detail"
                                        ? " btn btn-outline-primary active"
                                        : "btn text-dark"
                                    }`}
                                    onClick={() => {
                                      handleTabChangemeasurements("detail");
                                      fetchUserMeasurement();
                                    }}
                                    style={{
                                      borderColor:
                                        activeTabMeasurements === "detail"
                                          ? "#621aff"
                                          : "#111111",
                                      color:
                                        activeTabMeasurements === "detail"
                                          ? "#621aff"
                                          : "",
                                      backgroundColor:
                                        activeTabMeasurements === "detail"
                                          ? "#e2d5ff"
                                          : "",
                                    }}
                                  >
                                    Details
                                  </button>
                                </div>
                                <div
                                  className="tab-content"
                                  style={{ paddingTop: "5rem" }}
                                >
                                  <div
                                    role="tabpanel"
                                    id="tab-1"
                                    className={`tab-pane fade show ${
                                      activeTabMeasurements === "detail"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div>
                                      {userMeasurements.length > 0 ? (
                                        <>
                                          {Array.from(
                                            new Set(
                                              userMeasurements.map(
                                                (value) => value.clothType.title
                                              )
                                            )
                                          ).map((clothTypeTitle) => (
                                            <React.Fragment
                                              key={clothTypeTitle}
                                            >
                                              <div className="d-flex justify-content-between text-center">
                                                <div className="my-3">
                                                  <h3>{clothTypeTitle}</h3>
                                                </div>
                                                {/* <div className="my-3">
                                                  {currentUser.role.name ===
                                                    "tailor" && (
                                                    <a
                                                      href={`/measurements-edit/${getClothTypeId(
                                                        clothTypeTitle
                                                      )}/${id}`}
                                                      className="btn btn-md me-2 btn-block btn-primary"
                                                    >
                                                      Edit
                                                    </a>
                                                  )}
                                                </div> */}
                                              </div>
                                              <Row>
                                                {userMeasurements
                                                  .filter(
                                                    (value) =>
                                                      value.clothType.title ===
                                                      clothTypeTitle
                                                  )
                                                  .map((value, index) => (
                                                    <Col lg={4} key={value._id}>
                                                      {value.measurement && (
                                                        <div className="form-group">
                                                          <label>
                                                            {
                                                              value.measurement
                                                                .title
                                                            }
                                                          </label>
                                                          <input
                                                            min="1"
                                                            max="100"
                                                            type="text"
                                                            id={`measurement-${value._id}`}
                                                            className="form-control"
                                                            value={
                                                              value.size || 0
                                                            }
                                                            disabled
                                                          />
                                                        </div>
                                                      )}
                                                    </Col>
                                                  ))}
                                              </Row>
                                              <hr />
                                            </React.Fragment>
                                          ))}
                                        </>
                                      ) : (
                                        <Link
                                          to={`/customer-measurements-new/${profileUserId}`}
                                        >
                                          <div
                                            className="text-center"
                                            style={{ color: "grey" }}
                                          >
                                            {/* No Measurements added yet. */}
                                            Upload your customers measurements
                                            or add
                                          </div>
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="tab-content pt-4">
                                  <div
                                    role="tabpanel"
                                    id="tab-2"
                                    className={`tab-pane fade show ${
                                      activeTabMeasurements === "list"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="row">
                                      <div className="col-sm-12">
                                        <Link to={`/measurement-assign/${id}`}>
                                          <Button className="float-end mb-3">
                                            Assign{" "}
                                          </Button>
                                        </Link>
                                      </div>
                                      <div className="col-sm-12">
                                        <div className="card card-table">
                                          <div className="card-body">
                                            <div className="table-responsive">
                                              <DataTableExtensions
                                                {...tableDataMeasurements}
                                              >
                                                <DataTable
                                                  noHeader
                                                  defaultSortField="id"
                                                  defaultSortAsc={false}
                                                  pagination
                                                  highlightOnHover
                                                />
                                              </DataTableExtensions>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                role="tabpanel"
                                id="tab-2"
                                className={`tab-pane fade show ${
                                  activeTab === "orders" ? "active" : ""
                                }`}
                              >
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="card card-table">
                                      <div className="card-body">
                                        <div className="table-responsive">
                                          <DataTableExtensions {...tableData}>
                                            <DataTable
                                              noHeader
                                              defaultSortField="id"
                                              defaultSortAsc={false}
                                              pagination
                                              highlightOnHover
                                              onRowClicked={handleRowClick}
                                              conditionalRowStyles={
                                                conditionalRowStyles
                                              }
                                            />
                                          </DataTableExtensions>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                role="tabpanel"
                                id="tab-3"
                                className={`tab-pane fade show ${
                                  activeTab === "location" ? "active" : ""
                                }`}
                              >
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="card card-table">
                                      <div className="card-body">
                                        <div className="table-responsive">
                                          <div
                                            id="tailorMap"
                                            style={{ height: "400px" }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </>
      )}
    </>
  );
};
export default Profile;
