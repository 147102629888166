import React, { useEffect, useState, useContext } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from 'axios';
import { AuthContext } from "../authentication/AuthContext";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { ColorRing } from 'react-loader-spinner';
import JoinUs from "../authentication/JoinUs";
import Footer from "../layouts/Footer";


const Dashboard = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [menu, setMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dashboardCounts, setDashboardCounts] = useState([]);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  useEffect(() => {
    getDashboardCounts();
  }, []);

  function getDashboardCounts() {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/dashboard`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          console.log(response,'response  ');
          setDashboardCounts(response.data.body);
          if(currentUser?.role?.name == 'tailor'&& currentUser?.isFirstLogin == true){
            setShowModal(true)
          }
        },
        (error) => {
          console.log(error,"error");
          toast.error(error.response.data.message, { toastId: 'error1', });
        }
      );
  }

  const numberWithCommas = (number) => {
    return number?.toLocaleString('en-GB');
  };

  return (
    <>
    {dashboardCounts.length===0 ? <div className="" style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
    <ColorRing
      visible={true}
      height="auto"
      width="20%"
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={['#7638ff','#7638ff','#7638ff','#7638ff','#7638ff','#7638ff']}
    />
    </div> :
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {
            currentUser.role.name === 'admin' && (
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <div className="dash-count">
                          <div className="dash-title">
                            <h2>Earn £1 per Order as a Tailor</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )
          }
          <div className="row">
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card">
                <div className="card-body d-flex">
                    <span className="dash-widget-icon bg-1">
                      <i className="fas fa-pound-sign"></i>
                    </span>
                    <div className="ms-3">
                      <div className="dash-title">
                        <h2>{currentUser?.currency ? currentUser?.currency : "£"}{ numberWithCommas(dashboardCounts['totalRevenue']) ? numberWithCommas(dashboardCounts['totalRevenue']) : 0}</h2>
                      </div>
                      <div className="dash-counts">
                        <p>Total Sales</p>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            {
              currentUser.role.name === 'admin' && (
                <div className="col-xl-3 col-sm-6 col-12">
                  <a href="/tailors">
                    <div className="card">
                      <div className="card-body d-flex">
                          <span className="dash-widget-icon bg-2">
                            <i className="fas fa-users"></i>
                          </span>
                          <div className="ms-3">
                            <div className="dash-title">
                              <h2>{dashboardCounts['totalTailors'] ? dashboardCounts['totalTailors'] : 0}</h2>
                            </div>
                            <div className="dash-counts">
                              <p>Tailor</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </a>
                </div>
              )
            }
             {
              currentUser.role.name === 'tailor' && (
                <div className="col-xl-3 col-sm-6 col-12">
                  <a href="/orders">
                    <div className="card">
                      <div className="card-body d-flex">
                          <span className="dash-widget-icon bg-2">
                            <i className="fas fa-file-alt"></i>
                          </span>
                          <div className="ms-3">
                            <div className="dash-title">
                              <h2>{currentUser?.currency ? currentUser?.currency : "£"}{ numberWithCommas(dashboardCounts['totalRemaining']) ? numberWithCommas(dashboardCounts['totalRemaining']) : 0}</h2>
                            </div>
                            <div className="dash-counts">
                              <p>Balance Owed</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </a>
                </div>
              )
            }
            <div className="col-xl-3 col-sm-6 col-12">
              <a href="/customers">
                <div className="card">
                  <div className="card-body d-flex">
                      <span className="dash-widget-icon bg-3">
                        <i className="fas fa-users"></i>
                      </span>
                      <div className="ms-3">
                        <div className="dash-title">
                          <h2>{dashboardCounts['totalCustomers'] ? dashboardCounts['totalCustomers'] : 0}</h2>
                        </div>
                        <div className="dash-counts">
                          <p>Total Customers</p>
                        </div>
                      </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <a href="/orders">
                <div className="card">
                  <div className="card-body d-flex">
                      <span className="dash-widget-icon bg-4">
                        <i className="far fa-file"></i>
                      </span>
                      <div className="ms-3">
                        <div className="dash-title">
                          <h2>{dashboardCounts['numberOfIncompleteOrders'] ? dashboardCounts['numberOfIncompleteOrders'] : 0}</h2>
                        </div>
                        <div className="dash-counts">
                          <p>Orders To Complete</p>
                        </div>
                      </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title">
                        <h2>Top 5 { currentUser.role.name === 'admin' ? 'Tailors' : 'Customers' }</h2>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="mb-3"></div>
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th>Name</th>
                          { currentUser.role.name === 'admin' ? <th>Orders Count</th> : <th>Total Amount</th> }
                          <th>Phone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardCounts['topUsers']?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{ currentUser.role.name === 'admin' ? item.tailor.name : item.customer.name.substring(0,20)}</td>
                              <td>{ currentUser.role.name === 'admin' ? item.orderCount : (currentUser?.currency ? currentUser?.currency : "£")+ numberWithCommas(item.totalPaidAmount)}</td>
                              <td>{ currentUser.role.name === 'admin' ? item.tailor.phoneNumber : item.customer.phoneNumber }</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {
              currentUser.role.name === 'tailor' && (
                <div className="col-md-6 col-sm-6">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title">
                            <h2>Top 5 Customers Balance Owed</h2>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="mb-3"></div>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th>Name</th>
                              <th>Balance</th>
                              <th>Phone Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dashboardCounts['topUsersOwes']?.map((item, index) => {
                              return (
                                <tr key={index} onClick={() => navigate(`/orders-edit/${item._id}`)}>
                                  <td>{ item.customer.name.substring(0,20) }</td>
                                  <td>{(currentUser?.currency ? currentUser?.currency : "£") +numberWithCommas(item.remainingBalance)}</td>
                                  <td>{ item.customer.phoneNumber }</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  }
 <JoinUs showModal={showModal}
        setShowModal={setShowModal}  />
    </>
  );
};
export default Dashboard;
