import React, { useEffect, useContext,useRef } from "react";
import { AuthContext } from "./AuthContext";
import { GoogleLogout } from 'react-google-login';
import { OAUTH2CLIENTID } from "../assets/constant";
import { useNavigate } from 'react-router-dom';




const Logout = () => {
  const navigate = useNavigate();
  const { setCurrentUser } = useContext(AuthContext);
  const buttonRef = useRef(null);




  function logout(data){
    setCurrentUser(null);
    localStorage.removeItem("currentUser");
    localStorage.clear();
    console.log(data);
    console.log('here');
    console.log('here after logout');
    document.cookie = 'G_ENABLED_IDPS=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        
        // Clear G_AUTHUSER_H cookie
    document.cookie = 'G_AUTHUSER_H=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    navigate('/');
  }
  useEffect(() => {

     setCurrentUser();

    localStorage.clear();
    localStorage.removeItem("currentUser");
    if (buttonRef.current) {
      // console.log('button clicked');
      buttonRef.current.click();
    }   
     navigate('/login'); 
  }, []);

  return (
    <>
    <GoogleLogout
    clientId = {OAUTH2CLIENTID}
    buttonText="Logout"
    // ref={buttonRef}
    onLogoutSuccess={logout}
    render={(renderProps) => (
      <button ref={buttonRef} disabled={renderProps.disabled}>
        Logout
      </button>
    )}
  >
  </GoogleLogout>
    </>
  );
};

export default Logout;
