import React, { useEffect, useState, useContext,useRef } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Autocomplete } from "@react-google-maps/api";
import Footer from "../layouts/Footer";


const AddTailor = (props) => {
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (currentUser.role.name === "admin") {
      toast.error("Admin can not create a customer!", { toastId: "error1" });
      navigate("/");
    }
  }, []);

  const [menu, setMenu] = useState(false);

  const { currentUser } = useContext(AuthContext);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const handlePlaceSelect = (place) => {
    setSelectedLocation(place);
  };


  const [date, setDate] = useState(new Date());
  const [currencyOptions, setcurrencyOptions] = useState([
    { id: 1, text: "Select Currency" },
    { id: 2, text: "EUR Euro" },
    { id: 3, text: "INR Indoan Rupee" },
    { id: 4, text: "USD- US Dollar" },
  ]);
  const [countryOptions, setcountryOptions] = useState([
    { id: 1, text: "Select Country" },
    { id: 2, text: "Afghanistan" },
    { id: 3, text: "Albania" },
    { id: 4, text: "American Samoa" },
    { id: 5, text: "Algeria" },
    { id: 6, text: "Andorra" },
    { id: 7, text: "Angola" },
    { id: 8, text: "Anguilla" },
    { id: 9, text: "United States" },
  ]);

  const onSubmit = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/users`,
        {
          ...data,
          role: "customer",
          tailor: currentUser["id"],
          tailorLocation:selectedLocation
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          toast.success(response.data.message);
          navigate(`/profile/${response.data.body[0].user.id}`);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };
  const onEyeClick = () => {
    seteye(!eye);
  };
  const [eye, seteye] = useState(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className=" container-fluid">
              <div className="mt-5">
                <div className="row">
                  <div className="col-sm-12">
                    <Row className="mb-5">
                      <Col>
                        {" "}
                        <span style={{ fontSize: "30px", fontWeight: "500" }}>
                          Add Customer
                        </span>{" "}
                      </Col>
                      <Col>
                        <button
                          className="btn btn-md btn-primary float-end"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <Row>
                        <Col md={4} sm={6}>
                          <div className="form-group input_text">
                            <label className="form-control-label">Name</label>
                            <Controller
                              name="name"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control ${
                                    errors?.name ? "error-input" : ""
                                  }`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                  placeholder="e.g. John Snow"
                                  required
                                />
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.name?.message}</small>
                          </div>
                        </Col>
                        <Col md={4} sm={6}>
                          <div className="form-group input_text">
                            <label className="form-control-label">
                              Email Address
                            </label>
                            <Controller
                              name="email"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control  ${
                                    errors?.email ? "error-input" : ""
                                  }`}
                                  type="email"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                  placeholder="something@email.com"
                                />
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.email?.message}</small>
                          </div>
                        </Col>
                        <Col md={4} sm={6}>
                          <div className="form-group input_text">
                            <label className="form-control-label">
                              Contact
                            </label>
                            <Controller
                              name="phoneNumber"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control  ${
                                    errors?.phoneNumber ? "error-input" : ""
                                  }`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                  placeholder=""
                                />
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.phoneNumber?.message}</small>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} sm={6}>
                          <div className="form-group input_text">
                            <label className="form-control-label">Gender</label>
                            <Controller
                              name="gender"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <Form.Select
                                  className={`form-control ${
                                    errors?.name ? "error-input" : ""
                                  }`}
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                  required
                                >
                                  <option value="">Select an option</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                </Form.Select>
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.name?.message}</small>
                          </div>
                        </Col>
                        <Col md={4} sm={6}>
                          <div className="form-group input_text">
                            <label className="form-control-label">Tier</label>
                            <Controller
                              name="tier"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <Form.Select
                                  className={`form-control ${
                                    errors?.name ? "error-input" : ""
                                  }`}
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                  required
                                >
                                  <option value="">Select an option</option>
                                  <option value="silver">Silver</option>
                                  <option value="gold">Gold</option>
                                  <option value="platinum">Platinum</option>

                                </Form.Select>
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.name?.message}</small>
                          </div>
                        </Col>
                        <Col md={4} sm={6}>
                        <div className="row form-group">
                        <label className="form-control-label">Address</label>
                        <div className="col-sm-12">
                          <Autocomplete
                            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                            onPlaceChanged={() =>
                              handlePlaceSelect(autocompleteRef.current.getPlace())
                            }
                          >
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search location..."
                            />
                          </Autocomplete>
                        </div>
                      </div>
                      </Col>
                      <Col md={4} sm={6}>
                          <div className="form-group input_text">
                            <label className="form-control-label">Other Address</label>
                            <Controller
                              name="otherAddress"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control ${
                                    errors?.otherAddress ? "error-input" : ""
                                  }`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                  placeholder="Other Address"
                                />
                              )}
                              defaultValue=""
                            />

                            <small>{errors?.otherAddress?.message}</small>
                          </div>
                        </Col>
                    
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};
export default AddTailor;
