import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { BASE_URL } from "../assets/constant";
import "react-data-table-component-extensions/dist/index.css";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faArrowUpFromBracket,
  faDownload,
  faFileAlt 
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../layouts/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import AddStylePopUp from "../component/AddStylePop";

const Customerss = () => {
  const { currentUser } = useContext(AuthContext);
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();

  const [myData, setMyData] = useState([]);
  const [myProduct, setMyProduct] = useState([]);
  const [showPop, setModalPopShow] = useState(false);

  const [isError, setIsError] = useState("");
  const [singleRow, setSingleRew] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [refreshList, setRefreshList] = useState(false);
  const [type, setType] = useState("");
  const [uploadCustomerFile, setUploadCustomerFile] = useState("");
  const [showUploadCustomer, setShowUploadCustomer] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const handleShow = (row) => {
    setShow(true);
    setSingleRew(row);
  };
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleCloseUploadCustomer = () => {
    setShowUploadCustomer(false);
    setType("");
    setUploadCustomerFile("");
  };

  const [date, setDate] = useState(new Date());
  const [options, setOptions] = useState([
    { id: 1, text: "Draft" },
    { id: 2, text: "Sent" },
    { id: 3, text: "Viewed" },
    { id: 4, text: "Expired" },
    { id: 5, text: "Accepted" },
    { id: 6, text: "Rejected" },
  ]);

  const handleShowUploadCustomer = () => {
    setShowUploadCustomer(true);
  };
  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleCustomerFileChange = (event) => {
    const files = event.target.files[0];
    setUploadCustomerFile(files);
  };
  // console.log(singleRow);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.key === "Enter") event.preventDefault();

    axios
      .patch(`${BASE_URL}/api/users/${singleRow._id}`, singleRow, {
        headers: { Authorization: `Bearer ${currentUser.accessToken}` },
      })
      .then((response) => {
        // setMyData(response.data.body);
        toast.success(response.data.message);
        handleClose();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsError(error.response.data.message);
      });
    // console.log(event.target.elements.username.value)
    // console.log(event.target.username.value)
    // console.log(this.inputNode.value)
  };

  useEffect(() => {
    console.log(currentUser.role.name);
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/users`, {
          params: {
            user_role: "customer",
          },
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        })
        .then(
          (response) => {
            // setMyData(response.data.body.length> 0 ? response.data.body : [{"data":"nothing found"}] );
            setMyData(response.data.body.length > 0 ? response.data.body : 0);
          },
          (error) => {
            toast.error(error.response.data.message);
            setIsError(error.message);
          }
        );
    } catch (error) {
      console.log(error);
    }
  }, [refreshList]);

  const handleRowClick = (row) => {
    if (currentUser.role.name === "tailor") {
      navigate(`/profile/${row._id}`);
    }
  };

  const columns = [
    {
      name: <div>No</div>,
      export: false,
      selector: (row) => row.indexNo,
      sortable: true,
      width: "18%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "37%",
      cell: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>
          <a href={`/profile/${row._id}`}>{row.name.substring(0, 20)}</a>
        </div>
      ),
    },
    {
      name: "Contact",
      cell: (row) => (
        <div className="float-right" style={{ whiteSpace: "break-spaces" }}>
          {row.phoneNumber}
        </div>
      ),
      sortable: true,
      width: "40%",
    },
    {
      name: "Tailor",
      export: false,
      selector: (row) => row.tailor?.name,
      sortable: true,
      width: "5%",
    },
    // {
    //   name:<div>Phone Number</div>,
    //   selector: (row) => row.phoneNumber,
    //   sortable: true,
    //   width: "40%",
    // },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <div>
    //       <i onClick={() => handleShow(row)} className="fa fa-edit"></i>
    //     </div>
    //   ),
    //   sortable: false,
    //   width: "20%",
    // }
  ];

  const ExpandedComponent = ({ data }) => (
    <>
      <div className="ml-3">
        <div className="form-group p-3">
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="">Email</label>
              <span className="form-control">{data?.email}</span>
            </div>
            <div className="col-lg-4">
              <label htmlFor="">Contact</label>
              <span className="form-control">{data?.phoneNumber}</span>
            </div>
            <div className="col-lg-4">
              <label htmlFor="">Gender</label>
              <span className="form-control">{data?.gender}</span>
            </div>
            <div className="col-lg-6 mt-2">
              <label htmlFor="">Address</label>
              <span className="form-control">
                {data?.address?.formatted_address
                  ? data?.address?.formatted_address
                  : data?.address}
              </span>
            </div>
            <div className="col-lg-6 mt-2">
              <label htmlFor="">Other Address</label>
              <span className="form-control">{data?.otherAddress}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const role = currentUser.role.name;
  const tableData = {
    columns:
      role !== "tailor"
        ? columns
        : columns.filter((column) => column.name !== "Tailor"),
    data: myData !== 0 ? myData : [],
    export: true,
    exportHeaders: true,
    print: false,
    fileName: "customerTest",
  };

  const handleChange = (date) => {
    setDate(date);
  };

  const tooltip = <Tooltip id="tooltip">Add Customer</Tooltip>;
  const UploadCustomertooltip = (
    <Tooltip id="tooltip">Upload Customers</Tooltip>
  );

  const downloadCustomertooltip = (
    <Tooltip id="tooltip">Customers Template</Tooltip>
  );

  const handleImportCustomer = () => {
    if (type && uploadCustomerFile) {
      const formData = new FormData();
      formData.append("type", type);
      formData.append("tailor", currentUser["id"]);
      formData.append("file", uploadCustomerFile);
      formData.append("role", "customer");
      setIsloading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/import/customer`,
          formData,
          {
            headers: { Authorization: `Bearer ${currentUser.accessToken}` },
          }
        )
        .then((response) => {
          toast.success(response.data.message + ".Uploading may take up to 5 minutes if your file contains a large amount of data.");
          handleCloseUploadCustomer();
          setRefreshList(true);
          setIsloading(false);
        })
        .catch((error) => {
          toast.error("Please Upload Correct file template");
          setIsloading(false);
        });
    } else {
      toast.error("Fill the Complete Form");
    }
  };

  const fetchClothTypes = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_types`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setMyProduct(
            response.data.body.length > 0 ? response.data.body : false
          );
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsError(error.message);
        }
      );
  };

  useEffect(() => {
    fetchClothTypes();
  }, []);

  const handleDownloadCustomerTemplate = () => {
    setIsloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/customerTemplate`,
        { responseType: "blob" },
        {
          headers: { Authorization: `Bearer ${currentUser.accessToken}` },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `CustomerTemplate.xlsx`);
        document.body.appendChild(link);
        link.click();
        setIsloading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsloading(false);
      });
  };

  const onclickCustomer = () => {
    if (!myProduct) {
      setModalPopShow(true);
    } else {
      navigate("/customers-new");
    }
  };
  return (
    <>
      
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Tailor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <input
                className="form-control"
                defaultValue={singleRow?._id}
                type="hidden"
                name="id"
                id="id"
              />
              <div className="col-12">
                <label htmlFor="">Name</label>
                <input
                  className="form-control"
                  defaultValue={singleRow?.name}
                  onChange={(e) => {
                    singleRow.name = e.target.value;
                  }}
                  type="text"
                  name="name"
                  id="name"
                />
              </div>
              <div className="col-12">
                <label htmlFor="">Email</label>
                <input
                  className="form-control"
                  defaultValue={singleRow?.email}
                  disabled
                  type="text"
                  name="email"
                  id="email"
                />
              </div>
              <div className="col-12">
                <label htmlFor="">Phone Number</label>
                <input
                  className="form-control"
                  onChange={(e) => {
                    singleRow.phoneNumber = e.target.value;
                  }}
                  defaultValue={
                    singleRow?.phoneNumber != null &&
                    singleRow?.phoneNumber != ""
                      ? singleRow?.phoneNumber
                      : ""
                  }
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                />
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" variant="success">
                Save
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      {myData.length === 0 || isloading == true ? (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ColorRing
            visible={true}
            height="auto"
            width="20%"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={[
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
            ]}
          />
        </div>
      ) : (
        <>
<Modal show={showUploadCustomer} onHide={handleCloseUploadCustomer}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <label className="form-label">Type</label>
            <Row className="mb-3">
              <Col lg={2}>
                <Form.Check
                  inline
                  type="checkbox"
                  label="CSV"
                  value="csv"
                  checked={type === "csv"}
                  onChange={handleTypeChange}
                />
              </Col>
              <Col lg={6}>
                <Form.Check
                  inline
                  type="checkbox"
                  label="Excel"
                  value="excel"
                  checked={type === "excel"}
                  onChange={handleTypeChange}
                />
              </Col>
            </Row>
            <div className="form-group">
              <label className="form-label">Upload File</label>
              <input
                type="file"
                id="images"
                className="form-control"
                multiple
                accept=".xlsx, .csv"
                onChange={handleCustomerFileChange}
                min="1"
                max="1"
                required
              />
            </div>
          </>
          <Row>
            <Col lg={7} md={5} sm={5} xs={5}>
          <OverlayTrigger
                            placement="top"
                            overlay={downloadCustomertooltip}
                          >
                            <Button
                              className="btn btn-primary btn-md float-left"
                              onClick={() => handleDownloadCustomerTemplate()}
                            >
                              {/* <FontAwesomeIcon icon={faFileAlt} /> */}
                              Download Template
                            </Button>
                          </OverlayTrigger>
                          </Col>
            <Col lg={5} md={7} sm={7} xs={7} >
            <Button variant="secondary" onClick={handleCloseUploadCustomer} className="float-right mx-1">
              Cancel
            </Button>{' '}
            <Button variant="success" onClick={handleImportCustomer} className="float-right">
              Save
            </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
        <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <Sidebar />
          <div className="page-wrapper">
            <div className="container-fluid">
              <div className="mb-1">
                <div className="row align-items-center mt-5">
                  <Col lg={8} md={6} sm={6} xs={7}>
                    <h3 className="mt-1">Customers</h3>
                  </Col>
                  <Col lg={4}md={6} sm={6} xs={5}>
                    {currentUser.role.name === "tailor" && (
                      <Row className="mb-1 float-right">
                          
                        <Col lg={4} md={4} sm={4} xs={4} className="float-left  mx-1">
                          <OverlayTrigger placement="top" overlay={tooltip}>
                            <Button
                              className="btn btn-primary btn-md float-left"
                              onClick={onclickCustomer}
                            >
                              <FontAwesomeIcon icon={faUserPlus} />
                            </Button>
                          </OverlayTrigger>
                        </Col>
                        <Col lg={4} md={4} sm={4} xs={4} className="mx-1">
                          <OverlayTrigger
                            placement="top"
                            overlay={UploadCustomertooltip}
                          >
                            <Button
                              className="btn btn-primary btn-md"
                              onClick={() => handleShowUploadCustomer()}
                            >
                              <FontAwesomeIcon icon={faArrowUpFromBracket} />
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </div>
              </div>

              <div id="filter_inputs" className="card filter-card">
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Customer:</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Status:</label>
                        <Select2
                          className="w-100"
                          data={options}
                          options={{
                            placeholder: "Select Status",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>From</label>
                        <div className="cal-icon">
                          <DatePicker
                            selected={date}
                            onChange={handleChange}
                            className="form-control datetimepicker"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>To</label>
                        <div className="cal-icon">
                          <DatePicker
                            selected={date}
                            onChange={handleChange}
                            className="form-control datetimepicker"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Estimate Number</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table">
                    <div className="card-body">
                      <div className="table-responsive break-whitespace">
                        <DataTableExtensions {...tableData}>
                          <DataTable
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            className="data-table-extensions-action p-3"
                            onRowClicked={handleRowClick}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            highlightOnHover
                          />
                        </DataTableExtensions>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
        </>
      )}
      <AddStylePopUp
        showPop={showPop}
        setModalPopShow={setModalPopShow}
      ></AddStylePopUp>
    </>
  );
};
export default Customerss;
