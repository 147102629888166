import React from "react";

const Footer = () => {
  return (
    <footer className="bg-white text-black text-center py-0 mt-4"style={{ position: 'fixed', bottom: 0, left: 0, right: 0, }} >
    <div className="container">
      <p>&copy; Powered by My Tailor. Book. Measure.</p>
      {/* <p>Designed with <i className="bi bi-heart-fill text-black"></i> by You</p> */}
    </div>
  </footer>
  
//     <div className="container position-absolute top-100 start-100 translate-middle">
//   <div className="row">
//     <div className="col">
//       Column
//     </div>
//     <div className="col">
//       Column
//     </div>
//     <div className="col">
//       Column
//     </div>
//   </div>
// </div>
  );
};

export default Footer;
