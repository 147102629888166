import React, { useState } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { Card, Col, Modal, Row } from "react-bootstrap";
import paystack from "../assets/img/paystack.png";
import twillio from "../assets/img/twillio.png";
import Footer from "../layouts/Footer";

const Marketplace = () => {
  const [menu, setMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleOpen = () => setShowModal(true);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "500px",
              color: "grey",
            }}
          >
            <b>Coming Soon</b>
          </div>
        </Modal.Body>
      </Modal>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid text-center">
            <Row className="p-3">
            <Card style={{ backgroundColor: '#F8F5FF' }}> 
              <span className="marketplace_title">
                Work smarter, not harder and do more with apps.
              </span>
              </Card>
            </Row>
            <Row className="text-center">
              <Col lg={4} md={5} sm={3}>
                <Card className="p-4" style={{ height: "150px" }} onClick={handleOpen}>
                  <Card.Img
                    className="mx-auto"
                    style={{ height: "auto", width: "60%", marginTop: "20px" }}
                    src={paystack}
                    alt="Card image cap"
                  />
                  <Card.Body>
                    <Card.Title>Get paid with Paystacks</Card.Title>
                    {/* <Card.Text>Receive payments</Card.Text> */}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4} md={5} sm={3}>
                <Card className="p-4" style={{ height: "150px" }} onClick={handleOpen}>
                  <Card.Img
                    src={twillio}
                    className="mx-auto"
                    alt="Card image cap"
                    style={{
                      height: "auto",
                      width: "50%",
                    }}
                  />
                  <Card.Body>
                    <Card.Title>Broadcast with Twilio</Card.Title>
                    <Card.Text>
                      {/* Communicate directly with your customers */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </>
  );
};
export default Marketplace;
