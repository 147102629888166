import React, { useEffect, useState, useContext } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../assets/constant";
import { useParams } from "react-router-dom";
import Footer from "../layouts/Footer";
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { ColorRing } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const AssignTempMeasurement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [clothTypesList, setClothTypesList] = useState([]);
  const [myDataMeasurement, setMyDataMeasurement] = useState([]);
  const [assignedMeasurements, setAssignedMeasurements] = useState([]);
  const [touchData, setTouchData] = useState(null);
  const [menu, setMenu] = useState(false);
  const [productId, setProductId] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [tempMeasurementData, setTempMeasurementData] = useState([]);
  const [data, setData] = useState([]);


  const fetchMeasurement = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/dynamicMeasurements`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setMyDataMeasurement(
            response.data.body.length > 0 ? response.data.body : []
          );
          setTempMeasurementData(
            response.data.body.length > 0 ? response.data.body : []
          );
          setData(response.data.body.length > 0 ? response.data.body : [])
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };
  const fetchAssignDynamicMeasurement = (productId) => {
    setIsloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/assignDynamicMeasurement?clothType=${productId}`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          setAssignedMeasurements(
            response.data.body.filter((item) =>
              item.hasOwnProperty("dynamicMeasurement")
            )
          );
          const filteredIds = response?.data?.body?.map(
            (item) => item?.dynamicMeasurement?._id
          );
          const filteredTempMeasurementData = tempMeasurementData.filter(
            (item) => !filteredIds.includes(item._id)
          );
          setMyDataMeasurement(filteredTempMeasurementData);
          setIsloading(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsloading(false);
        }
      );
  };

  useEffect(() => {
    fetchMeasurement();
  }, []);

  const handleProductChange = (event) => {
    setAssignedMeasurements([]);
    setProductId(event.target.value);
    setMyDataMeasurement(tempMeasurementData);
    fetchAssignDynamicMeasurement(event.target.value);
  };

  const { currentUser } = useContext(AuthContext);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const fetchClothTypes = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_types`, {
        params: {
          active: "true",
        },
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setClothTypesList(response.data.body);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  useEffect(() => {
    fetchClothTypes();
  }, []);

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleSubmitMeasurement = (measurement) => {
    setIsloading(true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/dynamicMeasurements/assign`,
          {
            title: measurement.title,
            productId: productId,
            dynamicMeasurementId: measurement._id,
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser.accessToken}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message);
          resolve(response);
          setIsloading(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setIsloading(false);
          reject(error);
        });
    });
  };

  const handleUnassignedMeasurement = (measurement) => {
    setIsloading(true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/unassignDynamicMeasurement`,
          {
            clothType: productId,
            measurement: measurement,
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser.accessToken}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message);
          resolve(response);
          setIsloading(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          reject(error);
          setIsloading(false);
        });
    });
  };

  const handleDrop = (e) => {
    if (productId) {
      e.preventDefault();
      const id = e.dataTransfer.getData("id");
      const measurement = myDataMeasurement.find((item) => item._id === id);
      handleSubmitMeasurement(measurement)
        .then((response) => {
          setAssignedMeasurements((prevState) => [
            ...prevState,
            {
              _id: response?.data?.body?._id,
              dynamicMeasurement: measurement,
              title: response?.data?.body?.title,
            },
          ]);
          setMyDataMeasurement((prevState) =>
            prevState.filter((item) => item._id !== id)
          );
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("Please select Product");
    }
  };

  const handleTouchStart = (e, id) => {
    const touch = e.touches[0];
    e.preventDefault();
    setTouchData({
      id,
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
  };

  const handleTouchEnd = (e) => {
    if (productId) {
      e.preventDefault();
      const measurement = myDataMeasurement.find(
        (item) => item._id === touchData.id
      );
      handleSubmitMeasurement(measurement)
        .then((response) => {
          setAssignedMeasurements((prevState) => [
            ...prevState,
            {
              _id: response?.data?.body?._id,
              dynamicMeasurement: measurement,
              title: response?.data?.body?.title,
            },
          ]);
          setMyDataMeasurement((prevState) =>
            prevState.filter((item) => item._id !== touchData.id)
          );
          setTouchData(null);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setTouchData(null);
        });
    } else {
      toast.error("Please Select Product");
      setTouchData(null);
    }
  };

  const handleDropAvailable = (e) => {
    e.preventDefault();
    const id = e.dataTransfer.getData("id");
    const measurement = assignedMeasurements.find((item) => item._id === id);
    handleUnassignedMeasurement(measurement._id)
      .then((response) => {
        setMyDataMeasurement((prevState) => [
          ...prevState,
          measurement.dynamicMeasurement,
        ]);
        setAssignedMeasurements((prevState) =>
          prevState.filter((item) => item._id !== id)
        );
        setTouchData(null);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handleUnassignTouchEnd = (e) => {
    e.preventDefault();
    const measurement = assignedMeasurements.find(
      (item) => item._id === touchData.id
    );
    handleUnassignedMeasurement(measurement._id)
      .then((response) => {
        setMyDataMeasurement((prevState) => [
          ...prevState,
          measurement.dynamicMeasurement,
        ]);
        setAssignedMeasurements((prevState) =>
          prevState.filter((item) => item._id !== touchData.id)
        );
        setTouchData(null);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setTouchData(null);
      });
  };

  return (
    <>
      {isloading == true ? (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ColorRing
            visible={true}
            height="auto"
            width="20%"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={[
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
            ]}
          />
        </div>
      ) : (
        <>
          <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
            <Header onMenuClick={(value) => toggleMobileMenu()} />
            <Sidebar />
            <div className="page-wrapper">
              <div className=" container-fluid">
                <div className="">
                  <div className="row">
                    <div className="col-sm-12">
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="mb-2"
                        size="2x"
                        style={{ marginTop: "40px" }}
                        onClick={() => navigate(`/profile/${id}`)}
                      />
                      <Row className="mt-1">
                        <Col>
                          <span style={{ fontSize: "23px", fontWeight: "500" }}>
                            Assign Measurements
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <Row className="mb-4 mt-4">
                  <Col md={5}>
                    <label className="form-label">Product</label>
                    <Form.Select
                      className="form-control rounded-3"
                      name="clothType"
                      onChange={handleProductChange}
                      value={productId}
                    >
                      <option value="" disabled selected>
                        Select a Product
                      </option>
                      {clothTypesList?.map((type) => (
                        <option key={type._id} value={type._id}>
                          {type.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>

                <div className="row">
                  <div className="col-md-12"></div>
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <Container>
                          {data?.length > 0 ? (
                            <Row>
                              <Col
                                onDragOver={handleDragOver}
                                onDrop={handleDropAvailable}
                              >
                                <Card>
                                  <Card.Header as="h5">List</Card.Header>
                                  <Card.Body
                                    style={{
                                      maxHeight: "300px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <ListGroup as="ol" style={{ gap: "10px" }}>
                                      {myDataMeasurement?.map((task, index) => (
                                        <div
                                          key={index}
                                          draggable="true"
                                          onDragStart={(e) =>
                                            handleDragStart(e, task._id)
                                          }
                                          onTouchStart={(e) =>
                                            handleTouchStart(e, task._id)
                                          }
                                          onTouchMove={(e) =>
                                            handleTouchMove(e)
                                          }
                                          onTouchEnd={(e) => handleTouchEnd(e)}
                                        >
                                          <ListGroup.Item
                                            as="li"
                                            style={{
                                              borderRadius: "10px",
                                              borderColor: "#621aff",
                                              color: "#621aff",
                                              backgroundColor: "#e2d5ff",
                                            }}
                                          >
                                            <p>{task?.title}</p>
                                          </ListGroup.Item>
                                        </div>
                                      ))}
                                    </ListGroup>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                              >
                                <Card>
                                  <Card.Header as="h5">Assign</Card.Header>
                                  <Card.Body
                                    style={{
                                      maxHeight: "300px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <ListGroup as="ol" style={{ gap: "10px" }}>
                                      {assignedMeasurements?.map(
                                        (measurement, index) => (
                                          <div
                                            key={index}
                                            draggable="true"
                                            onDragStart={(e) =>
                                              handleDragStart(
                                                e,
                                                measurement._id
                                              )
                                            }
                                            onTouchStart={(e) =>
                                              handleTouchStart(
                                                e,
                                                measurement._id
                                              )
                                            }
                                            onTouchMove={(e) =>
                                              handleTouchMove(e)
                                            }
                                            onTouchEnd={(e) =>
                                              handleUnassignTouchEnd(e)
                                            }
                                          >
                                            <ListGroup.Item
                                              as="li"
                                              style={{
                                                borderRadius: "10px",
                                                borderColor: "#621aff",
                                                color: "#621aff",
                                                backgroundColor: "#e2d5ff",
                                              }}
                                            >
                                              <p>{measurement?.title}</p>
                                            </ListGroup.Item>
                                          </div>
                                        )
                                      )}
                                    </ListGroup>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          ) : (
                            <div className="text-center">
                              <p>
                                <strong>List not available.</strong>
                              </p>
                            </div>
                          )}
                        </Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </>
      )}
    </>
  );
};

export default AssignTempMeasurement;