import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AuthContext } from "../authentication/AuthContext";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Footer from "../layouts/Footer";
import {OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus,faArrowUpFromBracket,faDownload,faFileAlt} from "@fortawesome/free-solid-svg-icons";
import Measurements from "../measurements";

const SlideToggle = window.ReactSlideToggle;


const ClothTypesIndex = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [singleRow, setSingleRow] = useState();
  const [activeTab, setActiveTab] = useState("style");
  const [deleteId, setDeleteId] = useState("");
  const handleClose = () => setShow(false);
  const [isloading, setIsloading] = useState(false);
  const [updateList, setupdateList] = useState(false);
  const handleShow = (id) => {
    setDeleteId(id);
    setShow(true);
  };
  const [showEditServive, setShowEditServive] = useState(false);
  const [showAddServive, setShowAddServive] = useState(false);
  const [showUploadServive, setShowUploadServive] = useState(false);
  const [type, setType] = useState('');
  const [productType, setProductType] = useState('');

  const [uploadServiceFile, setUploadServiceFile] = useState('');
  const [uploadProductFile, setUploadProductFile] = useState('');


  const handleShowAddServive = (row) => {
    setShowAddServive(true);
  };
  const handleCloseAddServive = () => setShowAddServive(false);
  const handleCloseUploadServive = () => {setShowUploadServive(false); setType(""); setUploadServiceFile('')};

  const [showUploadProduct, setShowUploadProduct] = useState(false);
  const handleCloseUploadProduct = () => {setShowUploadProduct(false); setProductType(""); setUploadProductFile('')};

  const handleShowEditServive = (row) => {
    setShowEditServive(true);
    setSingleRow(row);
  };
  const handleEditServive = () => setShowEditServive(false);

  const handleRowClick = (row) => {
    if (currentUser.role.name === "tailor") {
      navigate(`/clothing-edit/${row._id}`);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [myData, setMyData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [isError, setIsError] = useState("");

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const fetchClothTypes = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_types`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setMyData(response.data.body.length > 0 ? response.data.body : 0);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsError(error.message);
        }
      );
  };

  useEffect(() => {
    fetchClothTypes();
  }, []);

  useEffect(() => {
    fetchService();
  }, [updateList]);

  const deleteClothType = () => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/cloth_types/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          handleClose();
          fetchClothTypes();
          toast.success(response.data.message);
        },
        (error) => {
          setIsError(error.message);
          toast.error(error.response.data.message);
        }
      );
  };

  const updateStatus = (id, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/cloth_types/${id}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          fetchClothTypes();
          toast.success(response.data.message);
        },
        (error) => {
          setIsError(error.message);
          toast.error(error.response.data.message);
        }
      );
  };
  const updateServiceStatus = (data) => {
    const newStatus = data.status === "active" ? "inactive" : "active";
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/service/${data._id}`,
        { status: newStatus, title: data.title, price: data.price },
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          fetchService();
          toast.success(response.data.message);
        },
        (error) => {
          setIsError(error.response.data.message);
          toast.error(error.response.data.message);
        }
      );
  };
  const fetchService = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/service`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setServiceData(
            response.data.body.length > 0 ? response.data.body : 0
          );
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsError(error.message);
        }
      );
  };
  const onSubmit = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/service`,
        {
          title: data.title,
          price: data.price,
          tailor: currentUser["id"],
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          toast.success(response.data.message);
          setShowAddServive(false);
          setupdateList(true);
          fetchService();
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  const handleUpdateSubmit = (event) => {
    event.preventDefault();
    if (event.key === "Enter") event.preventDefault();

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/service/${singleRow._id}`,
        singleRow,
        {
          headers: { Authorization: `Bearer ${currentUser.accessToken}` },
        }
      )
      .then((response) => {
        // setMyData(response.data.body);
        toast.success(response.data.message);
        handleEditServive();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsError(error.response.data.message);
      });
    // console.log(event.target.elements.username.value)
    // console.log(event.target.username.value)
    // console.log(this.inputNode.value)
  };
  const { handleSubmit, control } = useForm({});

  const numberWithCommas = (number) => {
    return number?.toLocaleString('en-GB');
  };
  const tooltip = (
    <Tooltip id="tooltip">
      Add Service
    </Tooltip>
  );

  const AddProducttooltip = (
    <Tooltip id="tooltip">
      Add Product
    </Tooltip>
  );

  const columns = [
    // {
    //   name: <div>No.</div>,
    //   selector: (row) => row.indexNo,
    //   sortable: true,
    //   width: "9%",
    // },
    {
      name: "Title",
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>
          {row.title.substring(0, 40)}
        </div>
      ),
      width: "35%",
    },
    {
      name:  "Price",
      sortable: true,
      cell: (row) =>(
        <div style={{ whiteSpace: "break-spaces" }}>
         {(currentUser?.currency ? currentUser?.currency : "£")+ numberWithCommas(Number (row.price))}
         </div>
         ),
      width: "35%",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "30%",
      cell: (row) => (
        <button
          className={`btn btn-sm ${
            row.status === "active" ? "btn-success" : "btn-danger"
          }`}
          onClick={() => updateStatus(row._id, row.status)}
        >
          {row.status === "active" ? "Active" : "Inactive"}
        </button>
      ),
    },
  ];
  const tableData = {
    columns,
    data: myData !== 0 ? myData : [],
    "export": true,
    exportHeaders:true,
    print: false,
    fileName: "products", 
  };

  const columnsService = [
    // {
    //   name: <div>No.</div>,
    //   selector: (row, i) => i + 1,
    //   sortable: true,
    //   width: "20%",
    // },
    {
      name: "Title",
      cell: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>
          {row.title.substring(0, 20)}
        </div>
      ),
      sortable: true,
      width: "30%",
    },
    {
      name: "Price",
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>
        {(currentUser?.currency ? currentUser?.currency : "£") +numberWithCommas(Number(row.price))}
        </div>
        ),
      width: "25%",
    },
    {
      name:  "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "25%",
      cell: (row) => (
        <button
          className={`btn btn-sm ${
            row.status === "active" ? "btn-success" : "btn-danger"
          }`}
          onClick={() => updateServiceStatus(row)}
        >
          {row.status === "active" ? "Active" : "Inactive"}
        </button>
      ),
    },
    {
      name: "Edit",
      export:false,
      cell: (row) => (
        <div>
          <i
            onClick={() => handleShowEditServive(row)}
            className="fa fa-edit"
          ></i>
        </div>
      ),
      sortable: false,
      width: "20%",
    },
 
  ];
  const tableServiceData = {
    columns: columnsService,
    data: serviceData !== 0 ? serviceData : [],
    "export": true,
    exportHeaders:true,
    print: false,
    fileName: "services", 
  };

  const handleShowUploadService = () => {
    setShowUploadServive(true);
  };
  const handleShowUploadProduct = () => {
    setShowUploadProduct(true);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleProductTypeChange = (e) => {
    setProductType(e.target.value);
  };  
  const handleServiceFileChange = (event) => {
    const files = event.target.files[0];
    setUploadServiceFile(files);
  };
  const handleServiceProductChange = (event) => {
    const files = event.target.files[0];
    setUploadProductFile(files);
  };

  const handleImportService = () => {
if(type && uploadServiceFile){
  const formData = new FormData();
  formData.append("type", type);
  formData.append("tailor",currentUser["id"] );
  formData.append("file", uploadServiceFile);
  setIsloading(true);
  axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/import/service`,
        formData,
        {
          headers: { Authorization: `Bearer ${currentUser.accessToken}` },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        handleCloseUploadServive();
        fetchService();
        setIsloading(false);
      })
      .catch((error) => {
        toast.error("Please Upload Correct file template");
        setIsloading(false);
      });
}
else{
  toast.error("Fill the Complete Form");
}

};

const handleImportProduct = () => {
  if(productType && uploadProductFile){
    const formData = new FormData();
    formData.append("type", productType);
    formData.append("tailor",currentUser["id"] );
    formData.append("file", uploadProductFile);
    setIsloading(true);
    axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/import/clothType`,
          formData,
          {
            headers: { Authorization: `Bearer ${currentUser.accessToken}` },
          }
        )
        .then((response) => {
          toast.success(response.data.message);
          handleCloseUploadProduct();
          fetchClothTypes();
          setIsloading(false);
        })
        .catch((error) => {
          toast.error("Please Upload Correct file template");
          setIsloading(false);
        });
  }
  else{
    toast.error("Fill the Complete Form");
  }
  
  };
  

const UploadServicetooltip = (
  <Tooltip id="tooltip">
    Upload Services
  </Tooltip>
);

const UploadProducttooltip = (
  <Tooltip id="tooltip">
    Upload Products
  </Tooltip>
);

const handleDownloadServiceTemplate = () => {
  setIsloading(true);
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/serviceTemplate`,
      { responseType: "blob" },
      {
        headers: { Authorization: `Bearer ${currentUser.accessToken}` },
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `ServiceTemplate.xlsx`);
      document.body.appendChild(link);
      link.click();
      setIsloading(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setIsloading(false);
    });
};

const handleDownloadProductTemplate = () => {
  setIsloading(true);
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/clothTypeTemplate`,
      { responseType: "blob" },
      {
        headers: { Authorization: `Bearer ${currentUser.accessToken}` },
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `ProductTemplate.xlsx`);
      document.body.appendChild(link);
      link.click();
      setIsloading(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setIsloading(false);
    });
};

const downloadServicetooltip = (
  <Tooltip id="tooltip">Services Template</Tooltip>
);

const downloadProducttooltip = (
  <Tooltip id="tooltip">Products Template</Tooltip>
);


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you really want to delete this?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteClothType}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddServive} onHide={handleCloseAddServive}>
        <Modal.Header closeButton>
          <Modal.Title>Add Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12">
                <label htmlFor="">Title</label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <input
                      className="form-control"
                      type="text"
                      name="title"
                      placeholder="Enter an additional service e.g. Tapering"
                      id="title"
                      onChange={onChange}
                      required
                    />
                  )}
                  defaultValue=""
                />
              </div>
              <div className="col-12">
                <label htmlFor="">Price</label>
                <Controller
                  name="price"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <InputGroup
                      name="price"
                      id="price"
                      placeholder="0"
                    
                      required
                    >
                       <InputGroup.Text>{(currentUser?.currency ? currentUser?.currency : "£")}</InputGroup.Text>
                       <Form.Control min='0'  type="number"   onChange={onChange} />
                    </InputGroup>
                  )}
                  defaultValue=""
                />
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseAddServive}>
                Cancel
              </Button>
              <Button variant="success" type="submit" onClick={handleSubmit}>
                Save
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={showEditServive} onHide={handleEditServive}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Servive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleUpdateSubmit}>
            <div className="row">
              <div className="col-12">
                <label htmlFor="">Title</label>
                <input
                  className="form-control"
                  defaultValue={singleRow?.title}
                  onChange={(e) => {
                    singleRow.title = e.target.value;
                  }}
                  type="text"
                  name="name"
                  id="name"
                />
              </div>
              <div className="col-12">
                <label htmlFor="">Price</label>
                <InputGroup
                  name="phoneNumber"
                  id="phoneNumber"
                >
                  <InputGroup.Text>{(currentUser?.currency ? currentUser?.currency : "£")}</InputGroup.Text>
                   <Form.Control    type="number" defaultValue={singleRow?.price}    onChange={(e) => {
                    singleRow.price = e.target.value;
                  }} />
                </InputGroup>
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleEditServive}>
                Cancel
              </Button>
              <Button variant="success" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      {myData.length === 0 || isloading==true ? (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ColorRing
            visible={true}
            height="auto"
            width="20%"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={[
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
              "#7638ff",
            ]}
          />
        </div>
      ) : (
        <>
        
<Modal show={showUploadProduct} onHide={handleCloseUploadProduct}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
    <>
          <label  className="form-label">Type</label>
          <Row className="mb-3" >
            <Col lg={2}>
              <Form.Check
                inline
                type="checkbox"
                label="CSV"
                value="csv"
                checked={productType === "csv"}
                onChange={handleProductTypeChange}
              />
            </Col>
            <Col lg={6}>
              <Form.Check
                inline
                type="checkbox"
                label="Excel"
                value="excel"
                checked={productType === "excel"}
                onChange={handleProductTypeChange}
              />
            </Col>
          </Row>
          <div className="form-group">
                    <label className="form-label">Upload File</label>
                    <input
                      type="file"
                      id="images"
                      className="form-control"
                      multiple
                      accept=".xlsx, .csv"
                      onChange={handleServiceProductChange}
                      min="1"
                      max="1"
                      required
                    />
                  </div>
                  </>
                  <Row>
            <Col lg={7} md={5} sm={5} xs={5}>
            <OverlayTrigger placement="top" overlay={downloadProducttooltip}>
                                <Button
                              className="btn btn-primary"
                              onClick={() => handleDownloadProductTemplate()}
                            >
                              {/* <FontAwesomeIcon icon={faFileAlt} /> */}
                              Download Template
                            </Button>
                            </OverlayTrigger>
                            </Col>
            <Col lg={5} md={7} sm={7} xs={7} >
              <Button variant="secondary" onClick={handleCloseUploadProduct} className="float-right mx-1">
                Cancel
              </Button>{'  '}
              <Button variant="success"  onClick={handleImportProduct} className="float-right">
                Save
              </Button>
              </Col>
          </Row>
        </Modal.Body>
      </Modal>


<Modal show={showUploadServive} onHide={handleCloseUploadServive}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
    <>
          <label  className="form-label">Type</label>
          <Row className="mb-3" >
            <Col lg={2}>
              <Form.Check
                inline
                type="checkbox"
                label="CSV"
                value="csv"
                checked={type === "csv"}
                onChange={handleTypeChange}
              />
            </Col>
            <Col lg={6}>
              <Form.Check
                inline
                type="checkbox"
                label="Excel"
                value="excel"
                checked={type === "excel"}
                onChange={handleTypeChange}
              />
            </Col>
          </Row>
          <div className="form-group">
                    <label className="form-label">Upload File</label>
                    <input
                      type="file"
                      id="images"
                      className="form-control"
                      multiple
                      accept=".xlsx, .csv"
                      onChange={handleServiceFileChange}
                      min="1"
                      max="1"
                      required
                    />
                  </div>
                  </>
                  <Row>
            <Col lg={7} md={5} sm={5} xs={5}>
            <OverlayTrigger
                            placement="top"
                            overlay={downloadServicetooltip}
                          >
                            <Button
                              className="btn btn-primary"
                              onClick={() => handleDownloadServiceTemplate()}
                            >
                              {/* <FontAwesomeIcon icon={faFileAlt} /> */}
                              Download Template
                            </Button>
                          </OverlayTrigger>
                          </Col>
            <Col lg={5} md={7} sm={7} xs={7} >
              <Button variant="secondary" onClick={handleCloseUploadServive} className="float-right mx-1">
                Cancel
              </Button>{'  '}
              <Button variant="success"  onClick={handleImportService} className="float-right">
                Save
              </Button>
              </Col>
          </Row>
        </Modal.Body>
      </Modal>
        <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <Sidebar />
          <div className="page-wrapper">
            <div className="container-fluid">
              <div className="mb-1">
                <div className="row align-items-center mt-5">
                  <div className="col">
                    <h3 className="">Products,Services and Measurements</h3>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card flex-fill bg-white">
                    <div className="card-header">
                      <ul role="tablist" className="nav nav-tabs card-header-tabs flex-column flex-sm-row  ">
                        <li className="nav-item float-right">
                            <Button
                                  className={`nav-link mx-2 ${activeTab === "style" ? "active btn btn-primary" : "btn btn-inactive"}  rounded`}
                                  style={{ color:"white"}}
                                  onClick={() => handleTabChange("style")}
                                >
                                  Products
                                </Button>
                              </li>
                          {' '}

                              <li className="nav-item float-right">
                                <Button
                                  className={`nav-link mx-2 ${activeTab === "service" ? "active btn btn-primary" : "btn btn-inactive"}  rounded`}
                                  style={{ color:"white", marginLeft: "3px", }}
                                  onClick={() => handleTabChange("service")}
                                >
                                  Services
                                </Button>
                              </li>
                              {' '}

                              <li className="nav-item float-right">
                                <Button
                                  className={`nav-link ${activeTab === "measurement" ? "active btn btn-primary" : "btn btn-inactive"}  rounded`}
                                  style={{ color:"white", marginLeft: "3px", }}
                                  onClick={() => handleTabChange("measurement")}
                                >
                                 Measurements
                                </Button>
                              </li>
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="tab-content pt-0">
                        <div
                          role="tabpanel"
                          id="tab-1"
                          className={`tab-pane fade show ${activeTab === "style" ? "active" : ""}`}
                        >
                          <div className="row">
                            <div className="col">
                            <div className="float-end">
                          
                              <Link to="/clothing-new" >
                              <OverlayTrigger placement="top" overlay={AddProducttooltip}>
                                {/* <i className="fas fa-plus"></i> */}
                                <Button className="btn btn-primary">
                                  {/* Add Clothing Style */}
                                  <FontAwesomeIcon icon={faPlus} />
                                </Button>
                                </OverlayTrigger>
                              </Link>
                              {"  "}
                              <OverlayTrigger placement="top" overlay={UploadProducttooltip}>
                              <Button
                                  className="btn btn-primary"
                                  onClick={() => handleShowUploadProduct()}
                                >
                                  <FontAwesomeIcon icon={faArrowUpFromBracket} />
                                </Button>
                                </OverlayTrigger>{"  "}
                               
                                </div>
                              {/* <a className="btn btn-primary filter-btn" href="/customers#" id="filter_search">
									<i className="fas fa-filter"></i>
								</a> */}
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="card card-table">
                                <div className="card-body">
                                  <DataTableExtensions {...tableData}>
                                    <DataTable
                                      noHeader
                                      defaultSortField="id"
                                      className="data-table-extensions-action p-3"
                                      defaultSortAsc={false}
                                      pagination
                                      onRowClicked={handleRowClick}
                                    />
                                  </DataTableExtensions>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          id="tab-2"
                          className={`tab-pane fade show ${
                            activeTab === "service" ? "active" : ""
                          }`}
                        >
                          <div className="row">
                            <div className="col">
                              <div className="float-end">
                                <>
                              <OverlayTrigger placement="top" overlay={tooltip}>
                                <Button
                                  className="btn btn-primary"
                                  onClick={() => handleShowAddServive()}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </Button>
                                </OverlayTrigger>{'  '}
                                <OverlayTrigger placement="top" overlay={UploadServicetooltip}>
                                <Button
                                  className="btn btn-primary"
                                  onClick={() => handleShowUploadService()}
                                >
                                  <FontAwesomeIcon icon={faArrowUpFromBracket} />
                                </Button>
                                </OverlayTrigger>{'  '}
                            
                                </>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="card card-table">
                                <div className="card-body">
                                  <DataTableExtensions {...tableServiceData}>
                                    <DataTable
                                      noHeader
                                      className="data-table-extensions-action p-3"
                                      defaultSortField="id"
                                      defaultSortAsc={false}
                                      pagination
                                    />
                                  </DataTableExtensions>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          role="tabpanel"
                          id="tab-3"
                          className={`tab-pane fade show ${
                            activeTab === "measurement" ? "active" : ""
                          }`}
                        >
                       <Measurements setIsloading={setIsloading}></Measurements>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="filter_inputs" className="card filter-card">
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>Name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>Phone</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
        </>
      )}
    </>
  );
};
export default ClothTypesIndex;
