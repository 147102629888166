import { useParams } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Row from "react-bootstrap/Row";
import { alphaNumericPattern } from "../assets/constant";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from 'react-toastify';
import DynamicInputFields from "../_components/dynamicTextField";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Footer from "../layouts/Footer";

const ClothTypeEdit = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [menu, setMenu] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [measurementFields, setMeasurementFields] = useState([]);

  const handleMeasurementFieldsChange = (fields) => {
    setMeasurementFields(fields);
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const schema = yup
    .object({
      title: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid title")
        .required("Please enter your title"),
        price: yup
        .string()
        .required("Please enter price"),
    })
    .required();

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchClothTypeData = () => {
      const clothTypeId = id;
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_types/${clothTypeId}`, {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        })
        .then(
          (response) => {
            setSelectedOption(response.data.body.status)
            setValue("title", response.data.body.title);
            setValue("status", response.data.body.status);
            setValue("price", response.data.body.price);
            setMeasurementFields(response.data.body.measurements);
          },
          (error) => {
            toast.error(error.response.data.message);
          }
        );
    };

    fetchClothTypeData();
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/cloth_types/${id}`,
        {
          title: data.title,
          price:data.price,
          status: (selectedOption === '' ? 'active' : selectedOption),
          titles: measurementFields,
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          toast.success(response.data.message)
          clearErrors("password");
        },
        (error) => {
          toast.error(error.response.data.message);
          setError("email", {
            message: error.response.data.message,
          });
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleSelect = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
              <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="">
                <div className="row">
                  <div className="col">
                    <h3 className="mt-5">Edit Clothing Style</h3>
                  </div>
                  <div className="col-4 mt-5">
                      <button className="btn btn-md btn-primary btn-sm float-end" type="submit" disabled={isLoading}>
                        {isLoading ? (
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* <h4 className="card-title">Basic Info</h4> */}
                  <Row>
                    <div className="col-4">
                      <div className="input_text">
                        <label className="form-control-label">
                          Title
                        </label>
                        <Controller
                          name="title"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control ${errors?.name ? "error-input" : ""
                                }`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                          defaultValue=""
                        />

                        <small>{errors?.name?.message}</small>
                      </div>
                    </div>
                    <div className="col-4">
                      <label className="form-control-label">Status</label>
                      <select
                        value={selectedOption}
                        onChange={handleSelect}
                        className="form-control"
                        name="status"
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="">Price</label>
                        <Controller
                          name="price"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <InputGroup
                              name="price"
                              id="price"
                            >
                            <InputGroup.Text>{(currentUser?.currency ? currentUser?.currency : "£")}</InputGroup.Text>
                            <Form.Control   required  defaultValue={value}  type="number" onChange={onChange} />
                            </InputGroup>
                          )}
                          defaultValue=""
                        />
                    </div>
                    
                  </Row>

                  {/* <Row>
                    <label>Measurement Area</label>
                    <div className=" col-lg-6 col-sm-12">
                      <DynamicInputFields
                        measurementFields={measurementFields}
                        onMeasurementFieldsChange={handleMeasurementFieldsChange}
                      />
                    </div>

                  </Row> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div >
    <Footer></Footer>
    </>
  );
};
export default ClothTypeEdit;
