import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const DatePicke = ({ selectedDateprop, onDateSelected }) => {
  const [selectedDate, setSelectedDate] = useState(selectedDateprop);
  const currentDate = new Date().toISOString().split('T')[0];

  const handleDateChange = (e) => {
    const date = e.target.value;
    setSelectedDate(date);
    onDateSelected(date);
  };
  

  return (
    <div className="">
        <label htmlFor="date-picker">Delivery Date</label>
        <input
          type="date"
          className="form-control"
          id="date-picker"
          value={selectedDateprop}
          onChange={handleDateChange}
          min={currentDate}
          required
          name= "deliveryDate"
        />
    </div>
  );
};

export default DatePicke;
