import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BASE_URL, alphaNumericPattern, emailrgx } from "../assets/constant";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DropDown from "./DropDown";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Footer from "../layouts/Footer";

const ClothTypeNew = (props) => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleSelect = (event) => {
    setSelectedOption(event.target.value);
  };

  const [date, setDate] = useState(new Date());
  const [currencyOptions, setcurrencyOptions] = useState([
    { id: 1, text: "Select Currency" },
    { id: 2, text: "EUR Euro" },
    { id: 3, text: "INR Indoan Rupee" },
    { id: 4, text: "USD- US Dollar" },
  ]);
  const [countryOptions, setcountryOptions] = useState([
    { id: 1, text: "Select Country" },
    { id: 2, text: "Afghanistan" },
    { id: 3, text: "Albania" },
    { id: 4, text: "American Samoa" },
    { id: 5, text: "Algeria" },
    { id: 6, text: "Andorra" },
    { id: 7, text: "Angola" },
    { id: 8, text: "Anguilla" },
    { id: 9, text: "United States" },
  ]);

  const onSubmit = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/cloth_types`,
        {
          title: data.title,
          price:data.price,
          status: selectedOption === "" ? "active" : selectedOption,
          tailor: currentUser.id,
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          toast.success(response.data.message);
          // navigate(`/clothing-measurements-new/${response.data.body._id}`);
          navigate('/clothing');
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  const { currentUser } = useContext(AuthContext);

  const onEyeClick = () => {
    seteye(!eye);
  };
  const [eye, seteye] = useState(true);

  const schema = yup
    .object({
      title: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid title")
        .required("Please enter your title"),
        price: yup
        .string()
        .required("Please enter price"),
    })
    .required();

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({});

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <div className="page-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <span
                        style={{ fontSize: "30px", fontWeight: "500" }}
                        className="mt-5"
                      >
                        Add Clothing Style{" "}
                      </span>
                      <button
                        className="btn btn-md btn-primary float-end"
                        type="submit"
                      >
                        Save & Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {/* <h4 className="card-title">Basic Info</h4> */}

                      <Col md={5}>
                        <div className="form-group input_text">
                          <label className="form-control-label">Title</label>
                          <Controller
                            name="title"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                className={`form-control ${
                                  errors?.name ? "error-input" : ""
                                }`}
                                type="text"
                                value={value}
                                onChange={onChange}
                                autoComplete="false"
                                placeholder="Enter a clothing style e.g. Kaftan"
                                required
                              />
                            )}
                            defaultValue=""
                          />
                        </div>
                      </Col>
                      <Col md={5}>
                        <label htmlFor="">Price</label>
                        <Controller
                          name="price"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <InputGroup
                              name="price"
                              id="price"
                            >
                              <InputGroup.Text>{(currentUser?.currency ? currentUser?.currency : "£")}</InputGroup.Text>
                              <Form.Control  min="0"  required  type="number" onChange={onChange} />
                                </InputGroup>
                          )}
                          defaultValue=""
                        />
                      </Col>
                      {/* <Col md={12}>
                        
                        </Col> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};
export default ClothTypeNew;
