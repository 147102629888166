import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Scrollbars } from "react-custom-scrollbars";
import { AuthContext } from "../authentication/AuthContext";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = Component => props => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Component
      {...props}
      location={location}
      navigate={navigate}
      params={params}
    />
  );
};


const Sidebar = (props) => {
  const { currentUser } = useContext(AuthContext);
  const [isSideMenu, setSideMenu] = useState("");

  const toggleSidebar = (value) => {
    console.log(value);
    setSideMenu(value);
  };
  
  const titlize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  console.log("Working", isSideMenu);

  let pathName = props.location.pathname;
  return (
    <div className="sidebar" id="sidebar">
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        autoHeight
        autoHeightMin={0}
        autoHeightMax="95vh"
        thumbMinSize={30}
        universal={false}
        hideTracksWhenNotNeeded={true}
      >
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className={`${"/" === pathName ? "active" : ""}`}>
                <Link to="/">
                  <FeatherIcon icon="align-justify" /> <span>Dashboard</span>
                </Link>
              </li>
              {currentUser.role.name == 'admin' && (
                <li
                  className={`${"/tailors" === pathName ||
                      "/tailors-new" === pathName ||
                      "/add-tailor" === pathName
                      ? "active"
                      : ""
                    }`}
                >
                  <Link to="/tailors">
                    <FeatherIcon icon="users" />
                    <span>Tailors</span>
                  </Link>
                </li>
              )}
              <li
                className={`${"/customers" === pathName ||
                    "/customers-new" === pathName ||
                    "/edit-estimate" === pathName ||
                    "/view-estimate" === pathName
                    ? "active"
                    : ""
                  }`}
              >
                <Link to="/customers">
                  <FeatherIcon icon="user" />
                  <span>Customers</span>
                </Link>
              </li>

              {currentUser.role.name == 'tailor' && (

                <li
                  className={`${"/cloth/type/index" === pathName ||
                      "/clothing" === pathName ||
                      "/edit-estimate" === pathName ||
                      "/view-estimate" === pathName
                      ? "active"
                      : ""
                    }`}
                >
                  <Link to="/clothing">
                    <FeatherIcon icon="scissors" />
                    <span>Products,Services and <br/>Measurments</span>
                  </Link>
                </li>
              )}
              <li
                className={`${"/orders" === pathName ||
                    "/add-invoice" === pathName ||
                    "/edit-invoice" === pathName
                    ? "active"
                    : ""
                  }`}
              >
                <Link to="/orders">
                  <FeatherIcon icon="shopping-cart" /> <span>Orders</span>
                </Link>
              </li>
              {currentUser.role.name == 'tailor' && (
<>
<li
className={`${"/staff" === pathName
    ? "active"
    : ""
  }`}
>
<Link to="/staff">
  <FeatherIcon icon="users" />
  <span>Staff</span>
</Link>
</li>
                <li
                  className={`${"/marketplace" === pathName
                      ? "active"
                      : ""
                    }`}
                >
                  <Link to="/marketplace">
                    <FeatherIcon icon="shopping-bag" />
                    <span>Marketplace</span>
                  </Link>
                </li>
                </>
              )}
              <li
                className={`${"/terms-condition" === pathName ||
                    "/add-expenses" === pathName ||
                    "/edit-expenses" === pathName
                    ? "active"
                    : ""
                  }`}
              >
                {/* <Link to="/terms-condition">
                  <FeatherIcon icon="package" /> <span>Terms & conditions</span>
                </Link> */}
              </li>

              <li
                className={`${"/privacy-policy" === pathName ||
                    "/add-expenses" === pathName ||
                    "/edit-expenses" === pathName
                    ? "active"
                    : ""
                  }`}
              >
                {/* <Link to="/privacy-policy">
                  <FeatherIcon icon="pie-chart" /> <span>Privacy Policy</span>
                </Link> */}
              </li>
              {/* <li className={`${'/sales-report' === pathName || '/expenses-report' === pathName || '/profit-loss-report' === pathName
                             || '/taxs-report' === pathName ? 'active submenu' : 'submenu' }`}>          
                                <Link to="#" className={isSideMenu == "report" ? "subdrop" : ""} onClick={()=> toggleSidebar(isSideMenu =="report" ? "": "report")}><FeatherIcon icon="pie-chart" /> <span> Reports</span><span className="menu-arrow"></span></Link>                               
                                    { isSideMenu == "report" ? 
                                        <ul>
                                            <li className={`${'/sales-report' === pathName ? 'active' : '' }`}><Link to="/sales-report">Sales Report</Link></li>
                                            <li className={`${'/expenses-report' === pathName ? 'active' : '' }`}><Link to="/expenses-report">Expenses Report</Link></li>
                                            <li className={`${'/profit-loss-report' === pathName ? 'active' : '' }`}><Link to="/profit-loss-report">Profit &amp; Loss Report</Link></li>
                                            <li className={`${'/taxs-report' === pathName ? 'active' : '' }`}><Link to="/taxs-report">Taxs Report</Link></li>
                                        </ul>
										:"" 
                                    }
                            </li>                             */}
              <li
                className={`${"/settings" === pathName ||
                    "/preferences" === pathName ||
                    "/tax-types" === pathName ||
                    "/expense-category" === pathName ||
                    "/notifications" === pathName ||
                    "/change-password" === pathName ||
                    "/delete-account" === pathName
                    ? "active"
                    : ""
                  }`}
              >
                <Link to="/settings">
                  <FeatherIcon icon="settings" /> <span>Settings</span>
                </Link>
              </li>
              
              <li>
                <Link to="/logout">
                  <FeatherIcon icon="log-out" /> <span>Logout</span>
                </Link>
              </li>
              {/* <li className={`${'/chat' === pathName || '/calendar' === pathName || '/inbox' === pathName ? 'active submenu' : 'submenu' }`}>                            
                                <Link to="#" className={isSideMenu == "application" ? "subdrop" : ""} onClick={()=> toggleSidebar(isSideMenu =="application" ? "": "application")}><FeatherIcon icon="grid" /> <span> Application</span> <span className="menu-arrow"></span></Link>
                                {
                                  isSideMenu ==  "application" ? 
                                  <ul>
                                        <li className={`${'/chat' === pathName ? 'active' : '' }`}><Link to="/chat">Chat</Link></li>
                                        <li className={`${'/calendar' === pathName ? 'active' : '' }`}><Link to="/calendar">Calendar</Link></li>
                                        <li className={`${'/inbox' === pathName ? 'active' : '' }`}><Link to="/inbox">Email</Link></li>
                                    </ul> : ""
                                }
                               
                            </li>
                            <li className="menu-title"> 
                                <span>Pages</span>
                            </li>
                            <li className={`${'/profile' === pathName ? 'active' : '' }`}> 
                                <Link to="/profile"><FeatherIcon icon="user-plus" /> <span>Profile</span></Link>
                            </li>
                            <li className={`${'/login' === pathName || '/register' === pathName || '/forgot-password' === pathName || '/lock-screen' === pathName ? 'active submenu' : 'submenu' }`}>
                                <Link to="#" className={isSideMenu == "authentication" ? "subdrop" : ""} onClick={()=> toggleSidebar(isSideMenu =="authentication" ? "": "authentication")} ><FeatherIcon icon="lock" /> <span> Authentication </span> <span className="menu-arrow"></span></Link>
                                {
                                  isSideMenu ==  "authentication" ? 
                                <ul>
                                    <li className={`${'/login' === pathName ? 'active' : '' }`}><Link to="/login"> Login </Link></li>
                                    <li className={`${'/register' === pathName ? 'active' : '' }`}><Link to="/register"> Register </Link></li>
                                    <li className={`${'/forgot-password' === pathName ? 'active' : '' }`}><Link to="/forgot-password"> Forgot Password </Link></li>
                                    <li className={`${'/lock-screen' === pathName ? 'active' : '' }`}><Link to="/lock-screen"> Lock Screen </Link></li>
                                </ul> : ""
                                }
                            </li>
                            <li className={`${'/error-404' === pathName || '/error-500' === pathName  ? 'active submenu' : 'submenu' }`}>
                                <Link to="#" className={isSideMenu == "errorPages" ? "subdrop" : ""} onClick={()=> toggleSidebar(isSideMenu =="errorPages" ? "": "errorPages")}><FeatherIcon icon="alert-octagon" />  <span> Error Pages </span> <span className="menu-arrow"></span></Link>
                                {
                                  isSideMenu ==  "errorPages" ? 
                                <ul>
                                    <li className={`${'/error-404' === pathName ? 'active' : '' }`}><Link to="/error-404">404 Error </Link></li>
                                    <li className={`${'/error-500' === pathName ? 'active' : '' }`}><Link to="/error-500">500 Error </Link></li>
                                </ul> : ""
                                }
                            </li>
                            <li className={`${'/users' === pathName ? 'active' : '' }`}> 
                                <Link to="/users"><FeatherIcon icon="users" /> <span>Users</span></Link>
                            </li>
                            <li className={`${'/blank-page' === pathName ? 'active' : '' }`}> 
                                <Link to="/blank-page"><FeatherIcon icon="file" /> <span>Blank Page</span></Link>
                            </li>
                            <li className={`${'/maps-vector' === pathName ? 'active' : '' }`}> 
                                <Link to="/maps-vector"><FeatherIcon icon="map-pin" /> <span>Vector Maps</span></Link>
                            </li>
                            <li className="menu-title"> 
                                <span>UI Interface</span>
                            </li>
                            <li className={`${'/components' === pathName ? 'active' : '' }`}> 
                                <Link to="/components"><FeatherIcon icon="layers" /> <span>Components</span></Link>
                            </li>
                            <li className={`${'/form-basic-inputs' === pathName || '/form-input-groups' === pathName || '/form-horizontal' === pathName || '/form-vertical' === pathName ||  '/form-mask' === pathName ||  '/form-validation' === pathName ? 'active submenu' : 'submenu' }`}>
                                <Link to="#" className={isSideMenu == "forms" ? "subdrop" : ""} onClick={()=> toggleSidebar(isSideMenu =="forms" ? "": "forms")}><FeatherIcon icon="file-minus" /> <span> Forms </span> <span className="menu-arrow"></span></Link>
                                {
                                  isSideMenu ==  "forms" ? 
                                <ul>
                                    <li className={`${'/form-basic-inputs' === pathName ? 'active' : '' }`}><Link to="/form-basic-inputs">Basic Inputs </Link></li>
                                    <li className={`${'/form-input-groups' === pathName ? 'active' : '' }`}><Link to="/form-input-groups">Input Groups </Link></li>
                                    <li className={`${'/form-horizontal' === pathName ? 'active' : '' }`}><Link to="/form-horizontal">Horizontal Form </Link></li>
                                    <li className={`${'/form-vertical' === pathName ? 'active' : '' }`}><Link to="/form-vertical"> Vertical Form </Link></li>
                                    <li className={`${'/form-mask' === pathName ? 'active' : '' }`}><Link to="/form-mask"> Form Mask </Link></li>
                                    <li className={`${'/form-validation' === pathName ? 'active' : '' }`}><Link to="/form-validation"> Form Validation </Link></li>
                                </ul> :""
                                }
                            </li>
                            <li className={`${'/tables-basic' === pathName || '/data-tables' === pathName ? 'active submenu' : 'submenu' }`}>
                                <Link to="#" className={isSideMenu == "tables" ? "subdrop" : ""} onClick={()=> toggleSidebar(isSideMenu =="tables" ? "": "tables")}><FeatherIcon icon="layout" />  <span> Tables </span> <span className="menu-arrow"></span></Link>
                                {
                                  isSideMenu ==  "tables" ?
                                <ul>
                                    <li className={`${'/tables-basic' === pathName ? 'active' : '' }`}><Link to="/tables-basic">Basic Tables </Link></li>
                                    <li className={`${'/data-tables' === pathName ? 'active' : '' }`}><Link to="/data-tables">Data Table </Link></li>
                                </ul> :""
                                }
                            </li> */}
            </ul>


          </div>
        </div>
      </Scrollbars>
        <div className="position-absolute bottom-0 start-01 ms-5 ps-4 mb-3">
              <span className="me-1 mt-4 fw-bold">Role: </span>
              <span className="me-5 mt-4">{titlize(currentUser?.role?.name)}</span>
            </div>
    </div>
  );
};
export default withRouter(Sidebar);
