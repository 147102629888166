import React, { useState, useContext, useEffect} from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Card, Col, Modal, Row, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../authentication/AuthContext";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { useForm, Controller } from "react-hook-form";
import { ColorRing } from "react-loader-spinner";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowUpFromBracket,
  faClipboardList  
} from "@fortawesome/free-solid-svg-icons";

const Measurements = ({setIsloading}) => {
  const { handleSubmit, control } = useForm({});

  const { currentUser } = useContext(AuthContext);

  const [showAddMeasurement, setShowAddMeasurement] = useState(false);
  const [showEditMeasurement, setShowEditMeasurement] = useState(false);
  const [showUploadMeasurement, setShowUploadMeasurement] = useState(false);
  const [measurementData, setMeasurementData] = useState({});
  const [uploadFile, setUploadFile] = useState('');
  const [fileType, setFileType] = useState('');
  const [dynamicMeasurement, setDynamicMeasurement] = useState([]);
  const [title, setTitle] = useState('');



  const handleCloseAddMeasurement = () => setShowAddMeasurement(false);
  const handleShowAddMeasurement = () => {setShowAddMeasurement(true);};

  const handleCloseEditMeasurement = () => {setShowEditMeasurement(false);setMeasurementData({});setTitle('')};
  const handleShowEditMeasurement = (row) => {setShowEditMeasurement(true); setMeasurementData(row);setTitle(row.title)};

  const handleCloseUploadMeasurement = () => {setShowUploadMeasurement(false);setUploadFile('');setFileType('')};
  const handleShowUploadMeasurement = () => {setShowUploadMeasurement(true);};

  const handleMeasurementFileChange = (event) => {
    const files = event.target.files[0];
    setUploadFile(files);
  };
  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
  };  

  const tooltip = <Tooltip id="tooltip">Add Measurement</Tooltip>;
  const Uploadtooltip = <Tooltip id="tooltip">Upload Measurement</Tooltip>;
  const assignTooltip = <Tooltip id="tooltip">Assign</Tooltip>;

  const fetchMeasurement = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/dynamicMeasurements`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
            setDynamicMeasurement(
            response.data.body.length > 0 ? response.data.body : 0
          );
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  useEffect(() => {
    fetchMeasurement();
  }, []);

  
  const columns = [
    {
      name: "Title",
      cell: (row) => (
        <div style={{ whiteSpace: "break-spaces" }}>
          {row.title.substring(0, 20)}
        </div>
      ),
      width: "25%",
    },
    {
      name: <div>Assigned</div>,
      export:false,
      cell: (row) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {row?.assigenMeasurement?.map((item, index) => (
          <React.Fragment key={index}>
            <span className="badge badge-success mr-1 mb-1">
              {item?.clothType?.title}
            </span>
            {index !== row?.assigenMeasurement.length - 1 && <span style={{ marginRight: '5px' }} />}
          </React.Fragment>
        ))} 
       </div>
      ),
      width: "35%",
    },
    {
      name: "Edit",
      export: false,
      cell: (row) => (
        <div>
          <i className="fa fa-edit" onClick={() => handleShowEditMeasurement(row)}></i>
        </div>
      ),
      sortable: false,
      width: "20%",
    },
  ];
  const tableData = {
    columns,
    data: dynamicMeasurement !== 0 ? dynamicMeasurement : [],
    print: false,
    "export": true,
    exportHeaders:true,
    fileName: "Measurements", 
  };

  const onSubmit = (data) => {
    setIsloading(true);
    axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/api/dynamicMeasurements`,
      {title:data.title},
      {
        headers: { Authorization: `Bearer ${currentUser.accessToken}` },
      }
    )
    .then((response) => {
      toast.success(response.data.message);
      setIsloading(false);
      fetchMeasurement();
      handleCloseAddMeasurement();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      setIsloading(false);
    });
    
  };

  const handleUpdateSubmit = (event) => {
    event.preventDefault();
    if (event.key === "Enter") event.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/dynamicMeasurements/${measurementData._id}`,
        {title:title},
        {
          headers: { Authorization: `Bearer ${currentUser.accessToken}` },
        }
      )
      .then((response) => {
        fetchMeasurement();
        handleCloseEditMeasurement();
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };


  const handleDownloadMeasurementTemplate = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/dynamicMeasurementTemplate`,
        { responseType: "blob" },
        {
          headers: { Authorization: `Bearer ${currentUser.accessToken}` },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `MeasurementTemplate.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleImportMeasurement = () => {
    if(fileType && uploadFile){
      const formData = new FormData();
      formData.append("type", fileType);
      formData.append("tailor",currentUser["id"] );
      formData.append("file", uploadFile);
      setIsloading(true);
      axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/api/import/dynamicMeasurements`,
            formData,
            {
              headers: { Authorization: `Bearer ${currentUser.accessToken}` },
            }
          )
          .then((response) => {
            toast.success(response.data.message);
            handleCloseUploadMeasurement();
            fetchMeasurement();
            setIsloading(false);
          })
          .catch((error) => {
            toast.error("Please Upload Correct file template");
            setIsloading(false);
          });
    }
    else{
      toast.error("Fill the Complete Form");
    }
    
    };

  return (
    <>
  
       <Modal show={showAddMeasurement} onHide={handleCloseAddMeasurement}>
        <Modal.Header closeButton>
          <Modal.Title>Add Measurement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12">
                <label htmlFor="">Title</label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <input
                      className="form-control"
                      type="text"
                      name="title"
                      placeholder="Enter an measurement e.g. Neck"
                      id="title"
                      onChange={onChange}
                      required
                    />
                  )}
                  defaultValue=""
                />
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseAddMeasurement}>
                Cancel
              </Button>
              <Button variant="success" type="submit" onClick={handleSubmit}>
                Save
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showEditMeasurement} onHide={handleCloseEditMeasurement}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Measurement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleUpdateSubmit}>
            <div className="row">
              <div className="col-12">
                <label htmlFor="">Title</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter an measurement e.g. Neck"
                  name="name"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  required
                  id="name"
                  defaultValue={measurementData.title}
                />
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEditMeasurement}>
                Cancel
              </Button>
              <Button variant="success" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showUploadMeasurement} onHide={handleCloseUploadMeasurement}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Measurement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
    <>
          <label  className="form-label">Type</label>
          <Row className="mb-3" >
            <Col lg={2}>
              <Form.Check
                inline
                type="checkbox"
                label="CSV"
                value="csv"
                checked={fileType === "csv"}
                onChange={handleFileTypeChange}
              />
            </Col>
            <Col lg={6}>
              <Form.Check
                inline
                type="checkbox"
                label="Excel"
                value="excel"
                checked={fileType === "excel"}
                onChange={handleFileTypeChange}
              />
            </Col>
          </Row>
          <div className="form-group">
                    <label className="form-label">Upload File</label>
                    <input
                      type="file"
                      id="images"
                      className="form-control"
                      multiple
                      accept=".xlsx, .csv"
                      onChange={handleMeasurementFileChange}
                      min="1"
                      max="1"
                      required
                    />
                  </div>
                  </>
                  <Row>
            <Col lg={7} md={5} sm={5} xs={5}>
          
                                <Button
                              className="btn btn-primary"
                              onClick={() => handleDownloadMeasurementTemplate()}
                            >
                              Download Template
                            </Button>
                            </Col>
            <Col lg={5} md={7} sm={7} xs={7} >
              <Button variant="secondary" onClick={handleCloseUploadMeasurement} className="float-right mx-1">
                Cancel
              </Button>{'  '}
              <Button variant="success"   onClick={handleImportMeasurement} className="float-right">
                Save
              </Button>
              </Col>
          </Row>
        </Modal.Body>
      </Modal>


      <div className="row">
        <div className="col">
          <div className="float-end">
            <>
              <OverlayTrigger placement="top" overlay={tooltip}>
                <Button className="btn btn-primary"  onClick={() => handleShowAddMeasurement()}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </OverlayTrigger>
              {"  "}
              <OverlayTrigger placement="top" overlay={Uploadtooltip}>
                <Button className="btn btn-primary" onClick={() => handleShowUploadMeasurement()} >
                  <FontAwesomeIcon icon={faArrowUpFromBracket} />
                </Button>
              </OverlayTrigger>
              {"  "}
              <Link to="/measurement-assign">
              <OverlayTrigger placement="top" overlay={assignTooltip}>
                <Button className="btn btn-primary">
                  <FontAwesomeIcon icon={faClipboardList} />
                </Button>
              </OverlayTrigger>
              </Link>
            </>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table">
            <div className="card-body">
              <DataTableExtensions {...tableData}>
                <DataTable
                  noHeader
                  className="data-table-extensions-action p-3"
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                />
              </DataTableExtensions>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};
export default Measurements;
