import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

export const UserProtectedRoutes = ({ children }) => {
  const { currentUser ,setCurrentUser } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const excludedRoutesPattern = /^\/(login|register|activate|privacy_policy|tac\/)/;
  const [isExpiredJWT, setIsExpiredJWT] = useState(false);
  const [isApiResponseCompleted, setIsApiResponseCompleted] = useState(false);
  const checkIsExpiredJWT = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/is_expired_jwt`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(() => {
        setIsExpiredJWT(true);
      }).catch((err) => {
        setIsExpiredJWT(false);
        setCurrentUser(null);
    localStorage.removeItem("currentUser");
    localStorage.clear();
    navigate('/login');
      }).finally(() => {
        setIsApiResponseCompleted(true);
      });
  };

  useEffect(() => {
    if (currentUser) {
      checkIsExpiredJWT();
    }
  }, [currentUser]);
 
  console.log("Protected routes",location.pathname);
  if (excludedRoutesPattern.test(location.pathname)) {
    return <>{children}</>;
  } else if (!currentUser) {
    // toast.error("Please login before proceeding.", { toastId: "error1" });
    return <Navigate to="/login" />;
  } 

  return <>{children}</>;
};
