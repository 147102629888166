import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../assets/constant";
import { useNavigate } from "react-router-dom";

export const NonProtectedRoutes = ({ children }) => {
  const { currentUser,setCurrentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const excludedRoutesPattern = /^\/(login|register|activate|tac|privacy_policy\/)/;
  const [isExpiredJWT, setIsExpiredJWT] = useState(false);
  const [isApiResponseCompleted, setIsApiResponseCompleted] = useState(false);

  const checkIsExpiredJWT = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/is_expired_jwt`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(() => {
        setIsExpiredJWT(true);
        console.log("current user data ",currentUser);
      }).catch((err) => {
        setIsExpiredJWT(false);
        setCurrentUser(null);
        localStorage.removeItem("currentUser");
        localStorage.clear();
      }).finally(() => {
        setIsApiResponseCompleted(true);
      });
  };

  useEffect(() => {
    if (currentUser) {
      checkIsExpiredJWT(); 
    }
  }, [currentUser]);

  if (excludedRoutesPattern.test(location.pathname) && (currentUser || (currentUser && isExpiredJWT && isApiResponseCompleted))) {
    return <Navigate to="/login" />;
  }
  if(location.pathname === "tac" || location.pathname === "privacy_policy"){
    return <Navigate to={location.pathname} />;
    
  }

  return <>{children}</>;
};
