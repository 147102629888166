import React, { useEffect, useState, useContext } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { alphaNumericPattern, emailrgx } from "../assets/constant";
import axios from "axios";
import { AuthContext } from "../authentication/AuthContext";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../assets/constant";
import { useParams } from "react-router-dom";
import DynamicInputFields from "../_components/dynamicTextField";
import Footer from "../layouts/Footer";

const AddClothSize = (props) => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [clothType, setClothType] = useState([]);
  const [customersList, setcustomersList] = useState([]);
  const [measurementFields, setMeasurementFields] = useState([]);
  const { id } = useParams();

  const handleMeasurementFieldsChange = (fields) => {
    setMeasurementFields(fields);
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const fetchClothType = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/cloth_types/${id}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then(
        (response) => {
          setClothType(response.data.body);
        },
        (error) => {
          toast.error(error.response.data.message);
        }
      );
  };

  useEffect(() => {
    fetchClothType();
  }, []);

  const [selectedClothType, setSelectedType] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const handleClothTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleCustomerChange = (event) => {
    setSelectedCustomer(event.target.value);
  };

  const [date, setDate] = useState(new Date());
  const [currencyOptions, setcurrencyOptions] = useState([
    { id: 1, text: "Select Currency" },
    { id: 2, text: "EUR Euro" },
    { id: 3, text: "INR Indoan Rupee" },
    { id: 4, text: "USD- US Dollar" },
  ]);
  const [countryOptions, setcountryOptions] = useState([
    { id: 1, text: "Select Country" },
    { id: 2, text: "Afghanistan" },
    { id: 3, text: "Albania" },
    { id: 4, text: "American Samoa" },
    { id: 5, text: "Algeria" },
    { id: 6, text: "Andorra" },
    { id: 7, text: "Angola" },
    { id: 8, text: "Anguilla" },
    { id: 9, text: "United States" },
  ]);

  const onSubmit = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/measurements`,
        {
          titles: measurementFields,
          clothType: id,
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then(
        (response) => {
          toast.success(response.data.message)
          navigate(`/clothing`);
        },
        (error) => {
          toast.error(error.response.data.message)
        }
      );
  };

  const { currentUser } = useContext(AuthContext);

  const onEyeClick = () => {
    seteye(!eye);
  };
  const [eye, seteye] = useState(true);

  const schema = yup
    .object({
      neckSize: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid size"),
      shoulderWidth: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid size"),
      chestSize: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid size"),
      waistSize: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid size"),
      sleeveLength: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid size"),
      shirtLength: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid size"),
      collarSpread: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid size"),
      cuffSize: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid size"),
      bicepSize: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid size"),
      armholeSize: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid size"),
      backPleats: yup
        .string()
        .matches(alphaNumericPattern, "please enter valid size"),
    })
    .required();

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">

                  <span style={{fontSize:"30px",fontWeight:"500"}} className="page-title">Add Customer Measurements</span>
                  <button
                        className="btn btn-lg btn-block btn-primary float-end"
                        type="submit"
                      >
                        Save 
                      </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    
                      <Row>
                        <Col lg={6} sm={12} className="mb-4">
                            <label className="form-label">Clothing</label>
                            <input
                                min="1"
                                max="100"
                                type="text"
                                id="typeNumber"
                                className="form-control"
                                value={clothType.title}
                                disabled
                              />
                          </Col>
                      </Row>
                      <Col lg={6} sm={12}>
                      <label className="form-label">Customer Measurement Area</label>
                      <DynamicInputFields
                        measurementFields={measurementFields}
                        onMeasurementFieldsChange={handleMeasurementFieldsChange}
                      />

                      </Col>

                     
                  </div>
                </div>
              </div>
            </div>
            </form>

          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};
export default AddClothSize;
